import React from 'react';
import { FooterBody } from './styled-components';
import usePrismicPageByUid from '../../../../hooks/usePrismicPageByUid';
import Grid from '../../../../modules/common/prismic/grid';
import usePrismicGlobalConfig from '../../../../hooks/usePrismicGlobalConfig';
import { useWindowSize } from '../../../../hooks/useWindowSize';

const PrismicFooter = () => {
  const { config: prismicGlobalConfig } = usePrismicGlobalConfig();
  let footerUid = prismicGlobalConfig && prismicGlobalConfig['footer-uid'];
  const footerXLUid = prismicGlobalConfig && prismicGlobalConfig['footer-xl-uid'];
  const footerLUid = prismicGlobalConfig && prismicGlobalConfig['footer-l-uid'];

  const { width: screenWidth } = useWindowSize();
  if (footerLUid && screenWidth < 1200) {
    footerUid = footerLUid;
  }
  if (footerXLUid && screenWidth >= 1200) {
    footerUid = footerXLUid;
  }

  const { data: footerData } = usePrismicPageByUid('grid', footerUid);

  return (
    <FooterBody>
      {footerData && <Grid contentData={footerData}/>}
    </FooterBody>
  );
}
export default PrismicFooter;

import { createSelector } from 'reselect';

const getValidAddressId = (addresses, requestedAddressId) => {
  const foundAddress = requestedAddressId && addresses && addresses.find(a => a.addressId === requestedAddressId);
  if (foundAddress) {
    return requestedAddressId;
  }
  if (addresses && addresses.length > 0) {
    return addresses[0].addressId;
  }
  return null;
};

const INITIAL_VALUE = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  businessPartner: {
    name: '',
    defaultBillingAddressId: null,
    defaultShippingAddressId: null,
  },
  businessPartnerName: '',
  currency: {},
  language: 'en',
  dateFormat: 'yyyy/mm/dd',
  numberFormat: {
    thousands: ' ',
    decimal: '.',
  },
  theme: '',
};

export const getLanguage = createSelector(
  [state => state.user.data && state.user.data.userLanguage],
  userLanguage => {
    return (userLanguage && userLanguage.languageId) || null;
  },
);

export const getPrismicLanguage = createSelector(
  [getLanguage],
  userLanguage => {
    return userLanguage && {
      'nl': 'nl-be',
      'fr': 'fr-fr',
      'en': 'en-gb',
      'de': 'de-de'
    }[userLanguage] || null;
  },
);

export const getDateFormat = state =>
  (state.user.data &&
    state.user.data.additionalUserSettings &&
    state.user.data.additionalUserSettings.formatting &&
    state.user.data.additionalUserSettings.formatting.dateFormatting) ||
  'dd/mm/yyyy';

export const getNumberFormat = createSelector(
  [
    state =>
      state.user.data &&
      state.user.data.additionalUserSettings &&
      state.user.data.additionalUserSettings.formatting &&
      state.user.data.additionalUserSettings.formatting.numberFormatting,
    state => state.user.data && state.user.data.defaultUserCurrency,
  ],
  (numberFormatting, currency) => {
    return {
      ...(numberFormatting || { thousands: ' ', decimal: '.' }),
      ...(currency || {}),
    };
  },
);

export const getUserSettings = createSelector(
  [
    state => state.user,
    state => state.settings && state.settings.config.data,
    state => state.settings && state.settings.theming,
    state => state.cart && state.cart.billingAddresses,
    state => state.cart && state.cart.shippingAddresses,
  ],
  (userState, config, theming, billingAddresses, shippingAddresses) => {
    const user = userState.data;
    if (config && user && theming && user.userBusinessPartner && billingAddresses && shippingAddresses) {
      return {
        user: {
          id: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.emailAddress,
        },
        defaultBillingAddressId: getValidAddressId(billingAddresses, user.billToAddressId),
        defaultShippingAddressId: getValidAddressId(shippingAddresses, user.shipToAddressId),
        businessPartnerName: (user.userBusinessPartner && user.userBusinessPartner.businessPartnerName) || '',
        currency: user.userBusinessPartner && user.userBusinessPartner.businessPartnerCurrency,
        language: (user.userLanguage && user.userLanguage.languageId) || 'en',
        dateFormat: (user.additionalUserSettings && user.additionalUserSettings.formatting && user.additionalUserSettings.formatting.dateFormatting) || 'yyyy/mm/dd',
        numberFormat: {
          thousands:
            (user.additionalUserSettings &&
              user.additionalUserSettings.formatting &&
              user.additionalUserSettings.formatting.numberFormatting &&
              user.additionalUserSettings.formatting.numberFormatting.thousands) ||
            ' ',
          decimal:
            (user.additionalUserSettings &&
              user.additionalUserSettings.formatting &&
              user.additionalUserSettings.formatting.numberFormatting &&
              user.additionalUserSettings.formatting.numberFormatting.decimal) ||
            '.',
        },
        theme: user.additionalUserSettings.theme || (theming.current && theming.current.name),
        loading: userState.loading,
        loaded: userState.loaded,
        saving: userState.saving,
        saved: userState.saved,
      };
    } else {
      return INITIAL_VALUE;
    }
  },
);

export const getDefaultAddressIds = createSelector(
  [getUserSettings],
  userSettings => {
    return {
      billToAddressId: userSettings.defaultBillingAddressId,
      shipToAddressId: userSettings.defaultShippingAddressId,
    };
  },
);

export const getAuthorizations = createSelector(
  [
    state => state.user,
  ],
  (user) => {
    // TODO embed Status & ComponentId when clear what these are
    return user
      && user.data
      && user.data.authorizations
      && user.data.authorizations.reduce((modulesResult, module) => {
        return {
          ...modulesResult,
          [module.moduleId]: module.features.reduce((featuresResult, feature) => {
            return {
              ...featuresResult,
              [feature.featureId]: true,
            };
          }, {}),
        };
      }, {})
      || {};
  },
);


export const getUserSettingsError = createSelector(
  [state => state.user],
  user => user.errorMessage
);

export const getIsAuthenticated = createSelector(
  [getUserSettings, getUserSettingsError],
  (userSettings, userError) => userSettings && !userError,
);


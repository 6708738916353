import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';

let INITIAL_STATE = {
  sending: false,
  sent: false,
  failed: false,
  data: {},
  errorMessage: null
};
export const reducer = handleActions(
  {
    [actionTypes.SEND_REGISTRATION_REQUEST]: (state, { payload }) => ({
      ...INITIAL_STATE,
      sending: true,
      data: payload,
    }),
    [actionTypes.SEND_REGISTRATION_SUCCESS]: () => ({
      ...INITIAL_STATE,
      sent: true,
    }),
    [actionTypes.SEND_REGISTRATION_FAILURE]: (state, { payload }) => ({
      ...INITIAL_STATE,
      failed: true,
      errorMessage: payload
    }),
  },
  INITIAL_STATE,
);

export default reducer;

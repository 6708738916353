import { createSelector } from 'reselect';
import { formatMessage } from '../../../utils/error-msg';

export const getCartItems = createSelector([(state) => state.cart], (cartState) => {
  const { orderItems } = cartState;

  // Get all UoM of same product
  const UoMbyProducts = {};
  orderItems.map((product) => {
    const { productId, selected, salesUnitId } = product;
    const unitId = selected ? selected.salesUnitId : salesUnitId;
    if (!UoMbyProducts[productId]) {
      UoMbyProducts[productId] = [];
    }
    UoMbyProducts[productId] = [...UoMbyProducts[productId], unitId];
  });

  const filteredOrderItems = orderItems.map((product) => {
    const { productId, selected, salesUnitId, unitConversions = [] } = product;
    const productCartUoM = UoMbyProducts[productId];
    const selectedUoM = selected ? selected.salesUnitId : salesUnitId;

    // Filter out UoM of product that is in cart already
    const filteredUnitConversions = unitConversions.filter((unit) => {
      const isSelectedUoM = selectedUoM === unit.unitOfMeasureId;
      const inCart = productCartUoM.includes(unit.unitOfMeasureId);
      return !inCart || isSelectedUoM;
    });

    return { ...product, filteredUnitConversions };
  });

  return filteredOrderItems;
});

export const getCartOrderRequest = createSelector([getCartItems], (cartState) =>
  cartState.map((item, i) => ({
    orderItemId: i + 1,
    productId: item.productId,
    quantity: item.quantity,
    // unitOfMeasure: item.salesUnitId,
    unitOfMeasure: item.selected ? item.selected.salesUnitId : item.salesUnitId,
  })),
);

export const getCartQuantity = createSelector([(state) => state.cart, getCartItems], (cartState, cartItems) => ({
  count: cartItems.length || 0,
  quantityCount: (cartItems && cartItems.reduce && cartItems.reduce((acc, obj) => acc + obj.quantity, 0)) || 0,
}));

export const getInvoice = createSelector([(state) => state.cart], (cartState) => cartState.invoice);

export const getMaximumCartSize = (state) => {
  return (
    (state.settings &&
      state.settings.config &&
      state.settings.config.data &&
      state.settings.config.data.app &&
      state.settings.config.data.app['max-cart-size']) ||
    40
  );
};

export const getCartIsFullMessage = createSelector(
  [getMaximumCartSize, getCartItems, (state) => state.translations],
  (maxCartSize, cartItems, translations) => {
    return cartItems && cartItems.length >= maxCartSize
      ? translations && translations.data && translations.data['CART.ERROR.CART_IS_FULL']
      : null;
  },
);

export const getLoading = (state) => state.cart.loading;
export const getLoadingCreate = (state) => state.cart.loadingCreateOrder;

export const getBillingAddresses = (state) => state.cart.billingAddresses;
export const getShippingAddresses = (state) => state.cart.shippingAddresses;
export const getPaymentMethods = state => state.cart.paymentMethods;

const findOrderItemId = (arr) => {
  const item = arr.messageVariables.find(({ variableName }) => variableName === 'orderItemId');
  return item ? item.variableValue : null;
};

export const getError = createSelector([(state) => state.cart.error], (cartError) =>
  cartError.map((error) => {
    if (error.messageVariables && error.messageVariables.length) {
      return {
        ...error,
        orderItemId: findOrderItemId(error),
        message: formatMessage(error.messageLongDescription, error.messageVariables),
      };
    }
  }),
);

export const getGroupErrorById = createSelector([getError], (state) => {
  const errors = state && state.length ? {} : null;
  state.map((error) => {
    const isExisting = errors[error.orderItemId];
    if (!isExisting) {
      errors[error.orderItemId] = [];
    }
    errors[error.orderItemId].push(error.message);
  });
  return errors;
});

export const getCheckoutError = createSelector([(state) => state.cart.checkoutError], (cartError) => {
  if (!cartError || !cartError.length) return null;

  let mapFieldsErrors = {};
  cartError.map((error) => {
    const { field, messageLongDescription, messageVariables } = error;

    if (field) {
      mapFieldsErrors[field] = {
        errors: [new Error(formatMessage(messageLongDescription, messageVariables))],
      };
    }
  });

  return mapFieldsErrors;
});

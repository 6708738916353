import { all, fork, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './constants';
import { getContentFailure, getContentSuccess } from './settings-actions';
import { fetchJson } from '../../../utils/saga-helper';
import * as appActionTypes from '../../../app/containers/constants';

export function* watchLoadContent() {
  const config = {
    successAction: getContentSuccess,
    failureAction: getContentFailure,
    path: `files/content.json`,
    languageDependent: true
  };
  yield takeLatest([actionTypes.GET_CONTENT_REQUEST, appActionTypes.SET_PUBLIC_LANGUAGE], fetchJson.bind(this, config));
}

export default function* categoryFlow() {
  yield all([
    fork(watchLoadContent),
  ]);
}

import 'react-app-polyfill/ie9';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './grid.css';
import 'antd/dist/antd.less';
import App from './app/app';
import '../assets/fonts/Montserrat.css';

ReactDOM.render(<App />, document.getElementById('root'));

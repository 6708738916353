import React, { lazy, Suspense, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ErrorPage } from './modules/common/error';
import useFeature, { Features, Modules } from './hooks/useFeature';
import { PortalNotAvailableContext } from './modules/common/error/unavailable';
const Home = lazy(() => import('./modules/home/components/home'));
const Content = lazy(() => import('./modules/content/content'));
const ProductDetail = lazy(() => import('./modules/product/components/product-detail'));
const ProductLister = lazy(() => import('./modules/product-lister/components/product-lister'));
const CartComponent = lazy(() => import('./modules/cart/components/cart-component'));
const OrderList = lazy(() => import('./modules/order-list/components/order-list'));
const OrderDetail = lazy(() => import('./modules/order-detail/components/order-detail'));
const Registration = lazy(() => import('./modules/self-registration/components/registration'));
const RegistrationResult = lazy(() => import('./modules/self-registration/components/registration-result'));
const UserSettings = lazy(() => import('./modules/user/components/user-settings'));
const Favorites = lazy(() => import('./modules/favorites/containers/favorites'));
const InvoiceList = lazy(() => import('./modules/invoice-list/components/invoice-list'));
const CartPaymentResponse = lazy(() => import('./modules/cart/components/cart-payment-response'));

const PageRoute = ({ component: Component, ...rest }) => {
  const module = Modules.General;
  const feature = Features.General.PortalNotAvailable;
  const isNotAvailable = useFeature(module, feature);
  const portalAvailability = useContext(PortalNotAvailableContext);

  // if featureFlag PortalNotAvailable is set to true
  if (isNotAvailable) {
    portalAvailability.setPortalNotAvailable(true);
  }

  return <Route render={(props) => <Component {...props} />} {...rest} />;
};

const AuthRoute = ({ component: Component, module, feature, ...rest }) => {
  const isAuthorized = useFeature(module, feature);

  // if no user settings
  if (!isAuthorized) {
    return <Redirect to="/" />;
  }
  return <PageRoute render={(props) => <Component {...props} />} {...rest} />;
};

export default () => {
  const location = useLocation();
  console.log('location: ' + location?.pathname);
  return (
    <Suspense fallback="">
      <Switch>
        <PageRoute exact={true} path="/" component={Home} />
        <PageRoute exact={true} path="/registration" render={(props) => <Registration />} />
        <PageRoute exact={true} path="/registration/success" render={(props) => <RegistrationResult />} />
        <PageRoute exact={true} path="/registration/error" render={(props) => <RegistrationResult />} />
        <PageRoute exact={true} path="/search" render={(props) => <ProductLister key={props.location.search} {...props} />} />
        <PageRoute exact={true} path="/category/:category?" render={(props) => <ProductLister key={props.match.params.category} {...props} />} />
        <PageRoute exact={true} path="/product/:productId" render={(props) => <ProductDetail key={props.match.params.productId} {...props} />} />
        <PageRoute exact={true} path="/content/:contentId" render={(props) => <Content key={props.match.params.contentId} {...props} />} />

        {/* TODO: use AuthRoute */}
        <PageRoute exact={true} path="/order/:orderId" render={(props) => <OrderDetail />} />
        <PageRoute exact={true} path="/order" render={(props) => <OrderList key={props.location.search} {...props} />} />
        <PageRoute exact={true} path="/invoice" render={(props) => <InvoiceList key={props.location.search} {...props} />} />
        <PageRoute exact={true} path="/checkout/payment" render={(props) => <CartPaymentResponse {...props} /> } />

        {/* Routes that needs permission */}
        <AuthRoute exact={true} path="/cart" module={Modules.Webshop} feature={Features.Webshop.canAddToCart} component={CartComponent} />
        <AuthRoute exact={true} path="/checkout" module={Modules.Webshop} feature={Features.Webshop.canAddToCart} component={CartComponent} />
        <AuthRoute exact={true} path="/settings" module={Modules.General} feature={Features.General.UserSettings} component={UserSettings} />
        <AuthRoute
          exact={true}
          path="/favorites/:id?"
          module={Modules.Webshop}
          feature={Features.Webshop.favorites}
          render={(props) => <Favorites key={props.match.params.id} {...props} />}
        />

        <PageRoute render={() => <ErrorPage id="ERROR.PAGE_NOT_FOUND" />} />
      </Switch>
    </Suspense>
  );
};

import { Modal } from 'antd';

export default class HandleError extends Error {
  errorResponse;

  constructor({ name, status, errorResponse, message }) {
    super(message);
    this.name = name;
    this.status = status;
    this.errorResponse = errorResponse;
  }
}

export const showError = (error => {
  let title = error.messageLongDescription;
  let content = error.messageLongDescription;
  const errorMessageVariable = error.messageVariables.find(em => em.variableName === 'errorMessage');
  if (errorMessageVariable) {
    content = errorMessageVariable.variableValue;
  } else {
    title = error.messageShortDescription;
  }
  Modal.error({ title, content });
});


const exampleError = [
  {
    'messageId': 'E001',
    'messageTypeId': 'E',
    'messageIconId': 'exclamation-circle',
    'messageShortDescription': 'Error',
    'messageLongDescription': 'An error occured.',
    'messageVariables': [
      {
        'variableName': 'userId',
        'variableType': 'String',
        'variableValue': 'paul.mestrum@delaware.pro',
      }, {
        'variableName': 'errorMessage',
        'variableType': 'String',
        'variableValue': 'UserPortalRole is invalid',
      }],
  },
];


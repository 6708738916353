import { createAction } from 'redux-actions';
import {
  FAV_CLOSE_MODAL,
  FAV_OPEN_MODAL,
  FAV_SEARCH_IN_MODAL,
  FAV_SEARCH_IN_MODAL_FAILED,
  FAV_SEARCH_IN_MODAL_REQUEST,
  FAV_SEARCH_IN_MODAL_SUCCESS,
  FAV_SEARCH_IN_MODAL_LOAD_MORE,
} from './constants';

export const favOpenModal = createAction(FAV_OPEN_MODAL);
export const favCloseModal = createAction(FAV_CLOSE_MODAL);
export const favSearchInModal = createAction(FAV_SEARCH_IN_MODAL);
export const favSearchInModalRequest = createAction(FAV_SEARCH_IN_MODAL_REQUEST);
export const favSearchInModalSuccess = createAction(FAV_SEARCH_IN_MODAL_SUCCESS);
export const favSearchInModalFailed = createAction(FAV_SEARCH_IN_MODAL_FAILED);
export const favSearchInModalLoadMore = createAction(FAV_SEARCH_IN_MODAL_LOAD_MORE);

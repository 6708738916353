import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as actionTypes from './constants';

const loading = handleActions(
  {
    [actionTypes.INITIALIZE_APP_REQUEST]: () => true,
    [combineActions(actionTypes.INITIALIZE_APP_SUCCESS, actionTypes.INITIALIZE_APP_FAILED)]: () => false,
  }, false);

const error = handleActions({
  [actionTypes.INITIALIZE_APP_REQUEST]: () => false,
  [actionTypes.INITIALIZE_APP_FAILED]: () => true
}, false);

const errorMessage = handleActions({
  [actionTypes.INITIALIZE_APP_REQUEST]: () => false,
  [actionTypes.INITIALIZE_APP_FAILED]: (state, { payload }) => payload
}, false);

const publicLanguage = handleActions(
  {
    [combineActions(actionTypes.INIT_PUBLIC_LANGUAGE, actionTypes.SET_PUBLIC_LANGUAGE)]: (state, { payload }) => payload,
  }, null);

// switch to public to test out public version of B2B portal
const routeType = handleActions({ [actionTypes.SET_ROUTE_TYPE]: (state, { payload }) => payload }, 'private');

const unauthorize = handleActions({
  [actionTypes.SET_UNAUTHORIZE]: (state, { payload }) => true
}, false);

const prismicGlobalConfig = handleActions({
  [actionTypes.SET_PRISMIC_GLOBAL_CONFIG_LOADING]: (state, { payload }) => ({ loading: true }),
  [actionTypes.SET_PRISMIC_GLOBAL_CONFIG]: (state, { payload }) => ({ loading: false, data: payload })
}, { loading: false, data: null });

const disableUI = handleActions({
  [actionTypes.SET_DISABLE_UI]: (state, { payload }) => payload
}, false);

export default combineReducers({ routeType, publicLanguage, loading, error, errorMessage, unauthorize, prismicGlobalConfig, disableUI });

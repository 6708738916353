export const flattenJson = (data) => {
  const result = {};
  const recurse = (cur, prop) => {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i += 1)
        recurse(cur[i], prop + '[' + i + ']');
      if (l === 0)
        result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop)
        result[prop] = {};
    }
  };
  recurse(data, '');
  return result;
}

export const unflattenJson = (data) => {
  if (Object(data) !== data || Array.isArray(data))
    return data;
  var result = {}, cur, prop, idx, last, temp;
  for (var p in data) {
    cur = result, prop = '', last = 0;
    do {
      idx = p.indexOf('.', last);
      temp = p.substring(last, idx !== -1 ? idx : undefined);
      cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}));
      prop = temp;
      last = idx + 1;
    } while (idx >= 0);
    cur[prop] = data[p];
  }
  return result[''];
}

import React from 'react';
import Label from '../label';
import CurrencyFormat from '../currency-format';
import Div from 'ui/div';

const PricingLine = ({ pricingLine }) => {
  const { pricingTypeDescription, calculatedAmount } = pricingLine;
  return (
    <Div df fdr jcsb>
      <Label>{pricingTypeDescription}</Label>
      <Label marginLeft={30}>
        <CurrencyFormat value={calculatedAmount} hideSymbol={true} />
      </Label>
    </Div>
  )
}

export default PricingLine;

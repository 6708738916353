import React, { useContext } from 'react';
import Div from 'ui/div';
import { ThemeContext } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import ImageComponent from '../../common/image';
import CurrencyFormat from '../../common/currency-format';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';

const CartSidePanelItem = props => {
  const { image, size, name, productId, description, price = 0, quantity = 0, salesUnit, salesUnits, inPromotion } = props;
  const unitDisplay = quantity === 1 ? salesUnit : salesUnits;
  const theme = useContext(ThemeContext);

  const hidePrice = useFeature(Modules.Webshop, Features.Webshop.hidePriceOnPortal);

  return (
    <Div fdr padding="12px 14px" borderBottom borderBottomWidth={1} borderColor="#EDF1F3" position="relative">
      {inPromotion === '1' && (
        <Div pat padding="1px 4px" fontSize="10px" zIndex={1} fontWeight="600" background={theme.tag.promo.background} color={theme.tag.promo.color}>
          <FormattedMessage id="PRODUCT.TAG.PROMO" />
        </Div>
      )}
      <Div flexBasis="95px" flexShrink="0">
        <ImageComponent image={image} fontSize={12} />
      </Div>
      <Div width="100%" overflow="hidden">
        <Div fdc padding={size === 'sm' ? '0 15px' : '0 20px'} lineHeight="1.6rem">
          <Div fontSize="0.9rem" color="#34495e" ttu fontWeight="700" ellipsis={true} data-testid='productTitle'>
            {name}
          </Div>
          <Div fontSize={10} color="#BEC4CB" fontWeight={600} lineHeight="1em" data-testid='productId'>
            {productId}
          </Div>
          <Div paddingTop="13px" fontSize="0.88rem" fontWeight={300} lineHeight="22px">
            {description}
          </Div>
        </Div>
        <Div fdr jcsb marginTop="auto" fontWeight="600" padding="2px 4px 2px 16px" fontSize="18px">
          <span style={{ fontSize: '16px', color: '#BEC4CB', fontWeight: '300' }}>{quantity} {unitDisplay}</span>
          {!hidePrice && (
            <span style={{ textAlign: 'right' }}>
              <CurrencyFormat value={price} data-testid='productPrice' />
            </span>
          )}
        </Div>
      </Div>
    </Div>
  );
};

CartSidePanelItem.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CartSidePanelItem;

import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actionTypes from './constants';

const loading = handleActions(
  {
    [actionTypes.GET_FAVORITES_REQUEST]: () => true,
    [combineActions(actionTypes.GET_FAVORITES_SUCCESS, actionTypes.GET_FAVORITES_FAILED)]: () => false,
  },
  false,
);

const favorites = handleActions(
  {
    [actionTypes.GET_FAVORITES_SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

const listLoading = handleActions(
  {
    [actionTypes.GET_FAVORITES_LIST_REQUEST]: () => true,
    [combineActions(actionTypes.GET_FAVORITES_LIST_SUCCESS, actionTypes.GET_FAVORITES_LIST_FAILED)]: () => false,
  },
  false,
);

const listFavorites = handleActions(
  {
    [combineActions(actionTypes.CREATE_FAVORITE_LIST_SUCCESS, actionTypes.CREATE_FAVORITE_LIST_SUCCESS, actionTypes.DELETE_FAVORITE_LIST_SUCCESS)]: () => ({ listPage: [] }),
    [actionTypes.GET_FAVORITES_LIST_SUCCESS]: (state, { payload }) => {
      if (!state) {
        return payload;
      } else {
        return {
          ...state,
          listPage: [...state.listPage, ...payload.listPage],
        };
      }
    },
    [actionTypes.UPDATE_FAVORITE_LIST_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        listPage: state.listPage.map((list) => {
          if (list.favoriteListId === payload.listPage[0].favoriteListId) {
            return payload.listPage[0];
          } else {
            return list;
          }
        }),
      };
    },
  },
  {
    listPage: [],
  },
);

const createLoading = handleActions(
  {
    [actionTypes.CREATE_FAVORITE_LIST_REQUEST]: () => true,
    [combineActions(actionTypes.CREATE_FAVORITE_LIST_SUCCESS, actionTypes.CREATE_FAVORITE_LIST_FAILED)]: () => false,
  },
  false,
);

const updateLoading = handleActions(
  {
    [actionTypes.UPDATE_FAVORITE_LIST_REQUEST]: () => true,
    [combineActions(actionTypes.UPDATE_FAVORITE_LIST_SUCCESS, actionTypes.UPDATE_FAVORITE_LIST_FAILED)]: () => false,
  },
  false,
);

const selectedId = handleActions(
  {
    [actionTypes.SELECT_FAVORITE_LIST]: (state, { payload }) => payload,
  },
  null,
);

const total = handleActions(
  {
    [actionTypes.GET_FAVORITES_LIST_SUCCESS]: (state, { payload }) => payload.listSize,
  },
  0,
);

const page = handleActions(
  {
    [actionTypes.GET_FAVORITES_LIST_SUCCESS]: (state, { payload }) => payload.page,
  },
  0,
);

export default combineReducers({
  loading,
  favorites,
  listLoading,
  listFavorites,
  createLoading,
  updateLoading,
  selectedId,
  total,
  page,
});

import React from 'react';
import Favicon from 'react-favicon';
import useAPI from '../../hooks/useAPI';

const FaviconLoader = ({ url }) => {
  const [favicon, loading, error] = useAPI(url);
  if (loading || error || !favicon) return '';
  return <Favicon url={url} />;
};

export default FaviconLoader;

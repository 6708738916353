import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getShowCategory } from '../containers/category-selector';
import { toggleCategory, hideCategory } from '../containers/category-actions';
import Div from 'ui/div';
import CategoryLevel1 from './category-level-1';
import CategoryLevel2 from './category-level-2';
import { withRouter } from 'react-router-dom';
import CategoryDropdown from './category-dropdown';
import { getDisableUI } from '../../../app/containers/app-selectors';


const Navigation = props => {
  const { history } = props;
  const activeCategory = useSelector(getShowCategory);
  const dispatch = useDispatch();
  const callback = useRef();
  const delay = activeCategory ? 0 : 300;
  const disableUI = useSelector(getDisableUI);

  const _toggleCategory = cat => {
    if (!disableUI) {
      const isSameCategory = (activeCategory && activeCategory.id === cat.id);
      clearTimeout(callback.current);
      callback.current = setTimeout(() => {
        if (!isSameCategory) {
          dispatch(toggleCategory(cat));
        }
      }, delay);
    }
  };

  const _hideCategory = () => {
    clearInterval(callback.current);
    dispatch(hideCategory());
  }

  const onClickCategory = (id) => {
    if (!disableUI) {
      _hideCategory();
      history.push(`/category/${id}`);
    }
  }

  return (
    <Div noDefault onMouseLeave={() => _hideCategory()} className="phone-hide">
      <CategoryLevel1 showCategory={_toggleCategory} onClickCategory={onClickCategory}/>
      <CategoryDropdown>
        <CategoryLevel2 onClickCategory={onClickCategory}/>
      </CategoryDropdown>
    </Div>
  )
};

export default withRouter(Navigation);

import React, { useContext } from 'react';
import Html from '../../../ui/html';
import { ThemeContext } from 'styled-components';
import { prismicLinkResolver, getColor } from '../../../utils/prismic-utils';
import Div from '../../../ui/div';

var PrismicDOM = require('prismic-dom');

const Row = ({ row: {backgroundColor, padding}, children }) => {
  return (<Div backgroundColor={backgroundColor} padding={padding} className="grid-row">
    {children}
  </Div>);
}

const Column = ({ column: {backgroundColor, padding, weight, contentAlignment}, totalWeight, children }) => {
  const widthPerc = weight / totalWeight * 100;
  let alignItems;
  switch (contentAlignment) {
    case 'center':
      alignItems = 'center';
      break;
    case 'left':
      alignItems = 'flex-start';
      break;
    case 'right':
      alignItems = 'flex-end';
      break;
  }
  console.log(`alignItems: ${alignItems}`);
  return (<Div backgroundColor={backgroundColor} padding={padding} className="grid-column" width={`${widthPerc}%`} ai={alignItems}>
    {children}
  </Div>);
}

const Text = ({ text: {alignment, textColor, backgroundColor, padding, centered, text} }) => {
  const prismicHtml = PrismicDOM.RichText.asHtml(text, prismicLinkResolver);
  return (<Div color={textColor} backgroundColor={backgroundColor} padding={padding} className="grid-text prismic-text" tac={!!centered}>
    <Html content={prismicHtml}/>
  </Div>);
}

const generateRowColumnArrays = (elmts, theme) => {
  let rows = [];
  let currentRow;
  let currentColumn;

  const startRow = (padding, backgroundColor) => {
    if (currentRow) {
      endRow();
    }
    currentRow = { padding, backgroundColor, columns: [] };
    rows.push(currentRow);
  };

  const startColumn = (padding, backgroundColor, columnWeight, contentAlignment) => {
    if (currentColumn) {
      endColumn();
    }
    if (!currentRow) {
      startRow();
    }
    currentColumn = { padding, backgroundColor, bodies: [], weight: columnWeight || 1, contentAlignment };
    currentRow.columns.push(currentColumn);
  };

  const endRow = () => {
    if (currentColumn) {
      endColumn();
    }
    if (currentRow) {
      currentRow.totalWeight = currentRow.columns.reduce((total, column) => total + column.weight, 0);
      currentRow = null;
    }
  };

  const endColumn = () => {
    currentColumn = null;
  };

  elmts.forEach(elmt => {

    let {
      padding,
      'background-color': backgroundColor,
      'custom-background-color': customBackgroundColor,
      'column-weight': columnWeight,
      'content_alignment': contentAlignment,
    } = elmt.primary;
    backgroundColor = getColor(backgroundColor, customBackgroundColor, theme);

    switch (elmt['slice_type']) {
      case 'row':
        startRow(padding, backgroundColor);
        break;
      case 'column':
        startColumn(padding, backgroundColor, columnWeight, contentAlignment || 'center');
        break;
      case 'text':
        if (!currentColumn) {
          startColumn();
        }
        elmt.items.forEach(item => {
          let {
            alignment,
            padding,
            centered,
            'background-color': backgroundColor,
            'custom-background-color': customBackgroundColor,
            'text-color': textColor,
            'custom-text-color': customTextColor,
            text
          } = item;
          alignment = alignment ? alignment === 'right to left' ? 'rtl' : 'ltr' : null;
          textColor = getColor(textColor, customTextColor, theme);
          backgroundColor = getColor(backgroundColor, customBackgroundColor, theme);
          currentColumn.bodies.push({ alignment, textColor, backgroundColor, padding, centered, text });
        });
        break;
    }
  });
  endRow();
  return rows;
}

const Grid = ({ contentData }) => {

  const theme = useContext(ThemeContext);

  const rows = generateRowColumnArrays(contentData.data.body, theme);

  return (
    <Div className="prismic-grid">
      {rows.map((row, rowIndex) => {
        return <Row row={row} key={`row-${rowIndex}`}>
          {row.columns.map((column, columnIndex) => {
            return <Column column={column} totalWeight={row.totalWeight} key={`column-${rowIndex}-${columnIndex}`}>
              {column.bodies.map((text, textIndex) => {
                return <Text text={text}  key={`text-${rowIndex}-${columnIndex}-${textIndex}`}/>
              })}
            </Column>
          })}
        </Row>
      })}
    </Div>
  );
};

export default Grid;

import {IntlProvider} from 'react-intl';
import React from 'react';
import {useSelector} from 'react-redux';
import {getLocale, getTranslationsKey, getTranslationsSelector} from '../../store/translations/translations-selectors';
import * as moment from 'moment';
// Load all moment languages so that they are available
import 'moment/locale/en-gb';
import 'moment/locale/nl-be';
import 'moment/locale/fr-ch';
import 'moment/locale/de-ch';

// Define locale
let locale = 'en';
if (navigator.language) {
  const navLocale = navigator.language.substring(0, 2);
  if (['en', 'fr', 'de', 'nl'].includes(navLocale)) {
    locale = navLocale;
  }
}

// Component
const Translation = ({ children }) => {

  const translations = useSelector(getTranslationsSelector);
  const translationsKey = useSelector(getTranslationsKey);
  const locale = useSelector(getLocale);
  moment.locale(locale);

  return (
    <IntlProvider locale={locale} messages={translations} key={translationsKey} textComponent="span">
      {children}
    </IntlProvider>
  );
};

export default Translation;

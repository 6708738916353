import { useState, useEffect, useContext } from 'react';
import useAPI from '../../../hooks/useAPI';
import { ImageContext } from './image-provider';

export const useImage = path => {
  const { getImage, storeImage } = useContext(ImageContext);
  const [callApi, shoudCallApi] = useState(false);
  const [image, setImage] = useState();
  const [img, imgLoading, err, getImg] = useAPI(path, false);

  useEffect(() => {
    const imgFound = getImage(path);
    imgFound && setImage(imgFound);
    shoudCallApi(!imgFound);
  }, []);

  useEffect(() => {
    if (callApi) {
      getImg();
      shoudCallApi(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (img) {
      storeImage(path, img);
      setImage(img);
    }
  }, [img]);

  if (err || (imgLoading && !callApi)) {
    return null;
  }

  return image;
};

import React, { useContext } from 'react';
import Div from 'ui/div';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import { getShowCategory } from '../containers/category-selector';
import { useTransition, animated } from 'react-spring';

const CategoryDropdown = props => {
  const { children } = props;
  const theme = useContext(ThemeContext);
  const activeCategory = useSelector(getShowCategory);

  const transitions = useTransition(!!activeCategory, null, {
    from: { transform: 'translate3d(0,-50vw,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-50vw,0)' },
  });

  return (
    <Div noDefault maxheight="70vh" overflow="auto">
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ backgroundColor: "#eaeaea", ...props }}>
              <Div noDefault fdr margin="0 auto" width="100%" maxWidth={theme.body.innerWidth} paddingTop={24}>
                {children}
              </Div>
            </animated.div>
          )
      )}
    </Div >
  )
};


export default CategoryDropdown;

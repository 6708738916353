import React from 'react';
import { useSelector } from 'react-redux';
import { getUserSettings } from './user-selector';

// Cached translations
let cachedLocalStorage;
let cachedSessionStorage;
try {
  cachedLocalStorage = JSON.parse(localStorage.getItem('userSettings'));
  cachedSessionStorage = JSON.parse(sessionStorage.getItem('userSettings'));
} catch (e) {
  // Do nothing
}

// Only render messages when origin changes
let userSettingsIsCached = false;


export const UserSettingsContext = React.createContext(null);

const UserSettingsProvider = ({ children }) => {

  // First all the hooks
  let userSettings = useSelector(getUserSettings);
  if (!userSettings) {
    userSettings = {
      ...(cachedLocalStorage || {}),
      ...(cachedSessionStorage || {}),
    };
  } else if (!userSettingsIsCached) {
    userSettingsIsCached = true;
    const { user, ...nonSensitiveData } = userSettings;
    localStorage.setItem('userSettings', JSON.stringify(nonSensitiveData));
    sessionStorage.setItem('userSettings', JSON.stringify({ user }));
  }

  return (
    <UserSettingsContext.Provider value={userSettings}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsProvider;

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Div from 'ui/div';
import CurrencyFormat from '../currency-format';

const ConversionLabel = ({ selectedUnit, baseUnit, baseUnitPlural, large }) => {

  const theme = useContext(ThemeContext);
  const ifDifferentUnit = baseUnit !== selectedUnit.unit;

  return (
    <Div df aife flexWrap marginBottom={8} lineHeight={1}>
      <Div fontSize={large ? 36 : 18} fontWeight={large ? 200 : 600}>
        <CurrencyFormat value={selectedUnit.price} data-testid='productPrice' />
      </Div>
      <Div fontSize={large ? 24 : 12} margin='0 5px' ttu> / {selectedUnit.unit}</Div>
      {ifDifferentUnit && (
        <Div fontSize={large ? 24 : 12} ttu color={theme.body.subtitle}>{`(${selectedUnit.qtyPerUnit} ${selectedUnit.qtyPerUnit !== 1 ? baseUnitPlural : baseUnit})`}</Div>
      )}
    </Div>
  )
}

export default ConversionLabel;
import { createAction } from 'redux-actions';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_PROCEED,
  ADD_PRODUCTS,
  CREATE_ORDER,
  CREATE_ORDER_FAILED,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_BILLING_ADDRESSES,
  GET_BILLING_ADDRESSES_FAILURE,
  GET_BILLING_ADDRESSES_REQUEST,
  GET_BILLING_ADDRESSES_SUCCESS,
  GET_SHIPPING_ADDRESSES,
  GET_SHIPPING_ADDRESSES_FAILURE,
  GET_SHIPPING_ADDRESSES_REQUEST,
  GET_SHIPPING_ADDRESSES_SUCCESS,
  REMOVE_PRODUCT,
  SIMULATE_ORDER,
  SIMULATE_ORDER_FAILED,
  SIMULATE_ORDER_REQUEST,
  SIMULATE_ORDER_SUCCESS,
  GET_CART,
  GET_CART_FAILED,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  SET_CART,
  SET_CART_FAILED,
  SET_CART_REQUEST,
  SET_CART_SUCCESS,
  UPDATE_QUANTITY,
  REMOVE_ALL_PRODUCTS,
  UPDATE_PRODUCT,
  CART_UPDATE_PRODUCT_FAV,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
} from './constants';

export const addProducts = createAction(ADD_PRODUCTS);
export const removeAllProducts = createAction(REMOVE_ALL_PRODUCTS);

export const addProduct = createAction(ADD_PRODUCT);
export const addProductProceed = createAction(ADD_PRODUCT_PROCEED);
export const removeProduct = createAction(REMOVE_PRODUCT);
export const updateProduct = createAction(UPDATE_PRODUCT);
export const updateQuantity = createAction(UPDATE_QUANTITY);

export const getCartAction = createAction(GET_CART);
export const getCartRequestAction = createAction(GET_CART_REQUEST);
export const getCartSuccessAction = createAction(GET_CART_SUCCESS);
export const getCartFailedAction = createAction(GET_CART_FAILED);

export const setCartAction = createAction(SET_CART);
export const setCartRequestAction = createAction(SET_CART_REQUEST);
export const setCartSuccessAction = createAction(SET_CART_SUCCESS);
export const setCartFailedAction = createAction(SET_CART_FAILED);

export const simulateOrder = createAction(SIMULATE_ORDER);
export const simulateOrderRequest = createAction(SIMULATE_ORDER_REQUEST);
export const simulateOrderSuccess = createAction(SIMULATE_ORDER_SUCCESS);
export const simulateOrderFailed = createAction(SIMULATE_ORDER_FAILED);

export const getBillingAddresses = createAction(GET_BILLING_ADDRESSES);
export const getBillingAddressRequest = createAction(GET_BILLING_ADDRESSES_REQUEST);
export const getBillingAddressSuccess = createAction(GET_BILLING_ADDRESSES_SUCCESS);
export const getBillingAddressFailure = createAction(GET_BILLING_ADDRESSES_FAILURE);

export const getShippingAddresses = createAction(GET_SHIPPING_ADDRESSES);
export const getShippingAddressRequest = createAction(GET_SHIPPING_ADDRESSES_REQUEST);
export const getShippingAddressSuccess = createAction(GET_SHIPPING_ADDRESSES_SUCCESS);
export const getShippingAddressFailure = createAction(GET_SHIPPING_ADDRESSES_FAILURE);

export const getPaymentMethods = createAction(GET_PAYMENT_METHODS);
export const getPaymentMethodsRequest = createAction(GET_PAYMENT_METHODS_REQUEST);
export const getPaymentMethodsSuccess = createAction(GET_PAYMENT_METHODS_SUCCESS);
export const getPaymentMethodsFailure = createAction(GET_PAYMENT_METHODS_FAILURE);

export const createOrder = createAction(CREATE_ORDER);
export const createOrderRequest = createAction(CREATE_ORDER_REQUEST);
export const createOrderSuccess = createAction(CREATE_ORDER_SUCCESS);
export const createOrderFailed = createAction(CREATE_ORDER_FAILED);

export const cartUpdateProductFav = createAction(CART_UPDATE_PRODUCT_FAV);

export const getRouteLoading = state => state.app.loading;
export const getRouteType = state => state.app.routeType && state.app.routeType + '/' || '';
export const getError = state => state.app.error
export const getErrorMessage = state => state.app.errorMessage;
export const getIsPublic = state => state.app.routeType === 'public';

export const getPublicLanguage = state => state.app.publicLanguage;
export const getPublicLanguageParam = state => state.app.publicLanguage && `&locale=${state.app.publicLanguage}` || '';
export const getUnauthorize = state => state.app.unauthorize;
export const getDisableUI = state => state.app.disableUI;

export const getPrismicGlobalConfigLoading = state => (state.app.prismicGlobalConfig.loading);
export const getPrismicGlobalConfig = state => (state.app.prismicGlobalConfig.data);
export const getPlugins = state => (state.settings && state.settings.config && state.settings.config.data && state.settings.config.data.plugins) || [];
export const getPrismicPlugin = state => {
  const plugins = getPlugins(state);
  return plugins.find((plugin) => plugin && plugin.type === 'prismic');
};

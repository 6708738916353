import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Drawer, Menu } from 'antd';
import Icon from 'ui/icon';
import { getLevel1Categories, getSelectedLevel1CategoryGroupedInColumnsSelectorFactory, getShowCategory } from '../containers/category-selector';
import { useDispatch, useSelector } from 'react-redux';
import ObjectStoreImage from 'ui/object-store-image';
import { toggleCategory } from '../containers/category-actions';
import { withRouter } from 'react-router-dom';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import { getDisableUI } from '../../../app/containers/app-selectors';

const NavigationMobile = ({ history }) => {
  const [open, setOpen] = useState(false);
  const [activeDD, setActiveDD] = useState([]);
  const categories = useSelector(getLevel1Categories);
  const activeCategory = useSelector(getShowCategory);
  const categoriesAlphabetical = useFeature(Modules.Webshop, Features.Webshop.categoriesAlphabetical);
  const category2columns = useSelector(getSelectedLevel1CategoryGroupedInColumnsSelectorFactory(categoriesAlphabetical));
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const disableUI = useSelector(getDisableUI);

  const showDrawer = () => {
    !disableUI && setOpen(true);
  };

  const hideDrawer = () => {
    setOpen(false);
  };

  const showSubDrawer = (e) => {
    if (!disableUI) {
      const clickedItem = [...e].pop();
      const isSameCategory = activeCategory && activeCategory.id === clickedItem;
      const category = categories.find((cat) => cat.id === clickedItem);
      if (category) {
        if (!isSameCategory) {
          dispatch(toggleCategory(category));
          setActiveDD([clickedItem]);
        } else {
          setActiveDD(e);
        }
      } else {
        setActiveDD(e);
      }
    }
  };

  const onClick = (e) => {
    setOpen(false);
    history.push(`/category/${e.key}`);
  };

  return (
    <div className="phone-show">
      <Icon padding="10px" type="menu" onClick={showDrawer}/>
      <Drawer
        title={
          <div style={{ margin: '0 auto', maxWidth: '200px', padding: '10px' }}>
            <ObjectStoreImage path={theme.header.top.logo.url} width="100%" height="auto"/>
          </div>
        }
        placement={'left'}
        closable={true}
        onClose={hideDrawer}
        visible={open}
        headerStyle={{ padding: 0, borderRadius: 0, backgroundColor: theme.header.top.background }}
        bodyStyle={{ padding: '0 5px 0 0' }}
        closable={false}
      >
        <Menu mode="inline" onOpenChange={showSubDrawer} openKeys={activeDD} style={{ borderRight: 0 }}>
          {categories.map((category) => (
            <Menu.SubMenu level="1" key={category.id} title={<span onClick={() => onClick({ key: category.id })}>{category.description}</span>}>
              {category2columns.flat().map((category2) => (
                <Menu.SubMenu key={category2.id} title={<span onClick={() => onClick({ key: category2.id })}>{category2.description}</span>}>
                  {category2.children.map((category3) => (
                    <Menu.Item key={category3.id}>
                      <span onClick={() => onClick({ key: category3.id })}>{category3.description}</span>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      </Drawer>
    </div>
  );
};

export default withRouter(NavigationMobile);

import styled from 'styled-components';
import Div from '../../../../ui/div';

export const FooterBody = styled(Div)`
  width: ${(props) => props.theme.body.outerWidth};
`;

export const StyledTopFooter = styled(Div)`
  max-width: ${(props) => props.theme.body.outerWidth};
  width: ${(props) => props.theme.body.outerWidth};
  padding: 10px calc((${(props) => props.theme.body.outerWidth} - ${(props) => props.theme.body.innerWidth}) / 2);
  background-color: ${(props) => props.theme.footer.top.background};
  color: ${(props) => props.theme.footer.top.color};
`;

export const StyledBottomFooter = styled(Div)`
  max-width: ${(props) => props.theme.body.outerWidth};
  width: ${(props) => props.theme.body.outerWidth};
  padding: 0 calc((${(props) => props.theme.body.outerWidth} - ${(props) => props.theme.body.innerWidth}) / 2);
  background-color: ${(props) => props.theme.footer.bottom.background};
  color: ${(props) => props.theme.footer.bottom.color};
  a {
    margin-left: 20px;
  }
`;

import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './constants';
import * as appActionTypes from '../../app/containers/constants';
import { getTranslationsCache, getTranslationsFail, getTranslationsRequest, getTranslationsSuccess } from './translations-actions';
import callApi from '../../utils/api';
import { getRouteType, getPublicLanguageParam } from '../../app/containers/app-selectors';

function* getTranslations() {
  const routeType = yield select(getRouteType);
  const publicLangaugeParam = yield select(getPublicLanguageParam);

  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
  const cached = localStorage.getItem('translations');
  if (cached) {
    try {
      yield put(getTranslationsCache(JSON.parse(cached)));
    } catch (e) {
      localStorage.removeItem('translations');
    }
  }

  try {
    yield put(getTranslationsRequest());
    const data = yield call(callApi, {
      endpoint: `/object/json?path=files/translations.json&languageDependent=true${publicLangaugeParam}`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
      },
    });
    if (data) {
      localStorage.setItem('translations', JSON.stringify(data));
      yield put(getTranslationsSuccess(data));
    } else {
      yield put(getTranslationsFail());
    }
  } catch (e) {
    yield put(getTranslationsFail(e.message));
  }
}

function* watchGetTranslations() {
  yield takeLatest([actionTypes.GET_TRANSLATIONS, appActionTypes.SET_PUBLIC_LANGUAGE], getTranslations);
}

export default function* translationFlow() {
  yield all([fork(watchGetTranslations)]);
}

import React from 'react';
import { Col, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { favModal, favModalProduct } from '../containers/add-to-favorites-selector';
import { favCloseModal } from '../containers/add-to-favorites-actions';

import Div from 'ui/div';

import AddToFavoritesModalProduct from './add-to-favorites-modal-product';
import AddToFavoritesModalFavList from './add-to-favorites-modal-list';
import { setDisableUI } from '../../../../app/containers/app-actions';

const AddToFavoritesModal = () => {
  const visible = useSelector(favModal);
  const product = useSelector(favModalProduct);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(favCloseModal());
    dispatch(setDisableUI(false));
  };

  if (!product) return null;

  return (
    <Modal visible={visible} width="720px" footer={null} onCancel={onCancel}>
      <Div position="relative">
        <Row gutter={40}>
          <AddToFavoritesModalProduct product={product} />
          <AddToFavoritesModalFavList product={product} />
        </Row>
      </Div>
    </Modal>
  );
};

export default AddToFavoritesModal;

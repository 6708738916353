import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import callApi from '../../../utils/api';
import * as actionTypes from './constants';
import { getTranslationsSelector } from '../../../store/translations/translations-selectors';
import { sendRegistrationFailure, sendRegistrationRequest, sendRegistrationSuccess } from './registration-actions';

function* sendRegistration({ payload }) {
  const {data, history} = payload;
  const translations = yield select(getTranslationsSelector);
  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
  try {
    yield put(sendRegistrationRequest(data));
    const result = yield call(callApi, {
      endpoint: `user/approval`,
      options: {
        root: '/public/portal/api/v1',
        method: 'POST',
        body: JSON.stringify({
          ...data,
          operationId: "Create",
        }),
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(sendRegistrationSuccess(result));
    history.push('/registration/success');
  } catch (e) {
    console.error(e);
    yield put(sendRegistrationFailure(e.messages || translations['REGISTRATION.MESSAGE.SEND_FAILED']));
    history.push('/registration/error');
  }
}

function* watchSendRegistration() {
  yield takeLatest([actionTypes.SEND_REGISTRATION], sendRegistration);
}

export default function* userFlow() {
  yield all([fork(watchSendRegistration)]);
}

import React, { useRef, useState, useEffect } from 'react';
import { InputNumber, Button } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { addProduct, simulateOrder, updateProduct } from '../../cart/containers/cart-actions';
import ConversionList from './conversion-list';
import Div from 'ui/div';
import { FormattedMessage } from 'react-intl';
import ConversionLabel from './conversion-label';
import UIButton from 'ui/button';
import useFeature, { Modules, Features } from '../../../hooks/useFeature';
import { useMediaQuery } from 'react-responsive';

const StyledInputNumber = styled(InputNumber)`
  margin: 0 5px;
  width: 64px
  height: 32px;
  min-width: 64px;

  .ant-input-number {
    border-radius: 2px;
  }

  .ant-input-number-focused {
    box-shadow: none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input {
    text-align: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -5px;
`;

const UnitSelector = ({ product, onChange = () => null, noButton = false, large = false, customOnChange = () => null }) => {
  const {
    productKey,
    productId,
    unitConversions: conv,
    filteredUnitConversions = [],
    quantity,
    minOrderQuantity,
    batchQuantity,
    price,
    salesUnit: baseUnit,
    salesUnitId,
    selected,
    salesUnit,
    salesUnitPluralDescription,
    baseUnitOfMeasureId,
  } = product;

  const conversions = noButton ? filteredUnitConversions : conv ? conv : []; // fix null values
  const dispatch = useDispatch();
  const count = useRef(quantity || minOrderQuantity);
  const canAddToCart = useFeature(Modules.Webshop, Features.Webshop.canAddToCart);
  const hidePrice = useFeature(Modules.Webshop, Features.Webshop.hidePriceOnPortal);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [selectedUnit, setSelectedUnit] = useState();

  const mappedProduct = () => {
    return {
      ...product,
      productId: productId,
      quantity: count.current,
      selected: selectedUnit,
    };
  };

  useEffect(() => {
    if (!selectedUnit) {
      // when setting, the value will also be sent to parent (customOnChange)
      setSelectedUnit({
        price: selected ? selected.price : price,
        unit: selected ? selected.unit : baseUnit,
        qtyPerUnit: selected ? selected.qtyPerUnit : product.quantityInBaseUnitOfMeasure,
        salesUnitId: selected ? selected.salesUnitId : salesUnitId,
        unitOfMeasureShortDescription: selected ? selected.unitOfMeasureShortDescription : salesUnit,
        unitOfMeasurePluralDescription: selected ? selected.unitOfMeasurePluralDescription : salesUnitPluralDescription,
        minOrderQuantity: selected ? selected.minOrderQuantity : minOrderQuantity,
        batchQuantity: selected ? selected.batchQuantity : batchQuantity,
      });
    } else {
      if (noButton) {
        const payload = {
          productKey: productKey,
          product: mappedProduct(),
        };
        dispatch(updateProduct(payload));
      }
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (customOnChange) {
      customOnChange([
        {
          productId: productId,
          quantity: count.current,
          unitOfMeasure: selectedUnit?.salesUnitId,
          selectedUnit
        },
      ]);
    }
  }, [count.current, selectedUnit?.salesUnitId]);

  const updateValue = (val) => {
    // Allow only quantities that is divisible to batch quantity
    const isAllowed = Number.isInteger(val / selectedUnit.batchQuantity);
    if (Math.sign(val) > 0 && isAllowed) {
      const int = parseInt(val);
      onChange(int);
      count.current = int;
      if (customOnChange) {
        customOnChange([{ quantity: int, unitOfMeasure: selectedUnit.salesUnitId, selectedUnit }])
      }
      ;
    }
  };

  const increment = (e) => {
    e.stopPropagation();
    updateValue(Math.min(Number.MAX_SAFE_INTEGER, count.current + selectedUnit.batchQuantity));
    e.target.blur();
    e.preventDefault();
  };

  const decrement = (e) => {
    e.stopPropagation();
    updateValue(Math.max(count.current - selectedUnit.batchQuantity, selectedUnit.minOrderQuantity));
    e.target.blur();
  };

  const addToCart = () => {
    dispatch(addProduct(mappedProduct()));
  };

  const handleMenuClick = (e) => {
    const selected = e.key;
    const selectedConversion = conversions.find((c) => c.unitOfMeasureId === selected);
    setSelectedUnit({
      price: selectedConversion.price,
      unit: selectedConversion.unitOfMeasureShortDescription,
      qtyPerUnit: selectedConversion.quantityInBaseUnitOfMeasure,
      salesUnitId: selectedConversion.unitOfMeasureId,
      unitOfMeasureShortDescription: selectedConversion.unitOfMeasureShortDescription,
      unitOfMeasurePluralDescription: selectedConversion.unitOfMeasurePluralDescription,
      minOrderQuantity: selectedConversion.minOrderQuantity,
      batchQuantity: selectedConversion.batchQuantity,
    });
    count.current = selectedConversion.minOrderQuantity;
  };

  if (!selectedUnit) {
    return null; // unit is required to be able to render html
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!noButton && !hidePrice && (
        <ConversionLabel
          selectedUnit={selectedUnit}
          baseUnit={product.baseUnitOfMeasureShortDescription}
          baseUnitPlural={product.baseUnitOfMeasurePluralDescription}
          large={large}
        />
      )}
      <ActionButtons>
        <Div df aic margin="5px">
          <Button size="small" shape="circle" icon="minus" onClick={decrement}/>
          <StyledInputNumber min={selectedUnit.minOrderQuantity} max={Number.MAX_SAFE_INTEGER} value={count.current} onChange={updateValue}/>
          <Button size="small" shape="circle" icon="plus" onClick={increment}/>
        </Div>
        <Div margin="5px" flexGrow="1">
          <ConversionList
            handleMenuClick={handleMenuClick}
            conversions={conversions}
            unit={selectedUnit.unit}
            salesUnitId={selectedUnit.salesUnitId}
          />
        </Div>
        {!noButton && canAddToCart && (
          <Div flexBasis={large && !isMobile ? 'auto' : '100%'} margin="5px">
            <UIButton block type="primary" onClick={addToCart}>
              <FormattedMessage id="PRODUCT.BUTTON.ADD_TO_CART"/>
            </UIButton>
          </Div>
        )}
      </ActionButtons>
    </div>
  );
};

export default UnitSelector;

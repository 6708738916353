import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { getUnauthorize } from '../../../app/containers/app-selectors';
import { getTranslationsSelector } from '../../../store/translations/translations-selectors';

export const UnauthorizedContext = React.createContext(null);

function error(title, content) {
  Modal.error({
    title: title || 'Unauthorized',
    content: content || 'Please reload the page and login to continue.',
    onOk() {
      location.reload(true);
    },
  });
}


const UnauthorizedProvider = ({ children }) => {
  const translations = useSelector(getTranslationsSelector);
  const title = translations['ERROR.UNAUTHORIZE.TITLE'];
  const content = translations['ERROR.UNAUTHORIZE.DESCRIPTION'];

  const [unauthorized, setUnauthorized] = useState(false);
  const isUnauthorize = useSelector(getUnauthorize);
  return (
    <UnauthorizedContext.Provider value={{ setUnauthorized }}>
      {(isUnauthorize || unauthorized) && error(title, content)}
      {children}
    </UnauthorizedContext.Provider>
  )
}

export default UnauthorizedProvider;

import { handleActions, combineActions } from 'redux-actions';
import * as actionTypes from './constants';
import * as userActionTypes from '../../../modules/user/containers/constants';

let initialState = {
  config: {
    loaded: false,
    loading: false,
    failed: false,
    errorMessage: false,
    data: null
  },
  content: {
    loaded: false,
    loading: false,
    failed: false,
    errorMessage: false,
    data: null
  },
  theming: {
    themes: [],
    current: null
  }
};

export const reducer = handleActions({
  [combineActions(
    userActionTypes.SET_USER_SETTINGS_SUCCESS,
    userActionTypes.GET_USER_SETTINGS_SUCCESS
  )]: (state, { payload }) => {
    return ({
      ...state,
      theming: {
        ...state.theming,
        current: state.theming.themes.find(t => t.name === payload.additionalUserSettings.theme) || state.theming.current
      }
    });
  },
  [actionTypes.GET_CONFIG_SUCCESS]: (state, { payload }) => {
    const themes = payload.themes && [...payload.themes] || [];
    if (payload.style) {
      themes.push({
        ...payload.style,
        name: 'default',
      });
    }
    return {
      ...state,
      config: {
        ...initialState.config,
        loaded: true,
        data: payload
      },
      theming: {
        themes: themes,
        current: themes.length && themes[0]
      }
    };
  },
  [actionTypes.GET_CONTENT_REQUEST]: (state) => ({
    ...state,
    content: {
      ...initialState.content,
      loading: true,
    }
  }),
  [actionTypes.GET_CONTENT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      content: {
        ...initialState.content,
        loaded: true,
        data: payload
      }
    };
  },
  [actionTypes.GET_CONTENT_FAILURE]: (state, { payload }) => ({
    ...state,
    content: {
      ...initialState.content,
      failed: true,
      errorMessage: payload
    }
  }),
}, initialState);

export default reducer;

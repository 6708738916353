import React from 'react';
import * as PropTypes from 'prop-types';

const Html = ({ content }) => {
  return <div dangerouslySetInnerHTML={{__html:content}}></div>;
};

Html.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Html;

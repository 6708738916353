import React from 'react';
import Div from 'ui/div';
import { useSelector } from 'react-redux';
import { getSelectedLevel1CategoryGroupedInColumnsSelectorFactory } from '../containers/category-selector';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import CategoryLevel3 from './category-level-3';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';

const CategoryLevel2 = props => {
  const { onClickCategory } = props;
  const categoriesAlphabetical = useFeature(Modules.Webshop, Features.Webshop.categoriesAlphabetical);
  const category2columns = useSelector(getSelectedLevel1CategoryGroupedInColumnsSelectorFactory(categoriesAlphabetical));

  return (
    <Row gutter={20} style={{ width: '100%' }}>
      {category2columns.map((categories, categoriesIndex) => (
        <Col md={12} lg={6} key={categoriesIndex}>
          <Div noDefault paddingBottom={40}>
            {categories.map((category, categoryIndex) => (
              <Div noDefault key={categoryIndex} padding="0 20px 20px">

                {/* Level 2 Navigation */}
                <Div noDefault fontWeight={600} fontSize="12px" ttu>
                  <Link to={`/category/${category.id}`} onClick={() => { onClickCategory(category.id) }}>
                    {category.description}
                  </Link>
                </Div>

                {/* Level 3 Navigation */}
                <CategoryLevel3 category={category.children} onClickCategory={onClickCategory} />
              </Div>
            ))}
          </Div>
        </Col>
      ))}
    </Row>
  )
};


export default CategoryLevel2;

import { useState, useEffect, useContext } from 'react';
import { debug as logDebug, error as logError } from '@dlw/dlw-logger';
import 'whatwg-fetch';
import { checkStatus } from 'utils/check-api-status';
// TODO: auth-manager to be replaced with authentication package.
import authManager from 'utils/auth-manager';
import { UnauthorizedContext } from '../modules/common/error/unauthorized';
import { useSelector } from 'react-redux';
import { getRouteType, getPublicLanguageParam } from '../app/containers/app-selectors';

const DISABLE_AUTHENTICATION = true;

export default function useAPI(url, immediately = true, options = {}, skipPublicPrivate = false) {
  const [isLoading, setIsLoading] = useState(immediately);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const auth = useContext(UnauthorizedContext);
  let routeType = useSelector(getRouteType);
  const publicParam = useSelector(getPublicLanguageParam);

  useEffect(() => {
    if (immediately) {
      fetchUrl();
    }
  }, []);

  async function authenticate() {
    try {
      let token = await authManager.getToken();
      if (!token) {
        token = await authManager.login();
      }

      return token;
    } catch (err) {
      logError('useAPI hook - unknown login error', err);
    }
  }

  async function fetchUrl() {
    preFetch();

    let headers = {};
    if (!DISABLE_AUTHENTICATION) {
      const token = await authenticate();
      if (!token) {
        failure(new Error('Unsuccesful authentication'));
      }
      headers = { authorization: `Bearer ${token}` };
    }

    try {
      let combinedUrl;
      if (skipPublicPrivate) {
        combinedUrl = url;
      } else {
        combinedUrl = `${routeType}${url}`.replace(/\/\//g, '/');
        if (publicParam) {
          combinedUrl = combinedUrl.includes('?') ? `${combinedUrl}${publicParam}` : `${combinedUrl}?${publicParam.substr(1)}`;
        }
      }
      const response = await fetch(combinedUrl, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
          ...(options && options.headers),
        },
      });
      await checkStatus({ response, url });
      const contentType = response.headers.get('content-type');
      let json;
      if (contentType && contentType.indexOf('application/json') === -1) {
        if (contentType.indexOf('text/html') !== -1) {
          failure({ status: 401 });
        } else if (contentType.indexOf('image') !== -1) {
          const img = await response.blob();
          json = await URL.createObjectURL(img);
        } else {
          json = await response.text();
        }
      } else {
        json = await response.json();
      }
      success(json);
    } catch (err) {
      failure(err);
    }
  }

  function preFetch() {
    logDebug({
      message: `useAPI hook - preparing to fetch ${url}`,
      properties: { url },
    });
    setIsLoading(true);
    setError(null);
    setData(undefined);
  }

  function success(data) {
    logDebug({
      message: `useAPI hook - fetch successful for ${url}`,
      properties: { url, data },
    });
    setData(data);
    setIsLoading(false);
    setError(null);
  }

  function failure(error) {
    logError({
      message: `${error} (thrown inside useAPI hook)`,
      properties: { url, error },
    });
    setIsLoading(false);
    setError(error);
    setData(undefined);
    if (error && error.status && error.status === 401) {
      auth.setUnauthorized(true);
    }
  }

  return [data, isLoading, error, fetchUrl];
}

import { call, put } from 'redux-saga/effects';
import callApi from './api';
import { getRouteType, getPublicLanguageParam } from '../app/containers/app-selectors';
import { select } from '@redux-saga/core/effects';

export function* fetchJson({ successAction, failureAction, path: configPath, languageDependent: configLanguageDependent, root = '/portal/api/v1' }, { payload }) {
  const path = configPath || payload && payload.path;
  const routeType = yield select(getRouteType);
  const languageDependent = configLanguageDependent || payload && payload.languageDependent || false;
  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
  const publicParam = yield select(getPublicLanguageParam);
  try {
    const data = yield call(callApi, {
      endpoint: `object/json?path=${path}&languageDependent=${languageDependent}${publicParam}`,
      options: {
        root: `${routeType}${root}`.replace(/\/\//g, '/'),
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(successAction(data));
  } catch (e) {
    yield put(failureAction(e.message));
  }
}

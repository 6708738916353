import { createSelector } from 'reselect';
import { getContent } from '../../../utils';

export const getAppTitle = createSelector(
  [
    state => state.settings && state.settings.config.data,
    state => state.settings && state.settings.content.data,
  ],
  (config, content) => {
    if (config && content) {
      return getContent(content, config.app.name['content-id'], config.app.name['default-value']);
    } else {
      return 'loading...';
    }
  },
);

// app.header.assistance
export const getAssistance = createSelector(
  [
    state => state.settings && state.settings.config.data,
    state => state.settings && state.settings.content.data,
  ],
  (config, content) => {
    if (config && content) {
      const defaultValue = config.app.header && config.app.header.assistance && config.app.header.assistance['default-value'];
      const contentId = config.app.header && config.app.header.assistance && config.app.header.assistance['content-id'];
      if (defaultValue || contentId) {
        return getContent(content, contentId, defaultValue);
      }
    }
    return null;
  },
);

export const getAllThemes = state => state.settings && state.settings.config && state.settings.theming.themes || [];

export const getAllLanguages = createSelector(
  [state => state.settings.config.data.languages],
  languages => languages || [],
);

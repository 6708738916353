import React from 'react';
import { Divider, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Div from '../../../ui/div';
import styled from 'styled-components';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import { FormattedMessage } from 'react-intl';

const StyledDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledMenuItem = styled(Menu.Item)`
  pointer-events: none;
`;

const userMenu = () => {
  const canAccessUserSettings = useFeature(Modules.General, Features.General.UserSettings);
  const canLogOut = useFeature(Modules.General, Features.General.Logout);
  const canAccessOrders = useFeature(Modules.OrderTracking);
  const canAccessInvoices = useFeature(Modules.InvoiceTracking);
  const canAccessFavorites = useFeature(Modules.Webshop, Features.Webshop.canAccessFavorites);

  const showDivider = canLogOut && (canAccessUserSettings || canAccessOrders);

  const logout = () => {
    window.location.href = '/do/logout';
  };

  return (
    <Menu data-testid='menu' style={{ marginTop: '15px' }}>
      {canAccessOrders && (
        <Menu.Item data-testid='menuItemHistory'>
          <Link to={`/order`} key="order-history">
            <Div ttn><FormattedMessage id="ORDER.LIST.ORDER_HISTORY" /></Div>
          </Link>
        </Menu.Item>
      )}
      {canAccessInvoices && <Menu.Item data-testid='menuItemInvoice'>
        <Link to={`/invoice`} key="invoice-history">
          <Div ttn><FormattedMessage id="INVOICE.TITLE" /></Div>
        </Link>
      </Menu.Item>}
      {canAccessUserSettings && (
        <Menu.Item data-testid='menuItemSettings'>
          <Link to={`/settings`} key="settings">
            <Div ttn><FormattedMessage id="USER_SETTINGS.TITLES.MY_SETTINGS" /></Div>
          </Link>
        </Menu.Item>
      )}
      {canAccessFavorites && (
        <Menu.Item data-testid='menuItemFavorites'>
          <Link to={`/favorites`} key="favorites">
            <Div ttn><FormattedMessage id="FAVORITES.TITLES.MY_FAVORITES" /></Div>
          </Link>
        </Menu.Item>
      )}
      {showDivider && (
        <StyledMenuItem>
          <StyledDivider />
        </StyledMenuItem>
      )}
      {canLogOut && (
        <Menu.Item key="logout" onClick={logout} data-testid='menuItemLogout'>
          <Div ttn><FormattedMessage id="APP.HEADER.USER_NAV.LOGOUT" /></Div>
        </Menu.Item>
      )}
    </Menu>
  );
};

userMenu.propTypes = {};

export default userMenu;

import { css } from 'styled-components';
import format from './format';

export const mainProps = (props) => css`
  ${!props.noDefault && "font-family: 'Montserrat', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif"};
  ${!props.noDefault && 'font-size: 16px'};
  ${!props.noDefault && 'color: #333f48'};
`;

export const textAlign = (props) => css`
  ${props.ta && `text-align: ${props.ta}`};
  ${props.tac && 'text-align: center'};
  ${props.tar && 'text-align: right'};
`;

export const textDecoration = (props) => css`
  ${props.tdlt && 'text-decoration: line-through'};
  ${props.tdu && 'text-decoration: underline'};
`;

export const textTransform = (props) => css`
  ${props.ttu && 'text-transform: uppercase'};
  ${props.ttc && 'text-transform: capitalize'};
  ${props.ttl && 'text-transform: lowercase'};
  ${props.ttn && 'text-transform: none !important'};
`;

export const widthProps = (props) => css`
  ${props.width && `width: ${format(props.width)} !important`};
  ${props.minWidth && `min-width: ${format(props.minWidth)}`};
  ${props.maxWidth && `max-width: ${format(props.maxWidth)}`};
  ${props.calcWidth && `width: calc(100% - ${format(props.calcWidth)})`};
`;
export const heightProps = (props) => css`
  ${props.height && `height: ${format(props.height)}`};
  ${props.minheight && `min-height: ${format(props.minheight)}`};
  ${props.maxheight && `max-height: ${format(props.maxheight)}`};
  ${props.calcHeight && `height: calc(100% - ${format(props.calcHeight)})`};
`;
export const marginProps = (props) => css`
  ${props.marginBottom && `margin-bottom: ${format(props.marginBottom)} !important`};
  ${props.marginTop && `margin-top: ${format(props.marginTop)} !important`};
  ${props.marginLeft && `margin-left: ${format(props.marginLeft)} !important`};
  ${props.marginRight && `margin-right: ${format(props.marginRight)} !important`};
  ${props.margin && `margin:${format(props.margin)}`};
  ${props.noMarginRightMobile &&
  `@media all and (max-width: 554px) {
    margin-right: 0 !important;
  }`};
  ${props.marginMobile &&
  `@media all and (max-width: 554px) {
      margin-right: ${format(props.marginMobile)} !important;
      margin-left: ${format(props.marginMobile)} !important;
    }`};
`;

/**
 * props:
 *   paddingBottom
 *   paddingTop
 *   paddingLeft
 *   paddingRight
 *   padding
 */
export const paddingProps = (props) => css`
  ${props.paddingBottom && `padding-bottom: ${format(props.paddingBottom)}`};
  ${props.paddingTop && `padding-top: ${format(props.paddingTop)}`};
  ${props.paddingLeft && `padding-left: ${format(props.paddingLeft)}`};
  ${props.paddingRight && `padding-right: ${format(props.paddingRight)}`};
  ${props.paddingVertical && `padding-top: ${format(props.paddingVertical)}; padding-bottom: ${format(props.paddingVertical)}`};
  ${props.paddingHorizontal && `padding-left: ${format(props.paddingHorizontal)}; padding-right: ${format(props.paddingHorizontal)};`};
  ${props.padding && `padding:${format(props.padding)}`};
`;

export const borderProps = (props) => css`
  ${props.border && `border: ${props.border}`};
  ${props.borderTop && `border-top: ${props.borderTop}`};
  ${props.borderRight && `border-right: ${props.borderRight}`};
  ${props.borderBottom && `border-bottom: ${props.borderBottom}`};
  ${props.borderLeft && `border-left: ${props.borderLeft}`};
  ${props.borderWidth && `border-width: ${format(props.borderWidth)}`};
  ${props.borderColor && `border-color: ${props.borderColor}`};
  ${props.borderWidth && `border-style: solid`};
  ${props.borderRadius && `border-radius: ${format(props.borderRadius)}`};
  ${props.borderBottom && `border-bottom-style: solid`};
  ${props.borderBottomWidth && `border-bottom-width: ${format(props.borderBottomWidth)}`};
  ${props.borderBottomColor && `border-bottom-color: ${props.borderBottomColor}`};
  ${props.borderLeft && `border-left-style: solid`};
  ${props.borderLeftWidth && `border-left-width: ${format(props.borderLeftWidth)}`};
  ${props.borderLeftColor && `border-left-color: ${props.borderLeftColor}`};
  ${props.borderTop && `border-top-style: solid`};
  ${props.borderTopWidth && `border-top-width: ${format(props.borderTopWidth)}`};
  ${props.borderTopColor && `border-top-color: ${props.borderTopColor}`};
  ${props.borderRight && `border-right-style: solid`};
  ${props.borderRightWidth && `border-right-width: ${format(props.borderRightWidth)}`};
  ${props.borderRightColor && `border-right-color: ${props.borderRightColor}`};

  ${props.cbb && `border-bottom: 1px solid ${props.theme.body.subtitle}; padding-bottom: 10px;margin-bottom: 10px;`}; // custom border bottom
  ${props.cbt && `border-top: 1px solid ${props.theme.body.subtitle}; padding-top: 10px;margin-top: 10px;`}; // custom border top
`;

export const fontProps = (props) => css`
  ${props.fontSize && `font-size: ${format(props.fontSize)}`};
  ${props.fontStyle && `font-style: ${format(props.fontStyle)}`};
  ${props.fontStyleItalic && `font-style: italic`};
  ${props.fontWeight && `font-weight: ${props.fontWeight}`};
  ${props.color && `color: ${props.color}`};
`;

/**
 * props:
 *   df         flex
 *   dfrw       flex row wrap
 *   fdc        flex column direction
 *   fdr        flex row direction
 *   jcsb       flex justify content: space between
 *   jcsa       flex justify content: space around
 *   jcfe       flex justify content: flex-end
 *   jcc        flex justify content: center
 *   aic        align items: center
 *   aife       align items: flex-end
 *   flexGrow   flex-grow
 *   flexShrink flex-shrink
 *   flexWrap   flex-wrap
 */
// eslint-disable-next-line no-use-before-define
export const flexProps = (props) => css`
  ${props.df && `display: flex`};
  ${props.dfi && `display: flex !important`};
  ${props.dfrw && `display: flex; flex-flow: row wrap`};
  ${props.fdc && `flex-direction: ${'column'}`};
  ${props.fdr && `flex-direction: ${'row'}`};
  ${props.jcsb && `justify-content: ${'space-between'}`};
  ${props.jcsa && `justify-content: ${'space-around'}`};
  ${props.jcfe && `justify-content: ${'flex-end'}`};
  ${props.jcc && `justify-content: ${'center'}`};
  ${props.ai && `align-items: ${props.ai}`};
  ${props.aic && `align-items: ${'center'}`};
  ${props.aife && `align-items: ${'flex-end'}`};
  ${props.flex && `flex: ${props.flex}`};
  ${(props.fdc || props.fdr || props.jcc || props.jcsa || props.jcsb || props.jcfe) && 'display: flex'};
  ${props.flexGrow && `flex-grow: ${props.flexGrow}`};
  ${props.flexShrink && `flex-shrink: ${props.flexShrink}`};
  ${props.flexWrap && `flex-wrap: ${'wrap'}`};
  ${props.flexBasis && `flex-basis: ${props.flexBasis}`};
`;

export const fixedProps = (props) => css`
  ${(props.pft || props.pfb || props.pfl || props.pfr) && 'position: fixed'};
  ${props.pft && `top: ${format(props.pft)}`};
  ${props.pfb && `bottom:  ${format(props.pfb)}`};
  ${props.pfl && `left:  ${format(props.pfl)}`};
  ${props.pfr && `right:  ${format(props.pfr)}`};
`;

export const absoluteProps = (props) => css`
  ${(props.pat || props.pab || props.pal || props.par) && 'position: absolute'};
  ${props.pat && `top: ${format(props.pat)}`};
  ${props.pab && `bottom:  ${format(props.pab)}`};
  ${props.pal && `left:  ${format(props.pal)}`};
  ${props.par && `right:  ${format(props.par)}`};
`;

export const relativeProps = (props) => css`
  ${(props.prt || props.prb || props.prl || props.prr) && 'position: relative'};
  ${props.prt && `top: ${format(props.prt)}`};
  ${props.prb && `bottom:  ${format(props.prb)}`};
  ${props.prl && `left:  ${format(props.prl)}`};
  ${props.prr && `right:  ${format(props.prr)}`};
`;

export const positionProps = (props) => css`
  ${props.position && `position: ${props.position}`};
`;

export const displayProps = (props) => css`
  ${props.hide && 'display: none'};
  ${props.show && 'display: block'};
`;

export const backgroundProps = (props) => css`
  ${props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${props.backgroundSize && `background-size: ${props.backgroundSize}`};
  ${props.background && `background: ${props.background}`};
`;

export const scrollProps = (props) => css`
  ${props.scroll && `overflow: auto`};
  ${props.scroll && `overflow-x: hidden`};
`;
export const indentProps = (props) => css`
  ${props.indent && `margin-left: ${props.indent}em`};
`;

export const extraProps = (props) => css`
  ${props.cursor && `cursor: ${props.cursor}`};
  ${props.zIndex && `z-index: ${props.zIndex}`};
  ${props.lineHeight && `line-height: ${props.lineHeight}`}
  ${props.overflow && `overflow: ${props.overflow}`}
  ${props.ellipsis && `white-space: nowrap`}
  ${props.ellipsis && `overflow: hidden`}
  ${props.ellipsis && `text-overflow: ellipsis`}
  ${props.wordBreak && `word-break: break-word`}
  ${props.allBreak && `word-break: break-all`}
  ${props.wrapWord && `white-space: normal`}
`;

export const mobileProps = (props) => css`
  @media all and (min-width: 865px) {
    ${props.showMobile && `display: none`}
    ${props.hideMobile && `display: block`}
  }
  @media all and (max-width: 865px) {
    ${props.hsm &&
    `span {
      display: none
    }`};
    ${props.hideMobile && `display: none`}
    ${props.showMobile && `display: block`}
  }

  @media all and (max-width: 700px) {
    ${props.dfrw && `display: flex; flex-flow: column wrap`};
  }
`;

export const shadowProps = (props) => css`
  ${props.shadowCentralLight &&
  `
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  `}
  ${props.boxShadow && `box-shadow: ${props.boxShadow}`};
`;

import React, { useState, useRef } from 'react';
import Div from 'ui/div';
import { IconFA } from 'ui/icon';
import { Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createFavoriteList } from '../containers/favorites-actions';
import { createFavoritesLoading } from '../containers/favorites-selector';
import { FormattedMessage, useIntl } from 'react-intl';

const CreateFavoritesList = () => {
  const maxLength = 30;
  const createLoading = useSelector(createFavoritesLoading);
  const [visible, setVisible] = useState(false);
  const input = useRef();
  const dispatch = useDispatch();
  const intl = useIntl();

  const createButtonText = intl.formatMessage({ id: 'FAVORITES.BUTTONS.CREATE' });
  const createListLabel = intl.formatMessage({ id: 'FAVORITES.LABEL.CREATE_NEW_LIST' });
  const favoritesPlaceholderLabel = intl.formatMessage({ id: 'FAVORITES.LABEL.NAME_PLACEHOLDER' });

  const showModal = () => {
    setVisible(true);
  };

  const handleCreate = () => {
    const { value } = input.current.state;
    if (value) {
      dispatch(createFavoriteList({ name: value, callback }));
    }
  };

  const callback = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Div cursor="pointer" paddingBottom={12} borderBottom="1px solid #e8e8e8" df jcsb aic onClick={showModal}>
        <FormattedMessage id="FAVORITES.BUTTONS.CREATE_NEW_LIST" /> <IconFA icon="fa-plus-square" style={{ fontSize: '20px' }} />
      </Div>
      <Modal destroyOnClose={true} confirmLoading={createLoading} title={createListLabel} visible={visible} onOk={handleCreate} onCancel={handleCancel} okText={createButtonText}>
        <Input ref={input} placeholder={favoritesPlaceholderLabel} disabled={createLoading} maxLength={maxLength} />
        <Div color="rgba(0, 0, 0, 0.45)" fontSize={12}>
          <FormattedMessage id="FAVORITES.ERRORS.MAXIMUM_CHARACTERS" values={{ maxLength }} />
        </Div>
      </Modal>
    </div>
  );
};

export default CreateFavoritesList;

import React from 'react';
import { Link } from 'react-router-dom';
import Div from 'ui/div';

const CategoryLevel3 = (props) => {
  const { category = [], onClickCategory } = props;

  return (
    <Div noDefault>
      {category.map(item =>
        <Link key={item.id} to={`/category/${item.id}`} onClick={() => { onClickCategory(item.id) }}>
          <Div cursor="pointer" fontSize={13} lineHeight="22px">
            {item.description}
          </Div>
        </Link>
      )}
    </Div>

  )
}

export default CategoryLevel3;

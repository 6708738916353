import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootSaga from '../sagas';

const LOGGING_ENABLED = false;

const logger = createLogger({
  predicate: (() => LOGGING_ENABLED),
});
export const sagaMiddleware = createSagaMiddleware();

export default function configureStore(rootReducer) {
  let createStoreWithMiddleware;
  if (process.env.NODE_ENV === 'development') {
    const composeEnhancers =
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    createStoreWithMiddleware = composeEnhancers(
      applyMiddleware(thunk, logger, sagaMiddleware),
    )(createStore);
  } else {
    createStoreWithMiddleware = compose(applyMiddleware(thunk, sagaMiddleware))(
      createStore,
    );
  }

  const store = createStoreWithMiddleware(rootReducer);
  sagaMiddleware.run(rootSaga);

  return store;
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRouteLoading } from './containers/app-selectors';

import ConfigurableThemeProvider from './theming/configurable-theme-provider';
import UserSettingsProvider from '../modules/user/containers/user-settings-provider';
import UnauthorizedProvider from '../modules/common/error/unauthorized';
import ImageProvider from '../modules/common/image/image-provider';
import PortalNotAvailableProvider from '../modules/common/error/unavailable';
import { getIsAuthenticated } from '../modules/user/containers/user-selector';

export const App = React.createContext(null);

const AppProvider = ({ children }) => {
  let isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    console.log(`isAuthenticated: ${isAuthenticated}`)
  }, [isAuthenticated]);

  const loading = useSelector(getRouteLoading);
  if (loading) {
    return null;
  }

  return (
    <PortalNotAvailableProvider>
      <ConfigurableThemeProvider>
        <UserSettingsProvider>
          <ImageProvider>
            <UnauthorizedProvider>
              {children}
            </UnauthorizedProvider>
          </ImageProvider>
        </UserSettingsProvider>
      </ConfigurableThemeProvider>
    </PortalNotAvailableProvider>

  );
};

export default AppProvider;

import HandleError from './error-handler';
export const checkStatus = async ({ response, url }) => {
  /*
  Default status codes also present in MW template
  https://wiki.delawareconsulting.com/pages/viewpage.action?pageId=122061198
  400 - Front-end can't fix the problem	- BusinessException
  401 - Not authenticated
  403 - Not authorized for the requested action
  409 - Front-end must take action exception - Is not logged in App Insights
  500 - MW bug - TechnicalException
  502 - Backend problem	- eg SAP
  */

  if (!response) {
    throw Error(`Uncaught (no response) - Something went wrong calling ${url}`);
  }

  let errorResponse;

  if (response.status >= 400) {
    try {
      const reader = await response.body.getReader();
      const result = await reader.read();
      const errorResponseString = new TextDecoder("utf-8").decode(result.value);
      errorResponse = JSON.parse(errorResponseString);
    } catch (e) {
    }
  }

  if (response.status === 400) {
    throw new HandleError({
      name: 'Business exception',
      status: 400,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (response.status === 401) {
    throw new HandleError({
      name: 'Unauthorized',
      status: 401,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (response.status === 403) {
    throw new HandleError({
      name: 'Forbidden',
      status: 403,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (response.status === 404) {
    throw new HandleError({
      name: 'Not Found',
      status: 404,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (status > 404 && status < 500 && status !== 409) {
    throw Error(
      errorResponse && errorResponse.error || `Uncaught - ${url} responded with a status code ${response.status}`,
    );
  }

  if (response.status === 500) {
    throw new HandleError({
      name: 'Server Error',
      status: 500,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (response.status === 502) {
    throw new HandleError({
      name: 'Backend Error',
      status: 502,
      errorResponse,
      message: errorResponse && errorResponse.error || `${url} responded with a status code ${response.status}`,
    });
  }

  if (status > 500) {
    throw Error(
      errorResponse && errorResponse.error || `Uncaught - ${url} responded with a status code ${response.status}`,
    );
  }
  return null;
};

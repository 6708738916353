import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actionTypes from './constants';
import { ADD_TO_FAVORITES_SUCCESS, DELETE_FAVORITE_LIST_SUCCESS } from '../../favorites/containers/constants';

const loading = handleActions(
  {
    [actionTypes.SIMULATE_ORDER_REQUEST]: () => true,
    [combineActions(actionTypes.SIMULATE_ORDER_SUCCESS, actionTypes.SIMULATE_ORDER_FAILED)]: () => false,
  },
  false,
);

const loadingCreateOrder = handleActions(
  {
    [actionTypes.CREATE_ORDER_REQUEST]: () => true,
    [combineActions(actionTypes.CREATE_ORDER_SUCCESS, actionTypes.CREATE_ORDER_FAILED)]: () => false,
  },
  false,
);

const removePricingLines = (product) => {
  return {
    ...product,
    pricingLines: [],
    netAmount: null,
  };
};

const updateQuantity = (cart, product, quantity) => {
  return cart.map((item) => {
    const isNotCurrent = item.productKey !== product.productKey;
    return removePricingLines({
      ...item,
      quantity: isNotCurrent ? item.quantity : quantity,
    });
  });
};

const updateProduct = (cart, productKey, product) => {
  return cart.map((item) => {
    const isNotCurrent = item.productKey !== productKey;
    const useProduct = isNotCurrent ? item : product;
    return removePricingLines(useProduct);
  });
};

const removeInFavId = (list, id) => {
  return list.filter((l) => l.favoriteListId !== id);
};

const orderItems = handleActions(
  {
    [actionTypes.ADD_PRODUCTS]: (state, { payload }) => {
      return payload;
    },
    [actionTypes.ADD_PRODUCT_PROCEED]: (state, { payload }) => {
      // order history don't have UoM so we need use default UoM id
      const selectedUnit = payload.selected ? payload.selected.salesUnitId : payload.salesUnitId;
      const productKey = `${payload.productId}-${selectedUnit}`;
      const isSameProduct = state.find((item) => item.productKey === productKey);

      let orderItems = [];
      if (isSameProduct) {
        const newQuantity = (isSameProduct.quantity += payload.quantity);
        orderItems = updateQuantity(state, payload, newQuantity);
      } else {
        orderItems = [...state, { ...payload, productKey: productKey }];
      }
      return orderItems;
    },
    [actionTypes.REMOVE_PRODUCT]: (state, { payload }) => state.filter((p) => p.productKey !== payload),
    [actionTypes.UPDATE_PRODUCT]: (state, { payload }) => {
      return updateProduct(state, payload.productKey, payload.product);
    },
    [actionTypes.UPDATE_QUANTITY]: (state, { payload }) => {
      return updateQuantity(state, payload.product, payload.quantity);
    },
    [actionTypes.GET_CART_SUCCESS]: (state, { payload }) => {
      const result = JSON.parse(JSON.stringify(payload));
      return Array.isArray(result) ? result : []; // don't store invalid responses
    },
    [actionTypes.SIMULATE_ORDER_SUCCESS]: (state, { payload }) => {

      return state.map((item) => {
        const payloadItem = payload.items.find((i) => i.productId === item.productId && i.unitOfMeasureId === item.selected.salesUnitId);
        return {
          ...item,
          messages: payloadItem.messages,
          pricingLines: payloadItem.pricingLines,
          netAmount: payloadItem.netAmount,
          salesUnit: payloadItem.unitOfMeasureShortDescription || item.salesUnit,
          salesUnitPluralDescription: payloadItem.unitOfMeasurePluralDescription || item.salesUnitPluralDescription,
        };
      });
    },
    [actionTypes.REMOVE_ALL_PRODUCTS]: () => [],
    [ADD_TO_FAVORITES_SUCCESS]: (state, { payload }) => {
      return state.map((product) => {
        if (product.productId === payload.productId) {
          return {
            ...product,
            isFavorite: payload.isFavorite,
          };
        }
        return product;
      });
    },
    [actionTypes.CART_UPDATE_PRODUCT_FAV]: (state, { payload }) => {
      return state.map((product) => {
        if (product.productId === payload.productId) {
          return { ...product, ...payload };
        } else {
          return product;
        }
      });
    },
    [DELETE_FAVORITE_LIST_SUCCESS]: (state, { payload }) => {
      const { favoriteListId } = payload;

      return state.map((product) => {
        const inFavoriteList = removeInFavId(product.inFavoriteList, favoriteListId);
        return {
          ...product,
          inFavoriteList: inFavoriteList,
          isFavorite: inFavoriteList.length ? '1' : '0',
        };
      });
    },
  },
  [],
);

const invoice = handleActions(
  {
    [actionTypes.SIMULATE_ORDER_REQUEST]: (state, { payload }) => ({}),
    [actionTypes.SIMULATE_ORDER_SUCCESS]: (state, { payload }) => payload,
    [actionTypes.CREATE_ORDER_SUCCESS]: (state, { payload }) => ({}),
    [actionTypes.REMOVE_ALL_PRODUCTS]: () => ({}),
  },
  {},
);

const billingAddresses = handleActions(
  {
    [actionTypes.GET_BILLING_ADDRESSES_SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

const shippingAddresses = handleActions(
  {
    [actionTypes.GET_SHIPPING_ADDRESSES_SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

const paymentMethods = handleActions(
  {
    [actionTypes.GET_PAYMENT_METHODS_SUCCESS]: (state, { payload }) => payload
  },
  {}
);

const error = handleActions(
  {
    [actionTypes.SIMULATE_ORDER_REQUEST]: () => [],
    [actionTypes.SIMULATE_ORDER_FAILED]: (state, { payload }) => payload,
  },
  [],
);

const checkoutError = handleActions(
  {
    [actionTypes.CREATE_ORDER_FAILED]: (state, { payload }) => payload,
    [actionTypes.SIMULATE_ORDER_SUCCESS]: () => [],
    [actionTypes.CREATE_ORDER_SUCCESS]: () => [],
  },
  [],
);

export default combineReducers({
  loading,
  loadingCreateOrder,
  orderItems,
  invoice,
  billingAddresses,
  shippingAddresses,
  paymentMethods,
  error,
  checkoutError,
});

import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';

const type = handleActions(
  {
    [actionTypes.SET_BREADCRUMB_TYPE]: (state, { payload }) => payload,
  },
  null,
);

const path = handleActions(
  {
    [actionTypes.ADD_BREADCRUMB_PATH]: (state, { payload }) => {
      if (!state.find(item => item.name === payload.name)) {
        if (payload.isProduct) {
          return [payload];
        } else {
          return [...state, payload];
        }
      } else {
        return state;
      }
    },
    [actionTypes.REMOVE_BREADCRUMB_PATHS]: () => [],
    [actionTypes.SET_BREADCRUMB_TYPE]: () => [],
  },
  [],
);

export default combineReducers({
  type,
  path,
});

import { all, fork, takeLatest, put, select, call } from 'redux-saga/effects';
import * as actionTypes from './constants';
import callApi from '../../utils/api';
import { getRouteType, getIsPublic } from './app-selectors';

import { getUserSettings } from '../../modules/user/containers/user-actions';
import { getTranslationsAction } from '../../store/translations/translations-actions';
import { getBillingAddresses, getShippingAddresses, addProducts, getCartAction } from '../../modules/cart/containers/cart-actions';
import { getContentRequest, getConfigSuccess } from '../../modules/settings/containers/settings-actions';
import { initializeAppRequest, setRouteType, initializeAppSuccess, initializeAppFailed, initPublicLanguage } from './app-actions';
import { getCategoriesRequest } from '../../modules/category/containers/category-actions';
import { getFavoritesList } from '../../modules/favorites/containers/favorites-actions';

/**
 *  Scenarios:
 *  /public.html and not authenticated => /public
 *  /public.html and authenticated => /public
 *  /index.html and not authenticated => /private =>  redirect to /public.html
 *  /index.html and authenticated => /private
 */

// Always receive the config data on the startup of the app.
function* initializeApp() {
  const routeType = yield select(getRouteType);
  const isPublic = yield select(getIsPublic);
  const activePath = window.location.pathname;

  try {
    yield put(initializeAppRequest());
    const data = yield call(callApi, {
      //fastest api to call
      endpoint: `/object/json?path=files/companyInfo.json&languageDependent=false`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
      }
    });

    if (isPublic) {
      const language = localStorage.getItem('language');
      if (language) {
        yield put(initPublicLanguage(language));
      }
    } else if (activePath === '/public.html') {
      window.location.href = 'index.html';
    }

    // if success continue
    yield put(getConfigSuccess(data));
    yield put(getTranslationsAction());
    yield put(getContentRequest());
    yield put(getCartAction());
    yield put(getUserSettings());
    if (!isPublic) { yield put(getFavoritesList()); }
    yield put(initializeAppSuccess());
    yield put(getCategoriesRequest());

    // don't call on public.html
    if (!isPublic) {
      yield put(getBillingAddresses()); // needed for user settings
      yield put(getShippingAddresses()); // needed for user settings

      try {
        const cart = localStorage.getItem('cartItems');
        if (cart) {
          const serializedState = JSON.parse(cart);
          yield put(addProducts(serializedState));
        }
      } catch (err) {
        // do nothing if localStorage is off
      }
    }
  } catch (e) {
    // use public continue
    if (e.status === 'USE_PUBLIC' && !isPublic) {
      if (activePath === '/index.html') {
        window.location.replace('public.html');
      }

      yield put(setRouteType('public'));
      yield call(initializeApp);
    } else {
      yield put(initializeAppFailed(e));
    }
  }
}

function setLanguage({ payload }) {
  localStorage.setItem('language', payload);
}


export function* mySagaGlow() {
  yield takeLatest(actionTypes.INITIALIZE_APP, initializeApp);
}

function* watchPublicLanguage() {
  yield takeLatest([actionTypes.SET_PUBLIC_LANGUAGE], setLanguage);
}

export default function* appFlow() {
  yield all([
    fork(mySagaGlow),
    fork(watchPublicLanguage)
  ]);
}

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import callApi from '../../../utils/api';
import * as actionTypes from './constants';
import {
  getUserSettingsCache,
  getUserSettingsFailure,
  getUserSettingsRequest,
  getUserSettingsSuccess,
  setUserSettingsFailure,
  setUserSettingsRequest,
  setUserSettingsSuccess,
} from './user-actions';
import { getLanguage } from './user-selector';
import { getTranslationsSelector } from '../../../store/translations/translations-selectors';
import { getTranslationsAction } from '../../../store/translations/translations-actions';
import { getRouteType, getPublicLanguageParam } from '../../../app/containers/app-selectors';
import { getContentRequest } from '../../settings/containers/settings-actions';
import { setUnauthorized } from '../../../app/containers/app-actions';
import * as appActionTypes from '../../../app/containers/constants';

function* getUserSettings() {
  const routeType = yield select(getRouteType);
  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
  const cachedInLocalStorage = localStorage.getItem('user-settings');
  const cachedInSessionStorage = sessionStorage.getItem('user-settings');
  const param = yield select(getPublicLanguageParam);
  if (cachedInLocalStorage) {
    try {
      yield put(getUserSettingsCache(JSON.parse({ ...cachedInLocalStorage, ...(cachedInSessionStorage || {}) })));
    } catch (e) {
      localStorage.setItem('user-settings', undefined);
      sessionStorage.setItem('user-settings', undefined);
    }
  }

  try {
    yield put(getUserSettingsRequest());
    const appendParam = param && `?${param.substr(1)}`;
    const data = yield call(callApi, {
      endpoint: `user/settings${appendParam}`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    if (data) {
      const { userId, firstName, lastName, emailAddress, ...nonSensitiveData } = data;
      localStorage.setItem('user-settings', JSON.stringify(nonSensitiveData));
      sessionStorage.setItem('user-settings', JSON.stringify({ userId, firstName, lastName, emailAddress }));
      yield put(getUserSettingsSuccess(data));
    } else {
      yield put(getUserSettingsFailure());
    }
  } catch (e) {
    yield put(getUserSettingsFailure(e));
  }
}

function* setUserSettings({ payload }) {
  const language = yield select(getLanguage);
  const translations = yield select(getTranslationsSelector);
  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
  try {
    yield put(setUserSettingsRequest());
    const data = yield call(callApi, {
      endpoint: `user/settings`,
      options: {
        root: '/portal/api/v1',
        method: 'POST',
        body: JSON.stringify({
          ...payload,
        }),
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    const { userId, firstName, lastName, emailAddress, ...nonSensitiveData } = data;
    localStorage.setItem('user-settings', JSON.stringify(nonSensitiveData));
    sessionStorage.setItem('user-settings', JSON.stringify({ userId, firstName, lastName, emailAddress }));
    if (language !== data.userLanguage.languageId) {
      yield put(getContentRequest());
      yield put(getTranslationsAction());
    }
    yield put(setUserSettingsSuccess(data));
    if (language !== data.userLanguage.languageId) {
      location.reload();
    }
  } catch (e) {
    console.error(e);
    if (e.status === 'USE_PUBLIC') {
      yield put(setUnauthorized());
    }
    yield put(setUserSettingsFailure(e.messages || translations['USER_SETTINGS.MESSAGE.LOAD_USER_SETTINGS_FAIL']));
  }
}

function* watchGetUserSettings() {
  yield takeLatest([actionTypes.GET_USER_SETTINGS, appActionTypes.SET_PUBLIC_LANGUAGE], getUserSettings);
}

function* watchSetUserSettings() {
  yield takeLatest([actionTypes.SET_USER_SETTINGS], setUserSettings);
}

export default function* userFlow() {
  yield all([fork(watchGetUserSettings)]);
  yield all([fork(watchSetUserSettings)]);
}

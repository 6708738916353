import Prismic from 'prismic-javascript';
import { useSelector } from 'react-redux';
import { getPrismicPlugin } from '../app/containers/app-selectors';
import { useState } from 'react';

export default function usePrismicClient() {
  const [client, setClient] = useState();
  const prismicPlugin = useSelector(getPrismicPlugin);
  if (prismicPlugin && !client) {
    setClient(Prismic.client(prismicPlugin.apiEndpoint, { accessToken: prismicPlugin.accessToken || '' }));
  }
  return client;
}

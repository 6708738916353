import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

const StyledLink = styled(ReactLink)`
  font-weight: 600;
  color: ${props => props.theme.icon.link};
  font-size: 15px;
  text-transform: uppercase;
  border-bottom: 2px solid ${props => props.theme.icon.link};
  display: inline-block;
  &:hover {
    color: ${props => props.theme.icon.link};
    filter: brightness(85%);
  }
  & + & {
    margin-left: 24px;
    @media all and (max-width: 480px) {
      margin-left: 0;
      margin-top: 24px;
    }
  }
`;

const Link = ({ to, children, ...props }) => (
  <StyledLink to={to} {...props}>
    {children}
  </StyledLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Link;

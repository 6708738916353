import React from 'react';
import Div from 'ui/div';
import { useSelector } from 'react-redux';
import { getCartItems } from '../../cart/containers/cart-selector';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import { FormattedMessage } from 'react-intl';

const ProductInCart = ({ productId, size }) => {
  const cartItems = useSelector(getCartItems);
  const canAccessCart = useFeature(Modules.Webshop, Features.Webshop.canAddToCart);
  const productInCart = productId && cartItems.find((item) => item.productId === productId);

  if (!productInCart || !canAccessCart) {
    return null;
  }

  const fontSize = size === 'sm' ? '16px' : '22px';

  return (
    <Div width="100%" height="100%" fdc aic jcc background="rgba(0,0,0, 0.5)">
      <Div color="#fff" fontWeight="400" fontSize={fontSize} tac>
        <FormattedMessage id="GENERAL.LABELS.ALREADY_IN_YOUR_CART" />
      </Div>
    </Div>
  );
};

export default ProductInCart;

import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu, Icon, Button } from 'antd';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import CurrencyFormat from '../currency-format';
import Div from 'ui/div';
import { useMediaQuery } from 'react-responsive';

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${(props) => (props.active ? props.theme.body.title : props.theme.body.subtitle)};

  > div {
    flex-basis: calc(100% / 3);
  }
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  height: 38px;
  width: 100%;

  justify-content: space-between;
  display: flex;
  line-height: 100%;
  align-items: center;
`;

const ConversionList = ({ handleMenuClick, conversions, unit, salesUnitId }) => {
  const showDecimals = useFeature(Modules.Webshop, Features.Webshop.unitConversionsAllDecimals);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const hidePrice = useFeature(Modules.Webshop, Features.Webshop.hidePriceOnPortal);

  const menu = (
    <Menu onClick={handleMenuClick}>
      {conversions
        .filter(({ orderAllowed }) => orderAllowed === '1')
        .map((conversion) => (
          <Menu.Item key={conversion.unitOfMeasureId}>
            <MenuItem active={salesUnitId === conversion.unitOfMeasureId}>
              <div>
                {conversion.quantityInUnitOfMeasure} {conversion.unitOfMeasureShortDescription}
              </div>
              <div>
                ({conversion.quantityInBaseUnitOfMeasure} {conversion.baseUnitOfMeasureShortDescription})
              </div>
              {!hidePrice && (
                <Div noDefault tar>
                  <CurrencyFormat value={conversion.price} showAllDecimals={showDecimals} />
                </Div>
              )}
            </MenuItem>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Div noDefault flexGrow="1" marginLeft="10px">
      <Dropdown overlay={menu} placement="bottomLeft" trigger={[isTabletOrMobile ? 'click' : 'hover']}>
        <StyledButton>
          {unit} <Icon type="down" />
        </StyledButton>
      </Dropdown>
    </Div>
  );
};

export default ConversionList;

import React from 'react';
import Div from 'ui/div';
import Button from 'ui/button';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addProduct } from '../../cart/containers/cart-actions';

const CartContainer = styled(Div)`
  background: #fff;
  border-radius: 4px;
  opacity: ${props => (props.showButton ? 1 : 0)};
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const AddToCart = ({ showButton, product, quantity, block = true }) => {
  const dispatch = useDispatch();

  const onClick = e => {
    e.stopPropagation();
    dispatch(addProduct({ ...product, quantity }));
  };

  return (
    <CartContainer showButton={showButton}>
      <Button type="primary" block={block} onClick={onClick} ttu>
        <FormattedMessage id="PRODUCT.BUTTON.ADD_TO_CART" />
      </Button>
    </CartContainer>
  );
};

export default AddToCart;

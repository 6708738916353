import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Popover, Icon } from 'antd';
import Div from 'ui/div';
import PricingLine from './pricing-line';
import { useMediaQuery } from 'react-responsive';

const PricingLines = ({ pricingLines = [], popOver = false }) => {
  const theme = useContext(ThemeContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  if (!pricingLines.length) {
    return null;
  }
  const content = pricingLines.map(pricingLine =>
    <PricingLine key={pricingLine.pricingKey} pricingLine={pricingLine} />
  );

  if (popOver) {
    return (
      <Popover placement="topRight" trigger={isTabletOrMobile ? 'click' : 'hover'} arrowPointAtCenter content={<div>{content}</div>}>
        <Div color={theme.body.subtitle} cursor="pointer" marginLeft="10px" onClick={e => e.stopPropagation()}>
          <Icon type="info-circle" />
        </Div>
      </Popover>
    )
  }

  return <>{content}</>
}

export default PricingLines;

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ObjectStoreImage from 'ui/object-store-image';
import * as PropTypes from 'prop-types';

/**
 * Image Component
 *
 * Based on discussion, a fixed aspect ratio is given to images: 4x3
 * @param {String} image
 * @param {Number} [fontSize="12"] // xs, sm, md
 */

const ImageSize = styled.div`
  // set content to always have 4:3 aspect ratio
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 75%;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

const NoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #dddddd;
  font-weight: 700;
  font-size: ${props => `${props.fontSize}px`};
  text-align: center;
`;

const ImageComponent = ({ image, fontSize = 14, width = '100%', children }) => (
  <ImageSize width={width}>
    <div>
      {image && image !== 'No Thumbnail Found' ? (
        <ObjectStoreImage path={image} width="100%" />
      ) : (
        <NoImage fontSize={fontSize}>
          <FormattedMessage id="ERROR.NO_IMAGE_AVAILABLE" />
        </NoImage>
      )}
    </div>
    {children}
  </ImageSize>
);

ImageComponent.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
};

export default ImageComponent;

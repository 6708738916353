const format = value => {
  // if string but completely a number, then suffix with 'px'
  if (/^\d+$/.test(value)) {
    return `${value}px`;
  }
  if (typeof value === 'number') {
    return `${value}px`;
  }
  return value;
};
export default format;

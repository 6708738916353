import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import  isTouchDevice from 'is-touch-device';
import { getLevel1Categories, getShowCategory } from '../containers/category-selector';
import { Tabs } from 'antd';
import Div from 'ui/div';

const { TabPane } = Tabs;

const StyledNavigation = styled(Div)`
@media (max-width: 1024px) {
  padding: 0 20px;
}
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    height: 50px;
    margin: 0;
    border-bottom: 0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    height: 49px;
    line-height: 49px
    margin-right: 36px;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
    &:hover { font-weight: 300;  }
  }
  .ant-tabs-tab-prev, .ant-tabs-tab-next {
    color: ${props => props.theme.header.bottom.color};
    &:hover { color: inherit; }
  }

`;


const CategoryLevel1 = (props) => {
  const { onClickCategory, showCategory } = props;
  const theme = useContext(ThemeContext);
  const activeCategory = useSelector(getShowCategory);
  const categories = useSelector(getLevel1Categories);
  const active = activeCategory ? activeCategory.id : undefined;

  const onClick = (category) => {
    if (isTouchDevice()) {
      showCategory(category)
      if (!!activeCategory && activeCategory.id === category.id) {
        onClickCategory(category.id);
      }
    } else {
      onClickCategory(category.id);
    }
  }


  return (
    <StyledNavigation noDefault background={theme.header.bottom.background}>
      <Div noDefault fdr margin="0 auto" width="100%" maxWidth={theme.body.innerWidth}>
        <StyledTabs onChange={null} activeKey={active}>
          {categories.map((category) => (
            <TabPane key={category.id} tab={
              <Div onMouseOver={() => showCategory(category)} onClick={() => onClick(category)} noDefault color={theme.header.bottom.color} fontSize="13px">
                {category.description}
              </Div>
            }
            />
          ))}
        </StyledTabs>
      </Div>
    </StyledNavigation>

  )
};

export default CategoryLevel1;

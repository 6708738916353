import { createAction } from 'redux-actions';
import {
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_CACHE,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAILURE,
  RESET_USER_SETTINGS_STATE,
  SET_USER_SETTINGS,
  SET_USER_SETTINGS_REQUEST,
  SET_USER_SETTINGS_SUCCESS,
  SET_USER_SETTINGS_FAILURE,
} from './constants';

export const getUserSettings = createAction(GET_USER_SETTINGS);
export const getUserSettingsCache = createAction(GET_USER_SETTINGS_CACHE);
export const getUserSettingsRequest = createAction(GET_USER_SETTINGS_REQUEST);
export const getUserSettingsSuccess = createAction(GET_USER_SETTINGS_SUCCESS);
export const getUserSettingsFailure = createAction(GET_USER_SETTINGS_FAILURE);

export const setUserSettings = createAction(SET_USER_SETTINGS);
export const setUserSettingsRequest = createAction(SET_USER_SETTINGS_REQUEST);
export const setUserSettingsSuccess = createAction(SET_USER_SETTINGS_SUCCESS);
export const setUserSettingsFailure = createAction(SET_USER_SETTINGS_FAILURE);

export const resetUserSettings = createAction(RESET_USER_SETTINGS_STATE);

import { createSelector } from 'reselect';
import { getContent, isNil, parseTimestamp } from '../../../utils/utils';
import { getAuthorizations } from '../../user/containers/user-selector';
import * as _ from 'lodash';

const merge = require('deepmerge');

const getRowValue = (row, propertyNames, defaultValue) => {
  const correctPropertyName = propertyNames.find(name => !isNil(row[name]));
  if (correctPropertyName && !isNil(row[correctPropertyName])) {
    return row[correctPropertyName];
  }
  return defaultValue;
}

const notFoundLink = (contentId) => ({
  title: `Content '${contentId}'`,
  description: `Content not found in language dependent content.json.  Please add '${contentId}' array to this json file.`,
});

export const getWhyShopWithUsLinks = createSelector(
  [
    (state) => state.settings && state.settings.config && state.settings.config.data && state.settings.config.data,
    (state) => state.settings && state.settings.content && state.settings.content.data,
  ],
  (config, content) => {
    if (config && content) {
      const contentId = config.app && config.app['why-shop-links-id'];
      if (contentId) {
        return getContent(content, contentId, [notFoundLink(contentId)]).map((link) => {
          return {
            ...link,
            iconTheme: link.iconType === 'antd-filled' ? 'filled' : 'outlined',
            urlType: link['url-type'] || 'new-tab',
          };
        });
      }
    }
    return [];
  },
);

export const getSupportedLanguageCodes = createSelector(
  [(state) => state.settings && state.settings.config.data && state.settings.config.data.languages],
  (languages) => languages || ['en', 'nl', 'fr', 'de'],
);

export const getCurrentLanguageCode = (state) => state.app && state.app.publicLanguage;

export const getHomepageConfig = createSelector(
  [
    (state) => state.settings,
    (state) => state.settings && state.settings.config.data && state.settings.config.data.homepage,
    (state) => state.settings && state.settings.config.data && state.settings.config.data.cmpHomepage,
    (state) => state.settings && state.settings.content.data,
    (state) => state.settings && state.settings.theming && state.settings.theming.current && state.settings.theming.current.homepage,
    getAuthorizations,
  ],
  (settings, homepageConfig, cmpHomepageConfig, content, themedConfigHomepage, authorizations) => {

    // merge config-data with config from theme
    let config = null;
    // if (authorizations && authorizations.General) {
    if (true) {
      config = homepageConfig;
      if (authorizations && authorizations.General && authorizations.General.PortalCMPCustomer) {
        config = cmpHomepageConfig;
        if (!config) {
          console.error(`No cmpHomepage available in companyInfo.json !!!`);
          config = homepageConfig;
        }
      }
    }

    if (config) {
      if (content) {
        if (themedConfigHomepage) {
          // merge with settings from theme
          config = merge(config, themedConfigHomepage, {
            arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
          });
        }
        // for merging, rows must be an object and not an array.  But we need an array here...
        if (!Array.isArray(config.rows) && typeof config.rows === 'object') {
          config.rows = Object.values(config.rows);
        }
        // Debug
        console.log(`homepage config`, config);
        // filter out messages that have timestamps out of range
        const rows = config.rows.filter((row) => {
          const startTimestamp = parseTimestamp(row['start-timestamp']);
          const endTimestamp = parseTimestamp(row['end-timestamp']);
          const currentTimestamp = new Date().getTime();
          let valid = true;
          valid &= !startTimestamp || currentTimestamp >= startTimestamp;
          valid &= !endTimestamp || currentTimestamp <= endTimestamp;
          return valid;
        });
        const response = {
          reduced: true,
          rows: rows.map((originalRow) => {
            // reducing common properties
            const row = {
              ...originalRow,
              paddingTop: originalRow['padding-top'] || originalRow['padding'] || 0,
              paddingBottom: originalRow['padding-bottom'] || originalRow['padding'] || 0,
              spaceBetween: getRowValue(originalRow, ['space_between', 'space-between'], 10),
              maxChildren: getRowValue(originalRow, ['max_children', 'max-children'], 1),
              carousel: getRowValue(originalRow, ['carousel'], true),
              carouselDots: getRowValue(originalRow, ['carousel_dots', 'carousel-dots'],  false),
              carouselArrows: getRowValue(originalRow, ['carousel_arrows', 'carousel-arrows'],  true),
              carouselAutoplay: getRowValue(originalRow, ['carousel_autoplay', 'carousel-autoplay'],  false),
              carouselOutside: getRowValue(originalRow, ['carousel_outside', 'carousel-outside'],  false),
              carouselColor: getRowValue(originalRow, ['carousel_color', 'carousel-color'], 'black'),
              carouselEffect: getRowValue(originalRow, ['carousel_effect', 'carousel-effect'], 'scrollx'),
              color: getRowValue(originalRow, ['text_color', 'color', 'text-color'], null),
              backgroundColor: getRowValue(originalRow, ['background_color', 'background-color'], null),
              strokeWidth:  getRowValue(originalRow, ['stroke_width', 'stroke-width'], null),
            };

            let type = row.type;
            if (type === 'tile') {
              type = 'tiles';
            }
            switch (type) {
              case 'tiles':
              case 'cmp':
                if (!row.tiles) {
                  if (row.items) {
                    row.tiles = row.items; // TODO hack for prismic
                  } else {
                    throw new Error('Homepage config: tile has no tiles array');
                  }
                }
                if (!row.tiles.length) {
                  throw new Error('Homepage config: tile has empty tiles array');
                }
                return {
                  ...row,
                  type,
                  height: row.height || 100,
                  tiles:
                    row.tiles &&
                    row.tiles.map((tile) => {
                      let opacity = tile['image-opacity'];
                      if (opacity === undefined || opacity === null) {
                        opacity = row['image-opacity'];
                      }
                      if (opacity === undefined || opacity === null) {
                        opacity = 1;
                      }
                      return {
                        ...tile,
                        color: tile.color || row.color,
                        imageOpacity: opacity,
                        textBackgroundColor: tile['text-background-color'] || row['text-background-color'],
                        preTitle: getContent(content, tile['pre-title-id'], undefined, true),
                        title: getContent(content, tile['title-id'], undefined, true),
                        postTitle: getContent(content, tile['post-title-id'], undefined, true),
                        button: getContent(content, tile['button-id'], undefined, true),
                        imageId: getContent(content, tile['image-id'], tile['image-id']),
                        url: getContent(content, tile['url'], tile['url']),
                        urlType: getRowValue(tile, ['url_type', 'url-type'], getRowValue(row, ['url_type', 'url-type'], 'new-tab')),
                      };
                    }),
                };
              case 'links':
                const linksContent = getContent(content, row['content-id'], [notFoundLink(row['content-id'])]);
                if (linksContent) {
                  return {
                    ...row,
                    content: linksContent.map(link => {
                      return {
                        ...link,
                        title: _.isArray(link.title) ? (link.title.length > 0 ? link.title[0]: ''): link.title,
                        description: _.isArray(link.description) ? (link.description.length > 0 ? link.description[0].text: ''): link.description,
                        iconId: getRowValue(link, ['iconId', 'icon_id']),
                        iconType: getRowValue(link, ['icon_type']),
                        iconImage: getRowValue(link, ['icon_image', 'image']),
                        iconTheme: link.iconType === 'antd-filled' ? 'filled' : 'outlined',
                        urlType: link['url-type'] || row['url-type'] || 'new-tab',
                      };
                    }),
                  };
                }
              case 'text':
                return {
                  ...row,
                  content: getContent(content, row['content-id']) || row['default-value'],
                };
              case 'line':
                return {
                  ...row,
                  width: row.width || '100%',
                  rounded: row.rounded || false,
                };
              case 'message':
                if (row.lifespan !== 'always' && !row.id) {
                  throw new Error('Homescreen config: Message must have an ID.  Message: "' + (row['content-id'] || row['default-value']) + '"');
                }
                return {
                  ...row,
                  type: (row['content-type'] || 'dialog') + '-message',
                  content: getContent(content, row['content-id'], row['default-value']),
                  closeable: typeof row.closeable === 'boolean' ? row.closeable : true,
                  lifespan: (row.lifespan && row.lifespan.toLowerCase()) || 'always',
                };
              case 'products':
                return {
                  ...row,
                  productsUrl:  getRowValue(originalRow, ['products-url', 'api_endpoint'], null),

                }
              default:
                return row;
            }
          }),
        };

        const reduceMessages = (type) => {
          const messages = response.rows
            .filter((row) => row.type === type)
            .reduce(
              (resp, row) => {
                return {
                  ...resp,
                  paddingTop: Math.max(resp.paddingTop, row.paddingTop),
                  paddingBottom: Math.max(resp.paddingBottom, row.paddingBottom),
                  autoplay: resp.autoplay && (typeof row.autoplay !== 'boolean' || row.autoplay),
                  messages: [...resp.messages, row],
                };
              },
              {
                autoplay: true,
                type: type + 's',
                paddingTop: 0,
                paddingBottom: 0,
                messages: [],
              },
            );
          if (messages.messages.length) {
            response.rows.push(messages);
          }
        };

        reduceMessages('dialog-message');
        reduceMessages('banner-message');

        return response;
      } else {
        return config;
      }
    } else {
      return {
        rows: [],
      };
    }
  },
);

import styled from 'styled-components';
import {
  backgroundProps,
  borderProps,
  extraProps,
  flexProps,
  fixedProps,
  absoluteProps,
  positionProps,
  displayProps,
  textTransform,
  fontProps,
  heightProps,
  mainProps,
  marginProps,
  mobileProps,
  paddingProps,
  scrollProps,
  shadowProps,
  textAlign,
  widthProps,
  textDecoration,
  relativeProps,
} from './general/styling';

export default styled.div`
  ${mainProps};
  ${widthProps};
  ${heightProps};
  ${textAlign};
  ${textDecoration};
  ${textTransform};
  ${marginProps};
  ${paddingProps};
  ${flexProps};
  ${fixedProps};
  ${absoluteProps};
  ${positionProps};
  ${displayProps};
  ${backgroundProps};
  ${fontProps};
  ${borderProps};
  ${scrollProps};
  ${mobileProps};
  ${extraProps};
  ${shadowProps};
  ${relativeProps};
`;

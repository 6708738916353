import { createAction } from 'redux-actions';
import {
  INITIALIZE_APP,
  INITIALIZE_APP_REQUEST,
  SET_ROUTE_TYPE,
  INITIALIZE_APP_SUCCESS,
  INITIALIZE_APP_FAILED,
  SET_PUBLIC_LANGUAGE,
  INIT_PUBLIC_LANGUAGE,
  SET_UNAUTHORIZE,
  SET_PRISMIC_GLOBAL_CONFIG_LOADING,
  SET_PRISMIC_GLOBAL_CONFIG,
  SET_DISABLE_UI
} from './constants';

export const initializeApp = createAction(INITIALIZE_APP);
export const initializeAppRequest = createAction(INITIALIZE_APP_REQUEST);
export const initializeAppSuccess = createAction(INITIALIZE_APP_SUCCESS);
export const initializeAppFailed = createAction(INITIALIZE_APP_FAILED);
export const setRouteType = createAction(SET_ROUTE_TYPE);
export const initPublicLanguage = createAction(INIT_PUBLIC_LANGUAGE);
export const setPublicLanguage = createAction(SET_PUBLIC_LANGUAGE);
export const setUnauthorized = createAction(SET_UNAUTHORIZE);
export const setPrismicGlobalConfigLoading = createAction(SET_PRISMIC_GLOBAL_CONFIG_LOADING);
export const setPrismicGlobalConfig = createAction(SET_PRISMIC_GLOBAL_CONFIG);
export const setDisableUI = createAction(SET_DISABLE_UI);

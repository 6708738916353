import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageComponent from '../image';
import AddToCart from './add-to-cart';
import { FormattedMessage } from 'react-intl';
import useDebounce from '../../../hooks/useDebounce';
import { withRouter } from 'react-router-dom';
import Price from './price';
import Div from 'ui/div';
import Button from 'ui/button';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import ProductInCart from './product-in-cart';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../../ui/icon';
import UnitSelector from '../unit-selector';
import AddToFavorites from '../add-to-favorites/components/add-to-favorites';
import { useSelector } from 'react-redux';
import { getIsPublic } from '../../../app/containers/app-selectors';

const ListContainer = styled.div`
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  min-height: 130px;
  box-shadow: ${(props) => (props.border ? '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)' : '0')};
  &:hover {
    box-shadow: ${(props) => (props.border ? '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)' : '0')};
  }
  @media all and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

const ImageContainer = styled.div`
  width: 180px;
  position: relative;
  flex-shrink: 0;
  padding: ${(props) => props.padding};
  @media all and (max-width: 480px) {
    width: 80px;
  }
`;

const ContentContainer = styled.div`
  flex: 2 auto;
  padding: 20px;
  @media all and (max-width: 480px) {
    padding-right: 0;
    max-width: calc(100% - 80px);
  }
`;

const PriceContainer = styled.div`
  width: 280px;
  padding: 0 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media all and (max-width: 480px) {
    flex-basis: 100%;
    padding: 0;
  }
`;

const ProductName = styled.div`
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #34495e;
  font-weight: 700;
  word-break: break-word;
  @media all and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
  }
`;

const Description = styled.div`
  padding-top: 13px;
  font-size: 0.88rem;
  font-weight: 300;
  line-height: 22px;
  flex: auto;
  @media all and (max-width: 480px) {
    display: none;
  }
`;

export const StyledLink = styled.a`
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;
  color: ${(props) => props.theme.icon.link};
`;

const RemoveContainer = styled.div`
  width: 100%;
  text-align: right;
  padding: 5px 0;
  @media all and (max-width: 480px) {
    width: 20px;
    padding: 0;
    margin-top: -10px;
    margin-left: auto;
  }
`;

const Promo = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 8px;
  font-size: 14px;
  z-index: 1;
  font-weight: 600;
  background: ${(props) => props.theme.tag.promo.background};
  color: ${(props) => props.theme.tag.promo.color};
  @media all and (max-width: 480px) {
    font-size: 8px;
    padding: 0 4px;
  }
`;

const ProductList = (props) => {
  const { product, quantityChanged } = props;
  const { productThumbnailPath, name, productListerShortDescription, quantity: _quantity = 1, inPromotion, isFavorite, productId, selected } = product;
  const { price: unitPrice, salesUnit, salesUnitPluralDescription, pricingLines: _pricingLines, netAmount: _netAmount } = product;

  // optional props
  const { border, removeProduct, enableHover = true, updateQuantity, imgPadding = 0, useTotalPrice = false } = props;
  const [quantity, setQuantity] = useState(_quantity);
  const [netAmount, setNetAmount] = useState(_netAmount);
  const [pricingLines, setPricingLines] = useState(_pricingLines);
  const debounceInput = useDebounce(quantity, 500);
  const showPrice = useFeature(Modules.Webshop, Features.Webshop.showPrice);
  const canAddToCart = useFeature(Modules.Webshop, Features.Webshop.canAddToCart);
  const selectedUnitPrice = selected ? selected.price : unitPrice;
  const price = useTotalPrice ? selectedUnitPrice * quantity : selectedUnitPrice;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const hidePrice = useFeature(Modules.Webshop, Features.Webshop.hidePriceOnPortal);
  const isPublic = useSelector(getIsPublic);

  useEffect(() => {
    if (_quantity !== quantity) {
      setQuantity(_quantity);
    }
  }, [_quantity]);

  useEffect(() => {
    if (netAmount !== _netAmount) {
      setNetAmount(_netAmount);
    }
  }, [_netAmount])

  useEffect(() => {
    if (pricingLines !== _pricingLines) {
      setPricingLines(_pricingLines);
    }
  }, [_pricingLines])

  useEffect(() => {
    if (debounceInput && updateQuantity) {
      const data = { product: product, quantity: debounceInput };
      updateQuantity(data);
    }
  }, [debounceInput]);

  /**
   * When quantity is set, remove netAmount & pricingLines (remove strikethrough price and price popup)
   */
  const setUnitAndQuantity = (unitAndQuantity) => {
    const {quantity, selectedUnit} = unitAndQuantity[0];
    // send change to parent
    quantityChanged && quantityChanged(quantity, selectedUnit);
    // save quantity in state
    setQuantity(quantity);
    setNetAmount(null);
    setPricingLines([]);
  }

  const remove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (removeProduct) {
      removeProduct(product.productKey);
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.history.push(`/product/${encodeURIComponent(product.productId)}`);
  };

  return (
    <ListContainer border={border} onClick={onClick}>
      <ImageContainer padding={imgPadding}>
        <ImageComponent image={productThumbnailPath} fontSize={isTabletOrMobile ? 8 : 14}>
          {inPromotion === '1' && (
            <Promo>
              <FormattedMessage id="PRODUCT.TAG.PROMO"/>
            </Promo>
          )}

          {enableHover && <ProductInCart productId={product.productId} unit={salesUnit} unitPlural={salesUnitPluralDescription} size="sm"/>}
        </ImageComponent>
        {!isPublic && <Div pat="0" par="0" padding={imgPadding ? '20px 8px' : '2px 8px'} fontSize="24px" zIndex={1}>
          <AddToFavorites product={product} productId={productId} isInFavorites={isFavorite}/>
        </Div>}
      </ImageContainer>
      <ContentContainer>
        <ProductName>
          <span data-testid="productTitle">{name}</span>
          {!isTabletOrMobile && (
            <Div fontSize={10} fontWeight={400} lineHeight="0.4rem" data-testid="productId">
              {product.productId}
            </Div>
          )}
          {isTabletOrMobile && removeProduct && (
            <RemoveContainer>
              <StyledLink href="" onClick={remove}>
                <Icon type="delete"/>
              </StyledLink>
            </RemoveContainer>
          )}
        </ProductName>
        <Description>{productListerShortDescription}</Description>
        {isTabletOrMobile && !hidePrice && <Price price={price} netAmount={netAmount} alignRight pricingLines={pricingLines}/>}
      </ContentContainer>
      <PriceContainer>
        {!isTabletOrMobile && removeProduct && (
          <RemoveContainer>
            <StyledLink href="" onClick={remove} data-testid="removeItem">
              <FormattedMessage id="CART.LABEL.REMOVE"/>
            </StyledLink>
          </RemoveContainer>
        )}
        {showPrice ? (
          <>
            <Div padding="5px 0">
              {!isTabletOrMobile && props.noButton && !hidePrice && <Price price={price} netAmount={netAmount} alignRight pricingLines={pricingLines}/>}
              <UnitSelector product={product} quantity={quantity} noButton={props.noButton} customOnChange={setUnitAndQuantity}/>
            </Div>
          </>
        ) : (
          <Button block type="primary">
            <FormattedMessage id="PRODUCT.BUTTON.MORE_INFO"/>
          </Button>
        )}
      </PriceContainer>
    </ListContainer>
  );
};

export default withRouter(ProductList);

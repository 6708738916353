import React, { useState } from 'react';
export const ImageContext = React.createContext(null);

const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);

  const storeImage = (url, data) => {
    setImages(prev => [...prev, { id: url, value: data }]);
  };

  const getImage = img => {
    const hasImage = images.find(image => image.id === img);
    if (hasImage) {
      return hasImage.value;
    }
    return null;
  };

  return <ImageContext.Provider value={{ getImage, storeImage }}>{children}</ImageContext.Provider>;
};

export default ImageProvider;

import React from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { getNumberFormat } from '../user/containers/user-selector';

const StyledNumberFormat = styled(NumberFormat)`
white-space: nowrap;
padding-left: 5px;
`;
const CurrencyFormat = ({ value, symbol, hideSymbol = false, showAllDecimals, ...props }) => {

  const numberFormat = useSelector(getNumberFormat);

  let symbolString = '';
  // if there is a symbol and !hideSymbol
  if (!hideSymbol && (symbol || numberFormat && numberFormat.currencySymbol)) {
    symbolString = symbol || numberFormat.currencySymbol;
    // end with a space
    if (symbolString.substr(symbolString.length - 1) !== ' ') {
      symbolString += ' ';
    }
  }
  return <StyledNumberFormat
    value={value || 0}
    defaultValue={0}
    displayType='text'
    thousandSeparator={numberFormat.thousands}
    decimalSeparator={numberFormat.decimal}
    fixedDecimalScale={true}
    decimalScale={showAllDecimals ? undefined : numberFormat.numberOfDecimals}
    prefix={symbolString}
    {...props}
  />;
}

export default CurrencyFormat;

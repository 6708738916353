export function getColor(color, customColor, theme) {
  switch (color) {
    case 'Default':
      return null;
    case 'Custom':
      return customColor;
    case 'Primary':
      return theme.button.primary.background;
    case 'Secondary':
      return theme.button.secondary.background;
  }
  return null;
}

export const openPrismicLink = ({ history, urlType = 'new-tab', link }) => {
  let url = link.url;
  if (link.type) {
    url = prismicLinkResolver(link);
  }
  if (url) {
    if (url.indexOf('https://#') === 0) {
      url = url.substring('https://#'.length - 1);
    }
    switch (urlType) {
      case 'new-tab':
        window.open(url);
        break;
      case 'same-tab':
        location.href = url;
        break;
      case 'portal':
        history.push(url);
        break;
    }
  }
};

export const buildPrismicParams = (query, language) => {
  let params = {};
  query && (params['graphQuery'] = query);
  language && (params['lang'] = language);

  return params;
};

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const prismicLinkResolver = (doc) => {
  if (doc.type === 'grid') {
    return `#/content/${doc.uid}`
  }
  return '/'
}

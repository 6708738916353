import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as actionTypes from './constants';
import * as favActionTypes from '../../../favorites/containers/constants';

const loading = handleActions(
  {
    [actionTypes.FAV_SEARCH_IN_MODAL_REQUEST]: () => true,
    [combineActions(actionTypes.FAV_SEARCH_IN_MODAL_SUCCESS, actionTypes.FAV_SEARCH_IN_MODAL_FAILED)]: () => false,
  },
  false,
);

const atflists = handleActions(
  {
    [actionTypes.FAV_SEARCH_IN_MODAL]: () => ({ listPage: [] }),
    [actionTypes.FAV_SEARCH_IN_MODAL_SUCCESS]: (state, { payload }) => {
      return { listPage: [...state.listPage, ...payload.listPage] };
    },
    [actionTypes.FAV_OPEN_MODAL]: () => ({ listPage: [] }),
  },
  {
    listPage: [],
  },
);

const search = handleActions(
  {
    [actionTypes.FAV_SEARCH_IN_MODAL]: (state, { payload }) => payload.search,
    [actionTypes.FAV_OPEN_MODAL]: () => null,
  },
  null,
);

const modal = handleActions(
  {
    [actionTypes.FAV_OPEN_MODAL]: () => true,
    [actionTypes.FAV_CLOSE_MODAL]: () => false,
  },
  false,
);

const product = handleActions(
  {
    [actionTypes.FAV_OPEN_MODAL]: (state, { payload }) => payload,
    [actionTypes.FAV_CLOSE_MODAL]: (state, { payload }) => null,
  },
  null,
);

const total = handleActions(
  {
    [actionTypes.FAV_SEARCH_IN_MODAL]: () => 0,
    [actionTypes.FAV_SEARCH_IN_MODAL_SUCCESS]: (state, { payload }) => payload.listSize,
  },
  0,
);

const page = handleActions(
  {
    [actionTypes.FAV_SEARCH_IN_MODAL_SUCCESS]: (state, { payload }) => payload.page,
  },
  0,
);

export default combineReducers({
  loading,
  search,
  atflists,
  modal,
  product,
  total,
  page,
});

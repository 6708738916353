import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { heightProps, marginProps, widthProps, paddingProps, relativeProps, textTransform } from './general/styling'; // TODO this causes an 'unknown prop warning'

const antdButton = ({ marginLeft, marginRight, ttu, size, ...rest }) => {
  // override default
  const override = {
    height: size === 'large' ? '60px' : '40px',
    fontWeight: 600,
    letterSpacing: '1px',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    whiteSpace: 'normal'
  };

  return <Button {...rest} style={override} />;
};

export default styled(antdButton)`
  ${widthProps};
  ${heightProps};
  ${marginProps};
  ${paddingProps};
  ${relativeProps};
  ${textTransform};
`;

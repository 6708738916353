import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { getCategoriesFailure, getCategoriesSuccess } from "./category-actions";
import * as actionTypes from './constants';
import * as appActionTypes from '../../../app/containers/constants';
import callApi from '../../../utils/api';
import { getRouteType, getPublicLanguageParam } from '../../../app/containers/app-selectors';

// Always receive the config data on the startup of the app.
function* fetchCategories() {
  const routeType = yield select(getRouteType);
  const param = yield select(getPublicLanguageParam);
  const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';

  // http://localhost:8080/portal/api/v1/product/category
  try {
    let appendParam = param && `?${param.substr(1)}`;
    const data = yield call(callApi, {
      endpoint: `/product/category${appendParam}`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getCategoriesSuccess(data));
  } catch (e) {
    yield put(getCategoriesFailure(e.message));
  }
}

export function* mySagaGlow() {
  yield takeLatest([actionTypes.GET_CATEGORIES_REQUEST, appActionTypes.SET_PUBLIC_LANGUAGE], fetchCategories);
}

export default function* categoryFlow() {
  yield all([fork(mySagaGlow)]);
}

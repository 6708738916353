import { createAction } from 'redux-actions';
import {
  GET_FAVORITES,
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAILED,
  ADD_TO_FAVORITES,
  ADD_TO_FAVORITES_REQUEST,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILED,
  REMOVE_TO_FAVORITES,
  REMOVE_TO_FAVORITES_REQUEST,
  REMOVE_TO_FAVORITES_SUCCESS,
  REMOVE_TO_FAVORITES_FAILED,
  GET_FAVORITES_LIST,
  GET_FAVORITES_LIST_REQUEST,
  GET_FAVORITES_LIST_SUCCESS,
  GET_FAVORITES_LIST_FAILED,
  CREATE_FAVORITE_LIST,
  CREATE_FAVORITE_LIST_REQUEST,
  CREATE_FAVORITE_LIST_SUCCESS,
  CREATE_FAVORITE_LIST_FAILED,
  UPDATE_FAVORITE_LIST,
  UPDATE_FAVORITE_LIST_REQUEST,
  UPDATE_FAVORITE_LIST_SUCCESS,
  UPDATE_FAVORITE_LIST_FAILED,
  DELETE_FAVORITE_LIST,
  DELETE_FAVORITE_LIST_REQUEST,
  DELETE_FAVORITE_LIST_SUCCESS,
  DELETE_FAVORITE_LIST_FAILED,
  SELECT_FAVORITE_LIST,
  ADD_TO_MULTIPLE_FAVORITES,
  REMOVE_TO_MULTIPLE_FAVORITES,
  LOAD_MORE_FAVORITE_LIST,
} from './constants';

export const getFavorites = createAction(GET_FAVORITES);
export const getFavoritesRequest = createAction(GET_FAVORITES_REQUEST);
export const getFavoritesSuccess = createAction(GET_FAVORITES_SUCCESS);
export const getFavoritesFailed = createAction(GET_FAVORITES_FAILED);

export const createFavoriteList = createAction(CREATE_FAVORITE_LIST);
export const createFavoriteListRequest = createAction(CREATE_FAVORITE_LIST_REQUEST);
export const createFavoriteListSuccess = createAction(CREATE_FAVORITE_LIST_SUCCESS);
export const createFavoriteListFailed = createAction(CREATE_FAVORITE_LIST_FAILED);

export const updateFavoriteList = createAction(UPDATE_FAVORITE_LIST);
export const updateFavoriteListRequest = createAction(UPDATE_FAVORITE_LIST_REQUEST);
export const updateFavoriteListSuccess = createAction(UPDATE_FAVORITE_LIST_SUCCESS);
export const updateFavoriteListFailed = createAction(UPDATE_FAVORITE_LIST_FAILED);

export const deleteFavoriteList = createAction(DELETE_FAVORITE_LIST);
export const deleteFavoriteListRequest = createAction(DELETE_FAVORITE_LIST_REQUEST);
export const deleteFavoriteListSuccess = createAction(DELETE_FAVORITE_LIST_SUCCESS);
export const deleteFavoriteListFailed = createAction(DELETE_FAVORITE_LIST_FAILED);

export const getFavoritesList = createAction(GET_FAVORITES_LIST);
export const getFavoritesListRequest = createAction(GET_FAVORITES_LIST_REQUEST);
export const getFavoritesListSuccess = createAction(GET_FAVORITES_LIST_SUCCESS);
export const getFavoritesListFailed = createAction(GET_FAVORITES_LIST_FAILED);

export const addToFavorites = createAction(ADD_TO_FAVORITES);
export const addToFavoritesRequest = createAction(ADD_TO_FAVORITES_REQUEST);
export const addToFavoritesSuccess = createAction(ADD_TO_FAVORITES_SUCCESS);
export const addToFavoritesFailed = createAction(ADD_TO_FAVORITES_FAILED);

export const removeToFavorites = createAction(REMOVE_TO_FAVORITES);
export const removeToFavoritesRequest = createAction(REMOVE_TO_FAVORITES_REQUEST);
export const removeToFavoritesSuccess = createAction(REMOVE_TO_FAVORITES_SUCCESS);
export const removeToFavoritesFailed = createAction(REMOVE_TO_FAVORITES_FAILED);

export const selectFavoriteList = createAction(SELECT_FAVORITE_LIST);

export const addToMultipleFavorites = createAction(ADD_TO_MULTIPLE_FAVORITES);
export const removeToMultipleFavorites = createAction(REMOVE_TO_MULTIPLE_FAVORITES);

export const loadMoreFavoritesList = createAction(LOAD_MORE_FAVORITE_LIST);

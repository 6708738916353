import React from 'react';
import styled from 'styled-components'; // TODO this causes an 'unknown prop warning'
import { fontProps, marginProps, paddingProps, widthProps } from './general/styling';
import { Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen,
  faBriefcase,
  faChartBar,
  faCloud,
  faDesktop,
  faDoorOpen,
  faLightbulb,
  faListUl,
  faRocket,
  faTh,
  faUsers,
  faBars,
  faFilter,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSun, faPlusSquare, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

// TODO Sander is there a better way?

const faList = {
  grid: faTh,
  list: faListUl,
  'fa-desktop': faDesktop,
  'fa-briefcase': faBriefcase,
  'fa-box-open': faBoxOpen,
  'fa-door-open': faDoorOpen,
  'fa-cloud': faCloud,
  'fa-sun': faSun,
  'fa-lightbulb-o': faLightbulb,
  'fa-bar-chart': faChartBar,
  'fa-rocket': faRocket,
  'fa-users': faUsers,
  'fa-plus-square': faPlusSquare,
  'fa-pencil': faPencilAlt,
  'fa-trash': faTrashAlt,
  'fa-bars': faBars,
  'fa-filter': faFilter
};

library.add(faTh, faListUl, faDesktop, faLightbulb, faBriefcase, faChartBar, faRocket, faUsers, faBars, faFilter, faPlusSquare, faPencilAlt);

const faIcon = ({ icon, marginTop, ...rest }) => {
  let useIcon = faList[icon] || icon;
  return <FontAwesomeIcon {...rest} icon={useIcon}/>;
};

const styledFaIcon = styled(faIcon)`
  ${fontProps};
  ${marginProps};
`;
export { styledFaIcon as IconFA };

const antdIcon = ({ marginLeft, marginRight, paddingRight, ...rest }) => <Icon {...rest} />;
export default styled(antdIcon)`
  ${widthProps};
  ${marginProps};
  ${paddingProps};
  ${fontProps};
`;

import { all, call, delay, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './constants';
import {
  getFavoritesSuccess,
  getFavoritesFailed,
  getFavoritesListSuccess,
  getFavoritesListFailed,
  getFavoritesRequest,
  getFavoritesListRequest,
  addToFavoritesRequest,
  addToFavoritesSuccess,
  addToFavoritesFailed,
  createFavoriteListRequest,
  createFavoriteListSuccess,
  createFavoriteListFailed,
  updateFavoriteListRequest,
  updateFavoriteListFailed,
  updateFavoriteListSuccess,
  deleteFavoriteListRequest,
  deleteFavoriteListFailed,
  deleteFavoriteListSuccess,
} from './favorites-actions';
import callApi from '../../../utils/api';
import { getFavoritesDefaultList } from './favorites-selector';
import { message } from 'antd';

const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
const rootUrl = '/portal/api/v1';

function* getFavoritesList({ payload }) {
  try {
    yield put(getFavoritesListRequest());

    let endpoint = '/product/favorites/list?status=1';
    endpoint += payload ? `&page=${payload}` : '';
    const data = yield call(callApi, {
      endpoint,
      options: {
        root: rootUrl,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getFavoritesListSuccess(data));
  } catch (e) {
    yield put(getFavoritesListFailed(e.messageBody || []));
  }
}

function* createFavoriteList({ payload }) {
  try {
    yield put(createFavoriteListRequest());
    const items = [
      {
        operationId: 'Create',
        favoriteListName: payload ? payload.name : 'Default',
        isDefaultFavoriteList: payload ? '0' : '1',
      },
    ];
    const data = yield call(callApi, {
      endpoint: `/product/favorites/list`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(items),
      },
    });
    yield put(createFavoriteListSuccess(data));
    if (payload && payload.callback) {
      payload.callback();
    }
    yield call(getFavoritesList, {});
  } catch (e) {
    yield put(createFavoriteListFailed(e.messageBody || []));
  }
}

function* updateFavoriteList({ payload }) {
  try {
    yield put(updateFavoriteListRequest());
    const items = [
      {
        operationId: 'Update',
        ...payload.data,
      },
    ];
    const data = yield call(callApi, {
      endpoint: `/product/favorites/list`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(items),
      },
    });
    yield put(updateFavoriteListSuccess(data));
    payload.callback && payload.callback();
  } catch (e) {
    yield put(updateFavoriteListFailed(e.messageBody || []));
  }
}

function* deleteFavoriteList({ payload }) {
  try {
    yield put(deleteFavoriteListRequest());
    const items = [
      {
        operationId: 'Delete',
        ...payload.data,
      },
    ];
    yield call(callApi, {
      endpoint: `/product/favorites/list`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(items),
      },
    });
    yield put(deleteFavoriteListSuccess(payload.data));
    yield call(getFavoritesList, {});
    payload.callback && payload.callback();
  } catch (e) {
    yield put(deleteFavoriteListFailed(e.messageBody || []));
  }
}

function* getFavorites() {
  try {
    yield put(getFavoritesRequest());
    const data = yield call(callApi, {
      endpoint: `/product/favorites/${favoritesListId}`,
      options: {
        root: rootUrl,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getFavoritesSuccess(data));
  } catch (e) {
    yield put(getFavoritesFailed(e.messageBody || []));
  }
}

function* addToFavorites({ payload }) {
  try {
    const favoritesListId = yield select(getFavoritesDefaultList);
    const items = [
      {
        operationId: 'Create',
        productId: payload.productId,
      },
    ];
    yield put(addToFavoritesRequest());
    const data = yield call(callApi, {
      endpoint: `/product/favorites/${favoritesListId}`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(items),
      },
    });
    yield put(
      addToFavoritesSuccess({
        productId: payload.productId,
        isFavorite: '1',
      }),
    );
    payload.callback && payload.callback();
  } catch (e) {
    yield put(addToFavoritesFailed(e.messageBody));
    message.error(e.error || `Failed to add product to favorites`);
    payload.errCallback && payload.errCallback();
  }
}

function* addToMultipleFavorites({ payload }) {
  try {
    const items = [
      {
        operationId: 'Create',
        productId: payload.productId,
      },
    ];

    yield all(
      payload.list.map((listId) =>
        call(callApi, {
          endpoint: `/product/favorites/${listId}`,
          options: {
            root: rootUrl,
            method: 'POST',
            errorMessage: transNotAbleToGetConfigData,
            tokenNeeded: false,
            body: JSON.stringify(items),
          },
        }),
      ),
    );

    payload.callback && payload.callback();
  } catch (e) {
    // yield put(addToFavoritesFailed(e.messageBody));
    message.error(e.error || `Failed to add product to favorites`);
    payload.errCallback && payload.errCallback();
  }
}

function* removeToMultipleFavorites({ payload }) {
  try {
    // const favoritesListId = yield select(getFavoritesDefaultList);
    const items = [
      {
        operationId: 'Delete',
        productId: payload.productId,
      },
    ];

    yield all(
      payload.list.map((listId) =>
        call(callApi, {
          endpoint: `/product/favorites/${listId}`,
          options: {
            root: rootUrl,
            method: 'POST',
            errorMessage: transNotAbleToGetConfigData,
            tokenNeeded: false,
            body: JSON.stringify(items),
          },
        }),
      ),
    );

    payload.callback && payload.callback();
  } catch (e) {
    message.error(e.error || `Failed to remove product to favorites`);
    payload.errCallback && payload.errCallback();
  }
}

function* removeToFavorites({ payload }) {
  try {
    const favoritesListId = yield select(getFavoritesDefaultList);
    const items = [
      {
        operationId: 'Delete',
        productId: payload.productId,
      },
    ];
    yield put(addToFavoritesRequest());
    const data = yield call(callApi, {
      endpoint: `/product/favorites/${favoritesListId}`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(items),
      },
    });
    yield put(
      addToFavoritesSuccess({
        productId: payload.productId,
        isFavorite: '0',
      }),
    );
    payload.callback && payload.callback();
  } catch (e) {
    yield put(addToFavoritesFailed(e.messageBody));
    message.error(e.error || `Failed to remove product to favorites`);
    payload.errCallback && payload.errCallback();
  }
}

function* watchGetFavoritesList() {
  yield takeLatest([actionTypes.GET_FAVORITES_LIST, actionTypes.LOAD_MORE_FAVORITE_LIST], getFavoritesList);
}

function* watchGetFavorites() {
  yield takeLatest([actionTypes.GET_FAVORITES], getFavorites);
}

function* watchAddToFavorites() {
  yield takeEvery([actionTypes.ADD_TO_FAVORITES], addToFavorites);
}

function* watchRemoveToFavorites() {
  yield takeEvery([actionTypes.REMOVE_TO_FAVORITES], removeToFavorites);
}

function* watchCreateFavoriteList() {
  yield takeEvery([actionTypes.CREATE_FAVORITE_LIST], createFavoriteList);
}

function* watchUpdateFavoriteList() {
  yield takeEvery([actionTypes.UPDATE_FAVORITE_LIST], updateFavoriteList);
}

function* watchDeleteFavoriteList() {
  yield takeEvery([actionTypes.DELETE_FAVORITE_LIST], deleteFavoriteList);
}

function* watchAddToMultipleFavorites() {
  yield takeEvery([actionTypes.ADD_TO_MULTIPLE_FAVORITES], addToMultipleFavorites);
}

function* watchRemoveToMultipleFavorites() {
  yield takeEvery([actionTypes.REMOVE_TO_MULTIPLE_FAVORITES], removeToMultipleFavorites);
}

export default function* cartFlow() {
  yield all([
    fork(watchGetFavoritesList),
    fork(watchGetFavorites),
    fork(watchAddToFavorites),
    fork(watchRemoveToFavorites),
    fork(watchCreateFavoriteList),
    fork(watchUpdateFavoriteList),
    fork(watchDeleteFavoriteList),
    fork(watchAddToMultipleFavorites),
    fork(watchRemoveToMultipleFavorites),
  ]);
}

import { createSelector } from 'reselect';

export const getFavoritesLoading = createSelector([(state) => state.favorites], (favoritesState) => favoritesState.loading);

export const sortFavList = (a, b) => {
  if (parseInt(a.isDefaultFavoriteList) < parseInt(b.isDefaultFavoriteList)) {
    return 1;
  }
  if (parseInt(a.isDefaultFavoriteList) > parseInt(b.isDefaultFavoriteList)) {
    return -1;
  }
  return 0;
};

export const getFavoritesList = createSelector([(state) => state.favorites], (favoritesState) => {
  const { listPage = [] } = favoritesState.listFavorites;
  return listPage.sort(sortFavList);
});

export const getFavoritesDefaultList = createSelector([(state) => state.favorites], (favoritesState) => {
  const { listPage = [] } = favoritesState.listFavorites;
  const defaultList = listPage.find((l) => l.isDefaultFavoriteList === '1');
  if (!defaultList) return;
  return defaultList.favoriteListId;
});

export const createFavoritesLoading = createSelector([(state) => state.favorites], (favoritesState) => favoritesState.createLoading);

export const updateFavoritesLoading = createSelector([(state) => state.favorites], (favoritesState) => favoritesState.updateLoading);

export const getSelectedFavId = createSelector([(state) => state.favorites], (favoritesState) => {
  return favoritesState.selectedId;
});

export const getFavoritesListPagination = createSelector([(state) => state.favorites, getFavoritesList], (favoritesState, favLists) => {
  return {
    hasMore: favoritesState.total > favLists.length,
    isLoading: favoritesState.listLoading,
    page: favoritesState.page + 1,
    currentCount: favLists.length,
    total: favoritesState.total,
  };
});

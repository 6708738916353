import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './constants';
import * as _ from 'lodash';
import {
  addProductProceed,
  createOrderFailed,
  createOrderRequest,
  createOrderSuccess,
  getBillingAddressFailure,
  getBillingAddressRequest,
  getBillingAddressSuccess,
  getCartFailedAction,
  getCartRequestAction,
  getCartSuccessAction,
  getShippingAddressFailure,
  getShippingAddressRequest,
  getShippingAddressSuccess,
  setCartFailedAction,
  setCartRequestAction,
  setCartSuccessAction,
  simulateOrderFailed,
  simulateOrderRequest,
  simulateOrderSuccess, getPaymentMethodsRequest, getPaymentMethodsSuccess, getPaymentMethodsFailure,
} from './cart-actions';
import { getCartIsFullMessage, getCartItems, getCartOrderRequest } from './cart-selector';
import { message } from 'antd';
import { getTranslationsSelector } from '../../../store/translations/translations-selectors';
import { getLanguage } from '../../user/containers/user-selector';
import callApi from '../../../utils/api';
import { getRouteType } from '../../../app/containers/app-selectors';
import { setUnauthorized } from '../../../app/containers/app-actions';

import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill/index.js';
import 'abortcontroller-polyfill';

const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
const rootUrl = '/portal/api/v1';

function* getCart() {
  try {
    yield put(getCartRequestAction());
    const data = yield call(callApi, {
      endpoint: `/cart/my-cart`,
      options: {
        root: rootUrl,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getCartSuccessAction(data));
  } catch (e) {
    yield put(getCartFailedAction(e.messageBody || []));
  }
}

function* setCart() {
  yield delay(100); // only take latest simulate request (like debounce(100) in angular ;-) )
  const cartItems = yield select(getCartItems);
  try {
    yield put(setCartRequestAction());
    const data = yield call(callApi, {
      endpoint: `/cart/my-cart`,
      options: {
        root: rootUrl,
        method: 'POST',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
        body: JSON.stringify(cartItems),
      },
    });
    yield put(setCartSuccessAction(data));
  } catch (e) {
    if (e.status === 'USE_PUBLIC') {
      yield put(setUnauthorized());
    }
    yield put(setCartFailedAction(e.messageBody || []));
  }
}

let abortController;

let previousSimulation; // cache previous simulation

// Always receive the config data on the startup of the app.
function* simulateOrder() {
  yield delay(100); // only take latest simulate request (like debounce(100) in angular ;-) )
  const orderItems = yield select(getCartOrderRequest);
  const language = yield select(getLanguage);

  if (!orderItems.length) {
    return;
  }
  try {
    // Is previous request identical?  Then don't ask for new simulation, use old response
    const isPreviousSimulation = previousSimulation
      && _.isEqual(previousSimulation.request, orderItems)
      && language === previousSimulation.language;

    if (isPreviousSimulation) {
      yield put(simulateOrderSuccess(previousSimulation.response));
    } else {
      yield put(simulateOrderRequest());
      if (abortController) {
        abortController.abort(); // abort previous call
      }
      abortController = new AbortController(); // DOM api
      const data = yield call(callApi, {
        endpoint: `/order/simulate`,
        options: {
          signal: abortController.signal,
          root: rootUrl,
          method: 'POST',
          body: JSON.stringify({
            orderReference: '',
            orderDescription: '',
            orderItems,
          }),
          errorMessage: transNotAbleToGetConfigData,
          tokenNeeded: false,
        },
      });
      previousSimulation = { request: orderItems, response: data, language };
      yield put(simulateOrderSuccess(data));
    }
  } catch (e) {
    if (e.status === 'USE_PUBLIC') {
      yield put(setUnauthorized());
    }
    yield put(simulateOrderFailed(e.messages || []));
  }
}

function* getBillingAddresses({ payload }) {
  const routeType = yield select(getRouteType);
  try {
    yield put(getBillingAddressRequest());
    const data = yield call(callApi, {
      endpoint: `/business-partner/address/list?addressType=BillToAddress`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getBillingAddressSuccess(data));
  } catch (e) {
    yield put(getBillingAddressFailure(e.message));
  }
}

function* getShippingAddresses({ payload }) {
  const routeType = yield select(getRouteType);
  try {
    yield put(getShippingAddressRequest());
    const data = yield call(callApi, {
      endpoint: `/business-partner/address/list?addressType=ShipToAddress`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getShippingAddressSuccess(data));
  } catch (e) {
    yield put(getShippingAddressFailure(e.message));
  }
}

function* getPaymentMethods({ payload: { amount, currency } }) {
  const routeType = yield select(getRouteType);
  try {
    yield put(getPaymentMethodsRequest());
    const data = yield call(callApi, {
      endpoint: `/cart/payment/methods?currency=${currency}&amount=${amount}`,
      options: {
        root: `${routeType}portal/api/v1`,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(getPaymentMethodsSuccess(data));
  } catch (e) {
    yield put(getPaymentMethodsFailure(e.message));
  }
}

function* createOrder({ payload: { values, callback } }) {
  const orderItems = yield select(getCartOrderRequest);
  const translations = yield select(getTranslationsSelector);
  try {
    yield put(createOrderRequest());
    const data = yield call(callApi, {
      endpoint: `/order/create`,
      options: {
        root: rootUrl,
        method: 'POST',
        body: JSON.stringify({
          ...values,
          orderItems,
        }),
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });
    yield put(createOrderSuccess(data));
    if (callback) {
      callback(data);
    }
  } catch (e) {
    yield put(createOrderFailed(e.messages));
    message.config({ top: 150 });
    message.error(translations['ORDER.MESSAGE.ORDER_SUBMIT_FAIL']);
  }
}

function* addProduct({ payload }) {
  const isFullMessage = yield select(getCartIsFullMessage);
  if (!isFullMessage) {
    yield put(addProductProceed(payload));
  } else {
    message.config({ top: 150, maxCount: 1 });
    message.error(isFullMessage);
  }
}

function* watchGetSimulateOrder() {
  yield takeLatest(
    [
      actionTypes.SIMULATE_ORDER,
      //actionTypes.ADD_PRODUCT_PROCEED,
      actionTypes.UPDATE_QUANTITY,
      actionTypes.REMOVE_PRODUCT,
      actionTypes.UPDATE_PRODUCT,
    ],
    simulateOrder,
  );
}

function* watchGetCart() {
  yield takeLatest([actionTypes.GET_CART], getCart);
}

function* watchGetBillingAddresses() {
  yield takeLatest([actionTypes.GET_BILLING_ADDRESSES], getBillingAddresses);
}

function* watchGetShippingAddresses() {
  yield takeLatest([actionTypes.GET_SHIPPING_ADDRESSES], getShippingAddresses);
}

function* watchGetPaymentMethods() {
  yield takeLatest([actionTypes.GET_PAYMENT_METHODS], getPaymentMethods);
}

function* watchCreateOrder() {
  yield takeLatest([actionTypes.CREATE_ORDER], createOrder);
}

function* watchUpdateCart() {
  yield takeLatest(
    [
      actionTypes.ADD_PRODUCT_PROCEED,
      actionTypes.UPDATE_QUANTITY,
      actionTypes.REMOVE_PRODUCT,
      actionTypes.SET_CART,
      actionTypes.REMOVE_ALL_PRODUCTS,
      actionTypes.CREATE_ORDER_SUCCESS,
      actionTypes.UPDATE_PRODUCT,
      actionTypes.CART_UPDATE_PRODUCT_FAV,
    ],
    setCart,
  );
}

function* watchAddProduct() {
  yield takeLatest([actionTypes.ADD_PRODUCT], addProduct);
}

export default function* cartFlow() {
  yield all([
    fork(watchGetSimulateOrder),
    fork(watchGetShippingAddresses),
    fork(watchGetCart),
    fork(watchGetBillingAddresses),
    fork(watchGetPaymentMethods),
    fork(watchCreateOrder),
    fork(watchUpdateCart),
    fork(watchAddProduct),
  ]);
}

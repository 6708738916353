import React from 'react';
import ErrorPage from './error-page';

class ErrorBoundaryPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      id: 'ERROR.PROBLEM_OCCURED',
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage id={this.state.id} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundaryPage;

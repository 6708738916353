import React from 'react';
import ReactMarkdown from 'react-markdown';
import Html from '../../../../ui/html';
import { useSelector } from 'react-redux';
import { getFooterConfig } from '../../containers/footer-selector';
import { FooterBody, StyledBottomFooter, StyledTopFooter } from './styled-components';

const RegularFooter = () => {
  const footer = useSelector(getFooterConfig) || {};
  const { main, bottom } = footer;
  return (
    <FooterBody>
      <StyledTopFooter fdc padding="10px 100px" minHeight={125} className="top-footer" hideMobile>
        {main && main.type === 'md' && <ReactMarkdown source={main.content} disallowedTypes={['paragraph']} unwrapDisallowed/>}
        {main && main.type === 'html' && <Html content={main.content}/>}
      </StyledTopFooter>
      <StyledBottomFooter fdr jcc padding={10} minHeight={40}>
        {bottom && bottom.type === 'md' && <ReactMarkdown source={bottom.content} disallowedTypes={['paragraph']} unwrapDisallowed/>}
        {bottom && bottom.type === 'html' && <Html content={bottom.content}/>}
      </StyledBottomFooter>
    </FooterBody>
  );
};

export default RegularFooter;

import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';

export const reducer = handleActions(
  {
    [actionTypes.GET_CATEGORIES_REQUEST]: state => ({
      ...state,
      loading: true,
      loaded: false,
      failed: false,
      data: [],
    }),
    [actionTypes.GET_CATEGORIES_SUCCESS]: (state, { payload }) => {
      const categoryReducer = (cat, level) => {
        return [
          {
            level,
            id: cat[`productCategoryLevel${level}`],
            description: cat[`productCategoryLevel${level}Description`],
            children: cat.children ? cat.children.map(child => child[`productCategoryLevel${level + 1}`]) : [],
            sortingKey: cat[`sortingKeyLevel${level}`]
          },
          ...(cat.children ? cat.children.flatMap(child => categoryReducer(child, level + 1)) : []),
        ];
      };
      const data = payload.reduce((result, category) => {
        return [...categoryReducer(category, 1), ...result];
      }, []);
      return {
        ...state,
        loading: false,
        loaded: true,
        data,
      };
    },
    [actionTypes.GET_CATEGORIES_FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      loaded: false,
      failed: true,
      errorMessage: payload,
    }),
    [actionTypes.TOGGLE_CATEGORY]: (state, { payload }) => ({
      ...state,
      showCategory: state.showCategory === payload ? null : payload,
    }),
    [actionTypes.SHOW_CATEGORY]: (state, { payload }) => ({
      ...state,
      showCategory: payload,
    }),
    [actionTypes.HIDE_CATEGORY]: state => ({
      ...state,
      showCategory: null,
    }),
  },
  { loading: false, loaded: false, failed: false, errorMessage: null, showCategory: null, data: [] },
);

export default reducer;

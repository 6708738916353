import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';

let INITIAL_STATE = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  failed: false,
  data: {},
  errorMessage: null
};
export const reducer = handleActions(
  {
    [actionTypes.RESET_USER_SETTINGS_STATE]: state => ({
      ...INITIAL_STATE,
      data: state.data,
    }),
    [actionTypes.GET_USER_SETTINGS_REQUEST]: state => ({
      ...state,
      loading: true,
      loaded: false,
      saving: false,
      saved: false,
      failed: false,
      data: {},
    }),
    [actionTypes.GET_USER_SETTINGS_CACHE]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },
    [actionTypes.GET_USER_SETTINGS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    },
    [actionTypes.GET_USER_SETTINGS_FAILURE]: (state, { payload }) => ({
      ...state,
      saving: false,
      saved: false,
      loading: false,
      loaded: false,
      failed: true,
      data: {},
      errorMessage: payload,
    }),
    [actionTypes.SET_USER_SETTINGS_REQUEST]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        saving: true,
        saved: false,
        failed: false,
        errorMessage: null
      }
    },
    [actionTypes.SET_USER_SETTINGS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        saving: false,
        saved: true,
        data: payload
      }
    },
    [actionTypes.SET_USER_SETTINGS_FAILURE]: (state, { payload }) => ({
      ...state,
      saving: false,
      saved: false,
      loading: false,
      loaded: false,
      failed: true,
      errorMessage: payload,
    }),
  },
  INITIAL_STATE,
);

export default reducer;

import {createAction} from 'redux-actions';
import {
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  HIDE_CATEGORY,
  SHOW_CATEGORY, TOGGLE_CATEGORY
} from './constants';

export const getCategoriesRequest = createAction(GET_CATEGORIES_REQUEST);
export const getCategoriesSuccess = createAction(GET_CATEGORIES_SUCCESS);
export const getCategoriesFailure = createAction(GET_CATEGORIES_FAILURE);
export const showCategory = createAction(SHOW_CATEGORY);
export const hideCategory = createAction(HIDE_CATEGORY);
export const toggleCategory = createAction(TOGGLE_CATEGORY);

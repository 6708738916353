import { combineReducers } from 'redux';
import cartReducer from './modules/cart/containers/cart-reducer';
import categoriesReducer from './modules/category/containers/category-reducer';
import settingsReducer from './modules/settings/containers/settings-reducer';
import breadcrumbsReducer from './app/breadcrumbs/containers/breadcrumbs-reducer';
import translationsReducer from './store/translations/translations-reducer';
import registrationReducer from './modules/self-registration/containers/registration-reducer';
import userReducer from './modules/user/containers/user-reducer';
import homeReducer from './modules/home/containers/home-reducer';
import favoritesReducer from './modules/favorites/containers/favorites-reducer';
import appReducer from './app/containers/app-reducer';
import addToFavoritesReducers from './modules/common/add-to-favorites/containers/add-to-favorites-reducer';

const rootReducer = combineReducers({
  app: appReducer,
  cart: cartReducer,
  categories: categoriesReducer,
  settings: settingsReducer,
  breadcrumb: breadcrumbsReducer,
  user: userReducer,
  registration: registrationReducer,
  home: homeReducer,
  translations: translationsReducer,
  favorites: favoritesReducer,
  atfavorites: addToFavoritesReducers,
});

export default rootReducer;

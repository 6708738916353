import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Loading from '../../modules/common/loading';

// Cached translations
let cachedTheme;
try {
  cachedTheme = JSON.parse(localStorage.getItem('theme'));
} catch (e) {
  // Do nothing
}

// Only render messages when origin changes
let themeCached = false;

const ConfigurableThemeProvider = ({ children }) => {
  // First all the hooks
  let theme = useSelector(state => state.settings && state.settings.theming && state.settings.theming.current);
  const loading = useSelector(state => state.user.loading);
  // IF theme not yet in state (companyInfo not loaded yet)
  if (!theme) {
    theme = cachedTheme;
  } else if (!themeCached) {
    themeCached = true;
    localStorage.setItem('theme', JSON.stringify(theme));
  }

  if (!theme || loading) {
    return <ThemeProvider theme={{ header: { bottom: { background: '#A0A0A0' } } }}><Loading /></ThemeProvider >
  }

  // Dynamic styling of ant design components
  // See here for all variables: https://github.com/ant-design/ant-design/blob/1dff6bf3b51c3fe47eb1303137d4cdff2c11d474/components/style/themes/default.less
  window.less.modifyVars({
    '@btn-primary-bg': theme.button.primary.background, //
    '@primary-color': theme.button.primary.background, // primary color for all components
    '@link-color': theme.body.subtitle, // doesn't work for <a href> links
    '@text-color': theme.body.color, // major text color
    '@text-color-secondary': 'black', // secondary text color TODO
    '@heading-color': theme.body.title, // heading text color
    '@breadcrumb-base-color': 'grey', // heading text color TODO
    '@breadcrumb-separator-color': 'green', // heading text color TODO
    '@item-active-bg': 'yellow',
    '@btn-square-size': '40px',
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ConfigurableThemeProvider;

import { useSelector } from 'react-redux';
import { getAuthorizations, getIsAuthenticated } from '../modules/user/containers/user-selector';

const merge = require('deepmerge');
/*
  Helper object (since we lack typescript)
 */
export const Features = {
  Webshop: {
    showPrice: 'PricingComponent',
    canAddToCart: 'CartComponent',
    categoriesAlphabetical: 'CategoriesAlphabetical',
    unitConversionsAllDecimals: 'UnitConversionsAllDecimals',
    showCategoriesBar: 'ShowCategoriesBar',
    canAccessFavorites: 'Favorites',
    showUnitConversionOnPD: 'ShowUoMConversionOnPD',
    hidePriceOnPortal: 'HidePricesOnPortalClientWebshop',
    showProductAvailability: 'ProductAvailabilityCheck',
    paymentNode: 'PaymentNode',
    hidePaymentNode: 'HidePaymentNode',
    canSetDeliveryDate: 'CanSetDeliveryDate',
  },
  General: {
    Login: 'Login',
    Register: 'Register',
    Logout: 'Logout',
    UserSettings: 'UserSettings',
    PortalNotAvailable: 'PortalNotAvailable',
    Usersnap: 'Usersnap',
    ShowSearchBar: 'ShowSearchBar',
    PortalCMPCustomer: 'PortalCMPCustomer',
    Prismic: 'EnablePrismicCMS',
  },
  OrderTracking: {
    hidePriceOnOrder: 'HidePricesOnPortalClientOrderTracking',
  },
};

/*
  Helper object (since we lack typescript)
 */
export const Modules = {
  Webshop: 'Webshop',
  OrderTracking: 'OrderTracking',
  InvoiceTracking: 'InvoiceTracking',
  General: 'General',
};

export default function useFeature(module, feature, defaultValue = false) {
  let authorizations = useSelector(getAuthorizations);
  let isAuthenticated = useSelector(getIsAuthenticated);

  if (!module) {
    return defaultValue;
  }

  return isFeatured(authorizations, isAuthenticated, module, feature);
}

const isFeatured = (authorizations, isAuthenticated, module, feature) => {
  // Just make sure that you can log in
  if (!isAuthenticated) {
    if (!authorizations) {
      authorizations = {};
    }
    if (!authorizations.General) {
      authorizations.General = {};
    }
    authorizations.General.Login = true;
  }

  const moduleAuthorizations = module && authorizations[module];
  const featureAuthorization = moduleAuthorizations && feature && moduleAuthorizations[feature];

  // isAuthorized:
  // no feature given: when module found
  // feature given: when module & feature found in authorizations
  return !!moduleAuthorizations && (!feature || !!featureAuthorization);
};

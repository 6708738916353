import React from 'react';
import { useSelector } from 'react-redux';
import { getPrismicPlugin } from '../../../containers/app-selectors';
import PrismicFooter from './prismic-footer';
import RegularFooter from './regular-footer';
import useFeature, {Features, Modules} from '../../../../hooks/useFeature';

const Footer = () => {
  const usePrismic = useFeature(Modules.General, Features.General.Prismic);
  const prismicPlugin = useSelector(getPrismicPlugin);
  if (usePrismic && prismicPlugin) {
    return <PrismicFooter/>;
  } else {
    return <RegularFooter/>;
  }
};

export default Footer;

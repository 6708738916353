import { useEffect, useState } from 'react';
import usePrismicClient from './usePrismicClient';
import {useSelector} from 'react-redux';
import {getPrismicLanguage} from '../modules/user/containers/user-selector';
import { buildPrismicParams } from '../utils/prismic-utils';


export default function usePrismicPageByUid(type, pageId, query) {

  const client = usePrismicClient();
  const lang = useSelector(getPrismicLanguage);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const options = {};
  if (lang) {
    options.lang = lang;
  }

  useEffect(() => {
    if (client && type && pageId) {
      setIsLoading(true);
      const params = buildPrismicParams(query, lang);
      client.getByUID(type, pageId, params).then(
        function(document) {
          // document contains the document content
          setIsLoaded(true);
          setIsLoading(false);
          return setData(document);
        },
        function(error) {
          setIsLoading(false);
          setError(error);
        });
    }
  }, [client, lang, type, pageId, query]);
  return {isLoading, isLoaded, error, data};
}

import React from 'react';
import { hot } from 'react-hot-loader/root';
import Provider from 'react-redux/es/components/Provider';
import configureStore from '../store/configure-store';
import rootReducer from '../reducers';
import { HashRouter as Router, Route } from 'react-router-dom';
import Header from './header/components/header';
import Routes from '../routes';
import Footer from './footer/components/footer/footer';
import styled, { ThemeContext } from 'styled-components';
import Translation from './translation/translation';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary, ErrorBoundaryPage } from '../modules/common/error';
import FaviconLoader from '../modules/common/favicon';
import ScrollToTop from '../modules/common/scroll-to-top';
import { initializeApp } from './containers/app-actions';
import AppProvider from './app-provider';
import { QueryParamProvider } from 'use-query-params';
import AddToFavoritesModal from '../modules/common/add-to-favorites/components/add-to-favorites-modal';

const store = configureStore(rootReducer);
store.dispatch(initializeApp());

const Body = styled.div`
  background-color: ${(props) => props.theme.sides.background};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.div`
  flex: 1 0 auto;
  margin-top: 115px;
`;

const MainContainer = styled.div`
  height: 100%;
  max-width: ${(props) => props.theme.body.outerWidth};
  width: ${(props) => props.theme.body.outerWidth};
  padding: 0 calc((${(props) => props.theme.body.outerWidth} - ${(props) => props.theme.body.innerWidth}) / 2);
  background-color: ${(props) => props.theme.body.background};
  background-image: ${(props) => props.theme.body.backgroundImage && 'url(' + props.theme.body.backgroundImage + ')'};
  color: ${(props) => props.theme.body.color};
`;

const App = () => {
  return (
    <Provider store={store}>
      <Translation>
        <ErrorBoundary message={<FormattedMessage id="ERROR.GENERAL_CRASH" />}>
          <AppProvider>
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <ScrollToTop />
                <ThemeContext.Consumer>
                  {(theme) => <FaviconLoader url={'/portal/api/v1/object/image?path=' + theme.favicon} />}
                </ThemeContext.Consumer>
                <Body>
                  <Header />
                  <Main>
                    <MainContainer>
                      <ErrorBoundaryPage>
                        <Routes />
                      </ErrorBoundaryPage>
                    </MainContainer>
                  </Main>
                  <Footer />
                </Body>
                <AddToFavoritesModal />
              </QueryParamProvider>
            </Router>
          </AppProvider>
        </ErrorBoundary>
      </Translation>
    </Provider>
  );
};

export default hot(App);

import { createSelector } from 'reselect';
import { getFavoritesList, getFavoritesListPagination, getFavoritesLoading, sortFavList, createFavoritesLoading } from '../../../favorites/containers/favorites-selector';

export const favModal = createSelector([(state) => state.atfavorites], (state) => state.modal);
export const favModalProduct = createSelector([(state) => state.atfavorites], (state) => state.product);

export const favModalListsLoading = createSelector(
  [(state) => state.atfavorites, (state) => state.favorites.listLoading, createFavoritesLoading],
  (state, favListLoading, createLoading) => state.loading || favListLoading || createLoading,
);

export const favModalLists = createSelector([(state) => state.atfavorites, getFavoritesList], (state, favList) => {
  let useData = state.search ? state.atflists.listPage : favList;
  return useData.sort(sortFavList);
});

export const getSearchValue = (state) => state.atfavorites.search;

export const favModalPagination = createSelector([(state) => state.atfavorites, getFavoritesListPagination], (state, favPagination) => {
  const count = state.atflists.listPage.length;
  const pagination = {
    hasMore: state.total > count,
    isLoading: state.loading,
    page: state.page + 1,
    currentCount: count,
    total: state.total,
  };

  return state.search ? pagination : favPagination;
});

import React from 'react';
import { Col } from 'antd';
import Div from 'ui/div';
import ImageComponent from '../../image';

const AddToFavoritesModalProduct = ({ product }) => {
  const { productThumbnail, productThumbnailPath, name, productName, productId, productListerShortDescription } = product;

  return (
    <Col xs={24} md={10}>
      <ImageComponent image={productThumbnailPath || productThumbnail} />
      <Div fontSize="0.9rem" color="#34495e" ttu fontWeight="700" wordBreak data-testid="productTitle">
        {name || productName || '&nbsp;'}
      </Div>
      <Div fontSize={10} fontWeight={400} lineHeight="0.4rem" data-testid="productId">
        {productId}
      </Div>
      <Div paddingTop="13px" fontSize="0.88rem" fontWeight={300} lineHeight="22px" flex="auto" data-testid="productDescription">
        {productListerShortDescription}
      </Div>
    </Col>
  );
};

export default AddToFavoritesModalProduct;

import styled from 'styled-components';
import { heightProps, marginProps, widthProps } from './general/styling';
import React from 'react';
import * as PropTypes from 'prop-types';
import { useImage } from '../modules/common/image/useImage';
import { useSelector } from 'react-redux';
import { getRouteType } from '../app/containers/app-selectors';

const ObjectStoreImage = ({ path, height, width, forceReload = false }) => {
  const routeType = useSelector(getRouteType);
  const image = useImage(`/portal/api/v1/object/image?path=${path}`);
  if (forceReload) {
    return <img src={`/${routeType}portal/api/v1/object/image?path=${path}`} width={width} height={height} />;
  }
  return <img src={image} width={width} height={height} />;
};

const StyledImg = styled(ObjectStoreImage)`
  ${widthProps};
  ${heightProps};
  ${marginProps};
`;

StyledImg.propTypes = {
  path: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default StyledImg;

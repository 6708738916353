import { useDispatch, useSelector } from 'react-redux';
import { getPrismicGlobalConfig, getPrismicGlobalConfigLoading } from '../app/containers/app-selectors';
import { useEffect } from 'react';
import usePrismicSinglePage from './usePrismicSinglePage';
import { setPrismicGlobalConfig, setPrismicGlobalConfigLoading } from '../app/containers/app-actions';
import useFeature, { Features, Modules } from './useFeature';

export default function usePrismicGlobalConfig() {

  const dispatch = useDispatch();
  const globalConfig = useSelector(getPrismicGlobalConfig);
  const globalConfigLoading = useSelector(getPrismicGlobalConfigLoading);
  const usePrismic = useFeature(Modules.General, Features.General.Prismic);

  const {data, isLoading, isLoaded, fetchData } = usePrismicSinglePage('global', null, true);

  useEffect(() => {
    console.log('in effect');
    if (usePrismic) {
      if (!globalConfigLoading && !isLoading && !globalConfig) {
        dispatch(setPrismicGlobalConfigLoading());
        fetchData();
      }
      if (data && isLoaded && !globalConfig) {
        dispatch(setPrismicGlobalConfig(data.data));
      }
    }
  }, [usePrismic, data, globalConfig]);

  return {usePrismic, config: globalConfig || data};
}

import styled from 'styled-components';
import Div from '../../ui/div';

const Label = styled(Div)`
  text-transform: uppercase;
  color: ${props => props.theme.body.subtitle};
  font-size: ${props => props.fontSize || 13}px;
`;

export default Label;

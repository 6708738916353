import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Input, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Div from 'ui/div';

import { favModalLists, favModalListsLoading, favModalPagination, getSearchValue } from '../containers/add-to-favorites-selector';
import CreateFavoritesList from '../../../favorites/components/create-favorites-list';
import { getFavoritesDefaultList } from '../../../favorites/containers/favorites-selector';
import AddToFavoritesModalFavButtons from './add-to-favorites-modal-buttons';
import { favCloseModal, favSearchInModal, favSearchInModalLoadMore } from '../containers/add-to-favorites-actions';
import { addToMultipleFavorites, loadMoreFavoritesList, removeToMultipleFavorites } from '../../../favorites/containers/favorites-actions';
import LoadMore from '../../load-more';
import { cartUpdateProductFav } from '../../../cart/containers/cart-actions';
import { setDisableUI } from '../../../../app/containers/app-actions';

const Title = styled.span`
  font-weight: ${(props) => (props.active ? 500 : 400)};
  opacity: ${(props) => (props.active ? 1 : 0.7)};
`;

const AddToFavoritesModalFavList = ({ product }) => {
  const { inFavoriteList = [] } = product;

  // return ids of favorite list which product is currently included
  const inFav = inFavoriteList.map(({ favoriteListId }) => favoriteListId);

  const [favIdsToAddList, addFavId] = useState([]);
  const [favIdsToRemoveList, removeFavId] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const favoritesListsLoading = useSelector(favModalListsLoading);
  const favoritesLists = useSelector(favModalLists);
  const defaultId = useSelector(getFavoritesDefaultList);
  const pagination = useSelector(favModalPagination);
  const searchVal = useSelector(getSearchValue);
  const dispatch = useDispatch();
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setModified(!!(favIdsToRemoveList.length || favIdsToAddList.length));
  }, [favIdsToAddList, favIdsToRemoveList])

  const addProductToFav = (id) => {
    const isCheckedBefore = inFav.includes(id);
    const isIncludedAlready = favIdsToAddList.includes(id);

    if (!isCheckedBefore && !isIncludedAlready) {
      addFavId((state) => [...state, id]);
    }

    // remove id on 'to be deleted list'
    removeFavId(favIdsToRemoveList.filter((lid) => lid !== id));
  };

  const removeProductToFav = (id) => {
    const isCheckedBefore = inFav.includes(id);
    const isIncludedAlready = favIdsToRemoveList.includes(id);

    if (isCheckedBefore && !isIncludedAlready) {
      removeFavId((state) => [...state, id]);
    }

    // remove id on 'to be added list'
    addFavId(favIdsToAddList.filter((lid) => lid !== id));
  };

  const onChangeCheckbox = (e, id) => {
    const isChecked = e.target.checked;
    isChecked ? addProductToFav(id) : removeProductToFav(id);
  };

  const searchOnList = (value) => {
    dispatch(favSearchInModal({ search: value }));
  };

  const closeModal = () => {
    setInProgress(false);
    dispatch(favCloseModal());
    dispatch(setDisableUI(false));
  };

  const onClickLoadMore = () => {
    if (searchVal) {
      dispatch(
        favSearchInModalLoadMore({
          search: searchVal,
          page: pagination.page,
        }),
      );
    } else {
      dispatch(loadMoreFavoritesList(pagination.page));
    }
  };

  const modifyProduct = () => {
    const existingList = inFavoriteList.filter((fav) => {
      return !favIdsToRemoveList.includes(fav.favoriteListId);
    });

    const addedFavs = favIdsToAddList.map((id) => {
      const favoriteList = favoritesLists.find((fav) => fav.favoriteListId === id);
      const isDefault = favoriteList ? favoriteList.isDefaultFavoriteList : '0';
      return {
        favoriteListId: id,
        isDefaultFavoriteList: isDefault,
      };
    });

    const updatedInFavoriteList = [...existingList, ...addedFavs];
    product.inFavoriteList = updatedInFavoriteList;
    product.isFavorite = updatedInFavoriteList.length ? '1' : '0';
    dispatch(
      cartUpdateProductFav({
        productId: product.productId,
        inFavoriteList: product.inFavoriteList,
        isFavorite: product.isFavorite,
      }),
    );
  };

  const onSave = () => {
    setInProgress(true);
    const hasSelected = favIdsToAddList.length;
    const hasUnchecked = favIdsToRemoveList.length;

    const callback = () => {
      modifyProduct();
      closeModal();
    };

    const onFinishAdd = hasUnchecked ? null : callback;
    const onFinishRemove = hasUnchecked ? callback : null;

    if (hasSelected) {
      dispatch(addToMultipleFavorites({ productId: product.productId, list: favIdsToAddList, callback: onFinishAdd }));
    }

    if (hasUnchecked) {
      dispatch(removeToMultipleFavorites({ productId: product.productId, list: favIdsToRemoveList, callback: onFinishRemove }));
    }

    if (!hasSelected && !hasUnchecked) {
      closeModal();
    }
  };

  return (
    <Col xs={24} md={14}>
      <Div margin="20px 0">
        <FormattedMessage id="FAVORITES.LABEL.CHOOSE_FAVORITE_LISTS" />
      </Div>
      <Input.Search placeholder="Search in list" onSearch={searchOnList} allowClear />
      <Div marginTop="10px">
        <CreateFavoritesList />
      </Div>
      <Div height="300px" scroll>
        <List
          loadMore={
            <Div marginTop={12}>
              <LoadMore {...pagination} type="ghost" size="small" loadMore={onClickLoadMore} />
            </Div>
          }
          loading={favoritesListsLoading}
          dataSource={favoritesLists}
          rowKey="favoriteListId"
          renderItem={(props) => (
            <List.Item>
              <Div df aic width="100%" cursor="pointer">
                <Checkbox defaultChecked={(inFav.includes(props.favoriteListId) && !favIdsToRemoveList.includes(props.favoriteListId)) || favIdsToAddList.includes(props.favoriteListId)} onChange={(e) => onChangeCheckbox(e, props.favoriteListId)}>
                  <Title active={props.favoriteListId === defaultId}>{props.favoriteListName}</Title>
                </Checkbox>
              </Div>
            </List.Item>
          )}
        />
      </Div>
      <AddToFavoritesModalFavButtons onSave={onSave} loading={inProgress} disableSave={!modified}/>
    </Col>
  );
};

export default AddToFavoritesModalFavList;

import { createSelector } from 'reselect';
import { getContent } from '../../../utils';

export const getFooterConfig = createSelector(
  [
    state => state.settings && state.settings.config.data,
    state => state.settings && state.settings.content.data,
  ],
  (config, content) => {
    if (config && content) {
      return {
        ...config.app.footer,
        main: getContent(content, config.app.footer.main['content-id'], config.app.footer.main['default-value']),
        bottom: getContent(content, config.app.footer.bottom['content-id'], config.app.footer.bottom['default-value'])
      };
    } else {
      return {};
    }
  },
);

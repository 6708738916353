import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';

const getIds = (storage) => {
  const idsAsString = storage.getItem('closedMessageIds');
  if (idsAsString) {
    try {
      const ids = JSON.parse(idsAsString);
      return ids;
    } catch (e) {
      throw new Error('Message IDs in storage not a valid JSON', e);
    }
  } else {
    return [];
  }
};

let INITIAL_STATE = {
  sessionMessagesState: getIds(sessionStorage),
  browserMessagesState: getIds(localStorage),
};
export const reducer = handleActions({
  [actionTypes.SET_BROWSER_MESSAGES_STATE]: (state, { payload }) => ({
    sessionMessagesState: [...state.sessionMessagesState, ...payload].filter((id, index, array) => array.indexOf(id) === index),
    ...state,
  }),
  [actionTypes.SET_BROWSER_MESSAGES_STATE]: (state, { payload }) => {
    return {
      browserMessagesState: [...state.browserMessagesState, ...payload].filter((id, index, array) => array.indexOf(id) === index),
      ...state,
    };
  },

}, INITIAL_STATE);

export default reducer;

import React from 'react';
import Div from 'ui/div';
import Button from 'ui/button';
import Link from 'ui/link';
import { useDispatch } from 'react-redux';
import { favCloseModal } from '../containers/add-to-favorites-actions';
import { FormattedMessage } from 'react-intl';
import { setDisableUI } from '../../../../app/containers/app-actions';

const AddToFavoritesModalFavButtons = (props) => {
  const { loading = false, onSave = () => null, disableSave = false } = props;
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(favCloseModal());
    dispatch(setDisableUI(false));
  };

  const cancelLink = (document.location.href.split('?').length === 2 && '?' + document.location.href.split('?')[1] || '#');
  return (
    <Div jcsb marginTop="20px">
      <div>
        <Link to={cancelLink} onClick={onCancel}>
          <FormattedMessage id="FAVORITES.BUTTONS.CANCEL" />
        </Link>
      </div>
      <div>
        <Button type="primary" loading={loading} onClick={onSave} disabled={disableSave}>
          <FormattedMessage id="FAVORITES.BUTTONS.SAVE" />
        </Button>
      </div>
    </Div>
  );
};

export default AddToFavoritesModalFavButtons;

import * as moment from 'moment';

export const truncate = (text, limit, append = '') => {
  if (text && text.length > limit) {
    return `${text.substr(0, limit)} ${append}`;
  }
  return text;
};

export const openLink = ({ history, urlType = 'new-tab', url }) => {
  if (url) {
    switch (urlType) {
      case 'new-tab':
        window.open(url);
        break;
      case 'same-tab':
        location.href = url;
        break;
      case 'portal':
        history.push(url);
        break;
    }
  }
};

export const isNil = (value) => {
  return value === undefined || value === null;
}

export const parseTimestamp = (ts) => {
  if (!ts) {
    return null;
  }
  const groups = /^(?:(\d{1,2}):(\d{1,2})\s)?(\d{1,2})\/(\d{1,2})\/(\d{4})$/.exec(ts);
  let mins = groups[2] || 0;
  let hours = groups[1] || 0;
  let date = groups[3];
  let month = groups[4];
  let year = groups[5];

  return new Date(year, month - 1, date, hours, mins, 0).getTime();
};

/**
 * API needs a timestamp. query params & frontend works with date-filters, not datetime filters
 * @param dateStr
 * @param endOfDay
 * @returns {string} datetime in iso-string
 */
export const formatDateForApi = (dateStr, endOfDay = false) => {
  let date = moment(dateStr, 'YYYY-MM-DD');
  if (endOfDay) {
    date = date.add(1, 'day').subtract(1, 'millisecond');
  }
  return date.toISOString();
}

/**
 * return content from a json source file (content.en.json as example)
 * @param content            the content source file
 * @param value              the key for which content needs to be searched
 * @param defaultValue       default value if value isn't found
 * @param returnErrorStringIfNotFound  if a boolean && true, then a 'not found' message is returned as string
 * @param paths              used internally in recursive mode.  skip this
 * @returns {null|*|null|*}  value from content or default value
 */
export const getContent = (content, value, defaultValue, returnErrorStringIfNotFound, paths) => {
  if (!value) { // if no value, return null
    return null;
  }
  if (!content) { // if no content found, use initial value
    if (typeof returnErrorStringIfNotFound === 'boolean' && returnErrorStringIfNotFound) {
      return `Content with id '${value}' not found in language dependent content.json`;
    } else {
      return defaultValue;
    }
  }
  if (!paths) { // if no paths yet, split value and try again
    return getContent(content, value, defaultValue, returnErrorStringIfNotFound, value.split('.'));
  }

  if (paths.length > 0) {
    const subContent = content[paths[0]];
    const newPaths = paths.slice(1);
    return getContent(subContent, value, defaultValue, returnErrorStringIfNotFound, newPaths);
  } else {
    return content;
  }
};

export const loadScript = (src, async = false) => {
  const script = document.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  if (async) {
    script.async = true;
  }
  document.head.appendChild(script);
};

import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';
import Div from 'ui/div';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import LanguageOptions from './language-options';
import { useSelector } from 'react-redux';
import { getDisableUI } from '../../containers/app-selectors';
import { withRouter } from 'react-router-dom';
import { getRegistrationHasBeenSent } from '../../../modules/self-registration/containers/registration-selector';

const Button = styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  color: ${props => props.theme.header.top.color};
  font-size: 0.7rem;
  font-weight: 500;
`;

const LoginButton = styled(Button)`
  background-color: ${props => props.theme.header.bottom .background};
  border-radius: 5px;
`;

const UserNavUnauthorized = ({history}) => {
  const theme = useContext(ThemeContext);
  const canLogin = useFeature(Modules.General, Features.General.Login);
  const canRegister = useFeature(Modules.General, Features.General.Register);
  const disableUI = useSelector(getDisableUI);
  const registrationHasBeenSent = useSelector(getRegistrationHasBeenSent);

  const onClickLogin = () => {
    if (!disableUI) {
      window.location.replace('login.html');
    }
  };

  const onClickRegister = () => {
    if (!disableUI) {
      history.push(`/registration`);
    }
  };

  return (
    <Div jcfe aic ttu height={65} marginRight={12}>
      {canLogin && <LoginButton theme={theme} onClick={onClickLogin}>
        <FormattedMessage id="APP.HEADER.USER_NAV.LOGIN"/>
      </LoginButton>}
      {canRegister && !registrationHasBeenSent && <Button onClick={onClickRegister}>
        <FormattedMessage id="APP.HEADER.USER_NAV.REGISTER"/>
      </Button>}
      <LanguageOptions/>
    </Div>
  );
};

export default withRouter(UserNavUnauthorized);

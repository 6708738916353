import React from 'react';
import Div from 'ui/div';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const ErrorPage = ({ id = 'ERROR.PROBLEM_OCCURED' }) => (
  <Div fdr jcc paddingTop={200} fontSize={20}>
    <FormattedMessage id={id} />
  </Div>
);

ErrorPage.propTypes = {
  id: PropTypes.string,
};

export default ErrorPage;

import { message } from 'antd';
import DateFormat from '../modules/common/date-format';
import React from 'react';

export const formatMessage = (message = '', messageVariables = []) => {
  const variables = message.split(' ') || [];
  const mappedVariables = variables.map((item, i) => {
    if (item.startsWith('%')) {
      const found = messageVariables.find(mes => mes.variableName === item.substr(1));
      if (found) {
        if (found.variableType === 'date') {
          return <><DateFormat key={i} date={new Date(found.variableValue)} /> </>;
        }
        return found.variableValue + ' ';
      }
      return '';
    }
    return item + ' ';
  });
  return mappedVariables;
};

export const alertError = e => {
  if (e && e.messages && e.messages.length) {
    e.messages.map(msg => message.error(formatMessage(msg.messageLongDescription, msg.messageVariables)));
  }
};

import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Col, Input, Row } from 'antd';
import UserNav from './user-nav';
import UserNavUnauthorized from './user-nav-unauthorized';
import Div from '../../../ui/div';
import ObjectStoreImage from '../../../ui/object-store-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCartQuantity } from '../../../modules/cart/containers/cart-selector';
import { getAppTitle, getAssistance } from '../containers/header-selector';
import Navigation from '../../../modules/category/components/navigation';
import { getDisableUI, getIsPublic } from '../../containers/app-selectors';
import Html from '../../../ui/html';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import NavigationMobile from '../../../modules/category/components/navigation-mobile';

const { Search } = Input;

const HeaderBar = styled(Div)`
  max-width: ${(props) => props.theme.body.outerWidth};
  width: ${(props) => props.theme.body.outerWidth};
  padding: 0 calc((${(props) => props.theme.body.outerWidth} - ${(props) => props.theme.body.innerWidth}) / 2);
  background-color: ${(props) => props.theme.header.top.background};
  color: ${(props) => props.theme.header.top.color};
  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 0 20px;
  }
`;

const HeaderBody = styled(Div)`
  width: ${(props) => props.theme.body.outerWidth};
`;

const Header = ({ history }) => {
  // use selectors
  const appTitle = useSelector(getAppTitle);
  const assistance = useSelector(getAssistance);
  const cartCount = useSelector(getCartQuantity);
  const isPublic = useSelector(getIsPublic);
  const [searchInput, setSearch] = useState('');
  const canSearch = useFeature(Modules.Webshop, Features.General.ShowSearchBar);
  const canSeeCategories = useFeature(Modules.Webshop, Features.Webshop.showCategoriesBar);
  const disableUI = useSelector(getDisableUI);

  // use react-intl for translations
  const intl = useIntl();
  const searchLabel = intl.formatMessage({ id: 'GENERAL.LABELS.SEARCH' });

  const search = (value) => {
    if (value && value.trim()) {
      let limitValue = encodeURIComponent(value.trim());
      history.push(`/search?query=${limitValue}`);
      setSearch('');
    }
  };

  const theme = useContext(ThemeContext);

  const inputSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <HeaderBody pft="0px" zIndex={1100}>
      <Helmet>
        <title>{appTitle}</title>
      </Helmet>
      <HeaderBar height={65}>
        <Row gutter={20} type="flex">
          <Col xs={12} md={6}>
            <Div height="100%" df aic color={theme.header.top.color}>
              <NavigationMobile />
              <Div cursor="pointer" maxWidth="220px" aic onClick={() => history.push('/')} data-testid="portalLogo">
                <ObjectStoreImage path={theme.header.top.logo.url} width="100%" height="auto" forceReload />
              </Div>
            </Div>
          </Col>
          <Col xs={0} md={12}>
            <Div height="100%" jcc aic flexGrow={1}>
              <Div fdr width={460} aic>
                {canSearch && <Search disabled={disableUI} placeholder={searchLabel} size="large" onSearch={search} maxLength={100} onChange={inputSearch} value={searchInput} />}
                {assistance && (
                  <Div width="160px" marginLeft={10} color={theme.header.top.color} fontSize="0.7rem">
                    <Html content={assistance} />
                  </Div>
                )}
                {!assistance && (
                  <Div width="160px" ttu marginLeft={10} color={theme.header.top.color} fontSize="0.7rem">
                    <FormattedMessage id="APP.HEADER.NEED_ASSISTANCE" />
                    <div>
                      <FormattedMessage id="APP.HEADER.ASSISTANCE_PHONE_NUMBER" />
                    </div>
                  </Div>
                )}
              </Div>
            </Div>
          </Col>
          <Col xs={12} md={6}>
            {isPublic ? <UserNavUnauthorized /> : <UserNav cartCount={cartCount} history={history} />}
          </Col>
        </Row>
      </HeaderBar>
      <Div noDefault className="phone-show" background={theme.header.bottom.background}>
        <Div noDefault fdr margin="0 auto" height="50px" padding="10px 15px" width="100%" maxWidth={theme.body.innerWidth}>
          {canSearch && <Search disabled={disableUI} placeholder={searchLabel} onSearch={search} onChange={inputSearch} value={searchInput} />}
        </Div>
      </Div>
      {canSeeCategories && <Navigation />}
    </HeaderBody>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);

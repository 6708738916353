import React from 'react';
import Div from '../../ui/div';
import styled from 'styled-components';
import Button from '../../ui/button';
import { FormattedMessage } from 'react-intl';
import { Spin, Icon } from 'antd';
import * as PropTypes from 'prop-types';

const antIcon = <Icon type="loading" style={{ fontSize: 24, color: '#EC4842' }} spin />;

// background => van theme !!
const StyledButton = styled(Button)`
  && {
    background: ${(props) => props.theme.button.secondary.background};
    color: ${(props) => props.theme.button.secondary.color};
    letter-spacing: 1px;
    font-size: 0.85rem;
    font-weight: 600;
    border-radius: 6px;
    max-width: 280px;
    width: 100%;
  }
  .loading {
    margin: 0 10px;
  }
`;

const LoadMore = ({ hasMore, isLoading, loadMore, currentCount, total, showingMessage, type }) => {
  const UseButton = (props) => {
    return type ? <Button block type={type} {...props} /> : <StyledButton {...props} />;
  };
  return (
    <Div fdc jcc aic>
      {hasMore && (
        <UseButton disabled={isLoading} onClick={loadMore} data-testid="loadMore">
          {isLoading && <Spin className="loading" indicator={antIcon} />}
          <FormattedMessage id="PRODUCT_DETAILS.LOAD_MORE_RESULTS" />
        </UseButton>
      )}
      {showingMessage && <FormattedMessage id={showingMessage} values={{ currentCount, total }} />}
    </Div>
  );
};

LoadMore.propTypes = {
  showButton: PropTypes.bool,
  page: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  total: PropTypes.number.isRequired,
  currentCount: PropTypes.number.isRequired,
  showingMessage: PropTypes.string,
};

export default LoadMore;

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Div from 'ui/div';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledInput = styled.input`
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -8px;
  height: 25px;
  color: #666;
  padding: 5px
`;

const StyledDot = styled.span`
  margin-top: 4px;
  margin-right: 4px;
  height: 12px;
  width: 12px;
  background-color: ${props => props.bg ? props.bg : '#bbb'};
  border-radius: 50%;
  display: inline-block;
`

const StyledBar = styled.span`
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
`

const NotAvailablePage = () => (
  <Div df aic height="100%" width="100%" background="#f9f9f9" padding="20px">
    <Div margin="0 auto" maxWidth="600px" width="100%">
      <Div borderWidth="3px" borderColor="#f1f1f1" borderRadius="4px 4px 0 0">
        <Div className="clearfix" padding="10px" background="#f1f1f1" borderRadius="4px 4px 0 0">
          <div style={{ float: 'left', width: '60px' }}>
            <StyledDot bg="#ED594A" />
            <StyledDot bg="#FDD800" />
            <StyledDot bg="#5AC05A" />
          </div>
          <div style={{ float: 'left', width: 'calc(90% - 60px)' }}>
            <StyledInput disabled />
          </div>
          <div style={{ float: 'left', width: '10%' }}>
            <div style={{ float: 'right' }}>
              <StyledBar />
              <StyledBar />
              <StyledBar />
            </div>
          </div>
        </Div>

        <Div padding="100px 50px" background="#fff">
          <h3 style={{ color: '#000' }}><FormattedMessage id="ERROR.PORTAL_NOT_AVAILABLE.TITLE" /></h3>
          <p><FormattedMessage id="ERROR.PORTAL_NOT_AVAILABLE.DESCRIPTION" /></p>
        </Div>
      </Div>
    </Div>
  </Div >
)

export const PortalNotAvailableContext = React.createContext(null);

const PortalNotAvailableProvider = ({ children }) => {
  const [portalNotAvailable, setPortalNotAvailable] = useState(false);
  const errorMessage = useSelector(state => state.app.errorMessage);
  const failedCall = errorMessage && [404, 500].includes(errorMessage.status);

  /**
   * if /{path} from initialization response is 404/500
   * or PortalNotAvailable is true
   * then show not available page
   */
  const showPortalNotAvailable = failedCall || portalNotAvailable;

  return (
    <PortalNotAvailableContext.Provider value={{ setPortalNotAvailable }}>
      {showPortalNotAvailable ? <NotAvailablePage /> : children}
    </PortalNotAvailableContext.Provider>
  )
}

export default PortalNotAvailableProvider;

import React, { useContext, useEffect, useState } from 'react';
import Div from '../../../ui/div';
import Icon from '../../../ui/icon';
import { Badge, Dropdown, Popover } from 'antd';
import userMenu from './user-menu';
import { FormattedMessage } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';
import * as PropTypes from 'prop-types';
import { UserSettingsContext } from '../../../modules/user/containers/user-settings-provider';
import useFeature, { Features, Modules } from '../../../hooks/useFeature';
import { useMediaQuery } from 'react-responsive';
import CartPopup from '../../../modules/cart/components/cart-popup';
import { useSelector } from 'react-redux';
import { getDisableUI } from '../../containers/app-selectors';

const NavItem = ({ children, hide = false, onClick = () => null, ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <Div onClick={() => onClick()} fdr aic padding="0 14px" cursor="pointer" color={theme.header.top.color} fontSize="0.7rem" fontWeight="500" hide={hide} {...props}>
      {children}
    </Div>
  );
};

const StyledIcon = styled(Icon)`
  // IE support
  && {
    color: ${(props) => props.theme.header.top.color};
  }
`;

const StyledDiv = styled(Div)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
`;

const NavIcon = ({ type, size = '1.5rem' }) => <StyledIcon fontSize={size} marginRight={10} type={type} />;

const UserNav = ({ history, cartCount }) => {
  const userSettings = useContext(UserSettingsContext);
  const [qtyUpdate, setQtyUpdate] = useState(false);
  const [countUpdate, setCountUpdate] = useState(false);
  const canAddToCart = useFeature(Modules.Webshop, Features.Webshop.canAddToCart);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const disableUI = useSelector(getDisableUI);

  useEffect(() => {
    setCountUpdate(true);
  }, [cartCount.count]);

  useEffect(() => {
    setQtyUpdate(true);
    const timer = setTimeout(() => {
      setQtyUpdate(false);
      setCountUpdate(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [cartCount.quantityCount]);

  const boxShadow = !countUpdate && qtyUpdate ? {} : { boxShadow: 'none' };
  return (
    <StyledDiv jcfe aic ttu height={65} id="header-menu">
      <NavItem hide>
        <NavIcon type="heart" /> Wishlist
      </NavItem>
      <Dropdown
        getPopupContainer={() => !disableUI && document.getElementById('header-menu')}
        overlay={userMenu()}
        placement="bottomLeft"
        disabled={disableUI}
        trigger={[isTabletOrMobile ? 'click' : 'hover']}>
        <div>
          <NavItem>
            <NavIcon type="user" />
            <span className="phone-tablet-hide">
              <FormattedMessage id="APP.HEADER.USER_NAV.GREETING" values={{ name: (userSettings && userSettings.user && userSettings.user.firstName) || '' }} />
            </span>
          </NavItem>
        </div>
      </Dropdown>
      {canAddToCart && (
        <Popover
          content={<CartPopup />}
          placement="bottomRight"
          getPopupContainer={() => !disableUI && document.getElementById('header-menu')}
          // visible={true}
          autoAdjustOverflow
          trigger={disableUI ? 'contextMenu' : 'hover'}
          overlayStyle={{
            minWidth: '400px',
            paddingRight: '10px',
            maxWidth: '400px',
          }}
        >
          <NavItem onClick={() => !disableUI && history.push('/cart')} data-testid="cart">
            <Badge
              count={cartCount.count}
              style={{
                background: '#2FCC71',
                transition: 'box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)',
                ...boxShadow,
              }}
              offset={[-10, 0]}
            >
              <NavIcon size="2rem" type="shopping-cart" />
            </Badge>
            <span className="phone-tablet-hide">
              <FormattedMessage id="APP.HEADER.USER_NAV.CART" />
            </span>
          </NavItem>
        </Popover>
      )}
    </StyledDiv>
  );
};

UserNav.propTypes = {
  history: PropTypes.object.isRequired,
  cartCount: PropTypes.shape({
    cartCount: PropTypes.number,
    quantityCount: PropTypes.number,
  }),
};

export default UserNav;

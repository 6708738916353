import React from 'react';
import Div from 'ui/div';
import CurrencyFormat from '../currency-format';
import PricingLines from './pricing-lines';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getNumberFormat } from '../../user/containers/user-selector';

const CrossedOut = styled.span`
  position: relative;
  font-size: 12px;
  color: ${props => props.theme.body.subtitle};
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
  }
  &>span {
    padding-left: 2px;
    padding-right: 2px;
  }
`;
const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const Price = props => {
  const { price, salesUnitLongDescription, alignRight, size, pricingLines, netAmount } = props;
  const numberFormat = useSelector(getNumberFormat);
  const decimal = numberFormat && numberFormat.numberOfDecimals || 2;

  const fontSize = size === 'large' ? 36 : 18;
  const fontWeight = size === 'large' ? 200 : 600;
  const showNetAmount = netAmount && round(netAmount, decimal) !== round(price, decimal);

  return (
    <Div fdr aic fontSize={fontSize} fontWeight={fontWeight} lineHeight="2rem" width="100%" ellipsis flexWrap jcfe={alignRight} data-testid='productPrice'>
      {showNetAmount ?
        <>
          <CrossedOut><CurrencyFormat value={price} hideSymbol={true} data-testid='productOldPrice'/></CrossedOut>
          <CurrencyFormat value={netAmount} data-testid='productPrice'/>
        </>
        : <Div noDefault ellipsis data-testid='productPrice'><CurrencyFormat value={price} /></Div>
      }

      {salesUnitLongDescription && <Div marginLeft="10px">{salesUnitLongDescription}</Div>}
      <PricingLines popOver pricingLines={pricingLines} />
    </Div>
  );
};

export default Price;

import { all, take, fork } from 'redux-saga/effects';
import { Modal } from 'antd';
import appFlow from './app/containers/app-saga';
import categoryFlow from './modules/category/containers/category-saga';
import settingsFlow from './modules/settings/containers/settings-saga';
import cartFlow from './modules/cart/containers/cart-saga';
import registrationFlow from './modules/self-registration/containers/registration-saga';
import userFlow from './modules/user/containers/user-saga';
import favoritesFlow from './modules/favorites/containers/favorites-saga';
import addToFavoritesFlow from './modules/common/add-to-favorites/containers/add-to-favorites-saga';
import translationFlow from './store/translations/translations-saga';

export default function* rootSaga() {
  try {
    yield all([
      fork(appFlow),
      fork(categoryFlow),
      fork(settingsFlow),
      fork(cartFlow),
      fork(translationFlow),
      fork(userFlow),
      fork(registrationFlow),
      fork(favoritesFlow),
      fork(addToFavoritesFlow),
    ]);
    // wait for something catrastophic to happen
    // ==> error to bubble up to here
    yield take('SAGA_CANCELLED');
  } finally {
    Modal.error({
      title: 'B2B Portal has crashed!',
      content: 'Please refresh the page.',
    });
  }
}

export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILED = 'GET_FAVORITES_FAILED';

export const CREATE_FAVORITE_LIST = 'CREATE_FAVORITE_LIST';
export const CREATE_FAVORITE_LIST_REQUEST = 'CREATE_FAVORITE_LIST_REQUEST';
export const CREATE_FAVORITE_LIST_SUCCESS = 'CREATE_FAVORITE_LIST_SUCCESS';
export const CREATE_FAVORITE_LIST_FAILED = 'CREATE_FAVORITE_LIST_FAILED';

export const UPDATE_FAVORITE_LIST = 'UPDATE_FAVORITE_LIST';
export const UPDATE_FAVORITE_LIST_REQUEST = 'UPDATE_FAVORITE_LIST_REQUEST';
export const UPDATE_FAVORITE_LIST_SUCCESS = 'UPDATE_FAVORITE_LIST_SUCCESS';
export const UPDATE_FAVORITE_LIST_FAILED = 'UPDATE_FAVORITE_LIST_FAILED';

export const DELETE_FAVORITE_LIST = 'DELETE_FAVORITE_LIST';
export const DELETE_FAVORITE_LIST_REQUEST = 'DELETE_FAVORITE_LIST_REQUEST';
export const DELETE_FAVORITE_LIST_SUCCESS = 'DELETE_FAVORITE_LIST_SUCCESS';
export const DELETE_FAVORITE_LIST_FAILED = 'DELETE_FAVORITE_LIST_FAILED';

export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const GET_FAVORITES_LIST_REQUEST = 'GET_FAVORITES_LIST_REQUEST';
export const GET_FAVORITES_LIST_SUCCESS = 'GET_FAVORITES_LIST_SUCCESS';
export const GET_FAVORITES_LIST_FAILED = 'GET_FAVORITES_LIST_FAILED';

export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';
export const ADD_TO_FAVORITES_REQUEST = 'ADD_TO_FAVORITES_REQUEST';
export const ADD_TO_FAVORITES_SUCCESS = 'ADD_TO_FAVORITES_SUCCESS';
export const ADD_TO_FAVORITES_FAILED = 'ADD_TO_FAVORITES_FAILED';

export const REMOVE_TO_FAVORITES = 'REMOVE_TO_FAVORITES';
export const REMOVE_TO_FAVORITES_REQUEST = 'REMOVE_TO_FAVORITES_REQUEST';
export const REMOVE_TO_FAVORITES_SUCCESS = 'REMOVE_TO_FAVORITES_SUCCESS';
export const REMOVE_TO_FAVORITES_FAILED = 'REMOVE_TO_FAVORITES_FAILED';

export const SELECT_FAVORITE_LIST = 'SELECT_FAVORITE_LIST';

export const ADD_TO_MULTIPLE_FAVORITES = 'ADD_TO_MULTIPLE_FAVORITES';
export const REMOVE_TO_MULTIPLE_FAVORITES = 'REMOVE_TO_MULTIPLE_FAVORITES';

export const LOAD_MORE_FAVORITE_LIST = 'LOAD_MORE_FAVORITE_LIST';

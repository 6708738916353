export const ADD_PRODUCTS = 'CART_ADD_PRODUCTS';
export const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL_PRODUCTS';

export const ADD_PRODUCT = 'CART_ADD_PRODUCT';
export const ADD_PRODUCT_PROCEED = 'CART_ADD_PRODUCT_PROCEED';
export const REMOVE_PRODUCT = 'CART_REMOVE_PRODUCT';
export const UPDATE_PRODUCT = 'CART_UPDATE_PRODUCT';
export const UPDATE_QUANTITY = 'CART_UPDATE_QUANTITY';

export const SIMULATE_ORDER = 'SIMULATE_ORDER';
export const SIMULATE_ORDER_REQUEST = 'SIMULATE_ORDER_REQUEST';
export const SIMULATE_ORDER_SUCCESS = 'SIMULATE_ORDER_SUCCESS';
export const SIMULATE_ORDER_FAILED = 'SIMULATE_ORDER_FAILED';

export const GET_CART = 'GET_CART';
export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILED = 'GET_CART_FAILED';

export const SET_CART = 'SET_CART';
export const SET_CART_REQUEST = 'SET_CART_REQUEST';
export const SET_CART_SUCCESS = 'SET_CART_SUCCESS';
export const SET_CART_FAILED = 'SET_CART_FAILED';

export const GET_BILLING_ADDRESSES = 'CATEGORY_GET_BILLING_ADDRESSES';
export const GET_BILLING_ADDRESSES_REQUEST = 'CATEGORY_GET_BILLING_ADDRESSES_REQUEST';
export const GET_BILLING_ADDRESSES_SUCCESS = 'CATEGORY_GET_BILLING_ADDRESSES_SUCCESS';
export const GET_BILLING_ADDRESSES_FAILURE = 'CATEGORY_GET_BILLING_ADDRESSES_FAILURE';

export const GET_SHIPPING_ADDRESSES = 'CATEGORY_GET_SHIPPING_ADDRESS';
export const GET_SHIPPING_ADDRESSES_REQUEST = 'CATEGORY_GET_SHIPPING_ADDRESSES_REQUEST';
export const GET_SHIPPING_ADDRESSES_SUCCESS = 'CATEGORY_GET_SHIPPING_ADDRESSES_SUCCESS';
export const GET_SHIPPING_ADDRESSES_FAILURE = 'CATEGORY_GET_BILLING_ADDRESSES_FAILURE';

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';

export const CART_UPDATE_PRODUCT_FAV = 'CART_UPDATE_PRODUCT_FAV';

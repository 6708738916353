export const INITIALIZE_APP = 'INITIALIZE_APP';
export const INITIALIZE_APP_REQUEST = 'INITIALIZE_APP_REQUEST';
export const INITIALIZE_APP_SUCCESS = 'INITIALIZE_APP_SUCCESS';
export const INITIALIZE_APP_FAILED = 'INITIALIZE_APP_FAILED';
export const SET_ROUTE_TYPE = 'SET_ROUTE_TYPE';
export const INIT_PUBLIC_LANGUAGE = 'INIT_PUBLIC_LANGUAGE';
export const SET_PUBLIC_LANGUAGE = 'SET_PUBLIC_LANGUAGE';
export const SET_UNAUTHORIZE = 'SET_UNAUTHORIZE';
export const SET_PRISMIC_GLOBAL_CONFIG_LOADING = 'SET_PRISMIC_GLOBAL_CONFIG_LOADING';
export const SET_PRISMIC_GLOBAL_CONFIG = 'SET_PRISMIC_GLOBAL_CONFIG';
export const SET_DISABLE_UI = 'SET_DISABLE_UI';

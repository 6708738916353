import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './constants';
import { callApi } from '../../../../utils';
import { favSearchInModalFailed, favSearchInModalRequest, favSearchInModalSuccess } from './add-to-favorites-actions';

const transNotAbleToGetConfigData = '(yield translate)("not_able_to_get_config_data")';
const rootUrl = '/portal/api/v1';

function* searchFavoritesModal({ payload }) {
  if (!payload.search) return;
  try {
    yield put(favSearchInModalRequest());

    let endpoint = `/product/favorites/list?status=1`;
    endpoint += payload.search ? `&search=${payload.search}` : '';
    endpoint += payload.page ? `&page=${payload.page}` : '';

    const data = yield call(callApi, {
      endpoint,
      options: {
        root: rootUrl,
        method: 'GET',
        errorMessage: transNotAbleToGetConfigData,
        tokenNeeded: false,
      },
    });

    yield put(favSearchInModalSuccess(data));
  } catch (e) {
    yield put(favSearchInModalFailed(e.messageBody || []));
  }
}

function* watchSearchFavoriteListModal() {
  yield takeEvery([actionTypes.FAV_SEARCH_IN_MODAL, actionTypes.FAV_SEARCH_IN_MODAL_LOAD_MORE], searchFavoritesModal);
}

export default function* addToFavoritesFlow() {
  yield all([fork(watchSearchFavoriteListModal)]);
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCartItems } from '../containers/cart-selector';
import Div from 'ui/div';
import UIButton from 'ui/button';
import CartSidePanelItem from './cart-side-panel-item';
import { IconFA } from '../../../ui/icon';
import { removeProduct } from '../containers/cart-actions';
import { useHistory } from 'react-router';
import { StyledLink } from '../../common/product/product-list';

const CartPopup = () => {
  const cartItems = useSelector(getCartItems);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickRemove = (productId) => {
    dispatch(removeProduct(productId));
  };

  const onClickCart = () => {
    history.push('/cart');
  };

  if (!cartItems.length) {
    return (
      <Div tac ttn padding="12px 16px">
        <FormattedMessage id="CART.LABEL.YOUR_CART_IS_EMPTY" />
      </Div>
    );
  }

  return (
    <Div padding="4px 0" hideMobile>
      <Div maxheight="500px" overflow="auto" padding="0 16px">
        {cartItems.map((item, i) => (
          <Div ttn key={`${item.productKey}`} position="relative">
            <Div par="0" pat="12px" zIndex="1" onClick={() => onClickRemove(`${item.productKey}`)}>
              <StyledLink>
                <IconFA icon="fa-trash" />
              </StyledLink>
            </Div>
            <CartSidePanelItem
              key={item.productKey}
              size="sm"
              name={item.name}
              productId={item.productId}
              price={item.selected ? item.selected.price  * item.quantity : item.price * item.quantity}
              image={item.productThumbnailPath}
              currency={item.currencySymbol}
              quantity={item.quantity}
              salesUnit={item.selected ? item.selected.unitOfMeasureShortDescription : item.salesUnit}
              salesUnits={item.selected ? item.selected.unitOfMeasurePluralDescription : item.salesUnitPluralDescription}
              inPromotion={item.inPromotion}
            />
          </Div>
        ))}
      </Div>
      <Div marginTop={4} padding="4px 16px" borderTopWidth={1} borderColor="#EDF1F3">
        <UIButton type="primary" block ttu onClick={onClickCart}>
          <FormattedMessage id="GENERAL.BUTTONS.VIEW_CART" />
        </UIButton>
      </Div>
    </Div>
  );
};

export default CartPopup;

export const GET_USER_SETTINGS = 'USER_GET_USER_SETTINGS';
export const GET_USER_SETTINGS_REQUEST = 'USER_GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_CACHE = 'USER_GET_USER_SETTINGS_CACHE';
export const GET_USER_SETTINGS_SUCCESS = 'USER_GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAILURE = 'USER_GET_USER_SETTINGS_FAILURE';

export const RESET_USER_SETTINGS_STATE = 'RESET_USER_SETTINGS_STATE';

export const SET_USER_SETTINGS = 'USER_SET_USER_SETTINGS';
export const SET_USER_SETTINGS_REQUEST = 'USER_SET_USER_SETTINGS_REQUEST';
export const SET_USER_SETTINGS_SUCCESS = 'USER_SET_USER_SETTINGS_SUCCESS';
export const SET_USER_SETTINGS_FAILURE = 'USER_SET_USER_SETTINGS_FAILURE';

import { createSelector } from 'reselect';

/**
 * private method to make the most optimal combination of categories for the first column
 * @param remainingCS available columns (size + count)
 * @param nrOfColumns how many columns will there be
 * @returns {score: number, remainingCS: CS[], group: number[]}
 */
const getMostOptimalColumn = (remainingCS, nrOfColumns) => {
  // If only 1 column, return all cat-sizes in 1 array, or if no cats left for remaining columns
  if (nrOfColumns === 1 || remainingCS.length === 0) {
    return {
      group: remainingCS.flatMap(cs => new Array(cs.count).fill(cs.size)),
      score: 0,
      remainingCS: [],
    };
  }

  // More than 1 column and still categories to assign

  const totalSize = remainingCS.reduce((count, cs) => cs.size * cs.count + count, 0);
  const sizePerColumn = totalSize / nrOfColumns;

  let iterCount = 0;
  let result = undefined;
  let mostOptimalFound = false;

  /**
   * This is a recursive function.  It needs another mindset to get to the bottom.  Take your time to debug if you need to make changes.
   *
   * @param remainingCS categories to put in the grouping (size: number + count: number)
   * @param column of type cat2[]: array of cat2 used over the recursive calls.
   * @returns {{score: *, grouping: *}|*} the best possible combination
   */
  const recursiveGrouper = (remainingCS, column = []) => {
    if (++iterCount > 10000) remainingCS.crashPlease(); // To avoid infinite loops ;-)

    if (remainingCS.length > 0) {
      remainingCS.find(cs => {
        // Use 'find' to stop iterating if the most optimal one found
        // If one recursive path already found the most optimal column, stop trying
        if (mostOptimalFound) {
          return true;
        }
        // Create a new column with this cs
        const newColumn = [...column, cs.size];
        const newColumnSize = newColumn.reduce((sum, size) => sum + size, 0);
        const newScore = newColumnSize - sizePerColumn;
        const newRemainingCS = remainingCS
          .map(c =>
            c.size === cs.size
              ? {
                ...c,
                count: c.count - 1,
              }
              : c,
          )
          .filter(c => c.count > 0);

        // If it's the first result or better than the previous one, store in function global parameters
        if (result === undefined || Math.abs(newScore) < Math.abs(result.score)) {
          result = {
            group: newColumn,
            score: newScore,
            remainingCS: newRemainingCS,
          };
          // Is this good enough (score is near the most optimal combination
          if (Math.abs(newScore) < sizePerColumn / 20 || iterCount > 100) {
            // Then stop searching
            mostOptimalFound = true;
            return true; // We found the best (good enough) combination
          }
        }
        // If newScore is higher than 0, stop adding more to column.  It will only be worse
        if (newScore > 0) {
          return false;
        }
        // if not yet full, try with adding the next one in the column (recursive call)
        return recursiveGrouper(newRemainingCS, newColumn);
      });
    }
  };

  // Initiate recursive find
  recursiveGrouper(remainingCS);

  // return most optimal result
  return result;
};

/**
 *
 * @param level1Category the parent category
 * @param columnSize how many columns the cat2's need to be distributed in?
 * @returns {*[]}
 */
const groupInColumns = (level1Category, columnSize = 4) => {
  // Calculate size of each category2
  const cat2s = level1Category.children.map(cat2 => {
    return {
      ...cat2,
      size: cat2.children.length + 1, // size of cat2 = cat3.length + 1 to take padding into account
    };
  });

  // CS = category grouped per size - element { size: number; count: number; cats: Category[] }
  // this is used to find most optimal columns in a most normalized way
  const initialCS = cat2s.reduce((result, cat2) => {
    let newResult = [...result];
    const existing = result.find(r => r.size === cat2.size);
    if (!existing) {
      newResult.push({ size: cat2.size, count: 1, cats: [cat2] });
    } else {
      existing.count += 1;
      existing.cats.push(cat2);
    }
    return newResult;
  }, []);

  // Find column contents
  const groups = [];
  let remainingCS = initialCS;
  for (let i = columnSize; i > 0; i--) {
    const result = getMostOptimalColumn(remainingCS, i);
    groups.push(result.group);
    remainingCS = result.remainingCS;
  }

  // map groups with sizes as content to array of arrays with categories as content
  const categoryColumns = groups.map(group => {
    return group.map(size => {
      const cs = initialCS.find(cs => cs.size === size);
      return cs.cats.pop();
    });
  });
  // Extra check
  if (initialCS.find(cs => cs.cats.length > 0)) {
    throw new Error('Not all categories returned in columns.  Please debug code !!!');
  }
  return categoryColumns;
};

const groupAlphabeticallyInColumns = (level1Category, columnSize = 4) => {
  const cat2s = level1Category.children.map(cat2 => {
    return {
      ...cat2,
      size: cat2.children.length + 1, // size of cat2 = cat3.length + 1 to take padding into account
    };
  });

  cat2s.sort((c1, c2) => c1.description > c2.description ? 1 : -1);

  const totalSize = cat2s.reduce((count, cs) => cs.size + count, 0);
  const sizePerColumn = totalSize / columnSize;

  const categoryColumns = new Array(columnSize).fill(0).map(() => []);

  const fillColumn = (column) => {
    let size = 0;
    while (Math.abs(sizePerColumn - size) > .2 * sizePerColumn && size < sizePerColumn * 1.2) {
      const cat2 = cat2s.splice(0, 1)[0];
      if (cat2) {
        column.push(cat2);
      }
      size += cat2 ? cat2.size : 1;
    }
  };
  for (let i = 0; i < categoryColumns.length; i++) {
    fillColumn(categoryColumns[i]);
  }
  return categoryColumns;
};

export const getLevel1Categories = createSelector(
  [state => (state.categories && state.categories.data) || []],
  categories => categories.filter(cat => cat.level === 1).sort((a, b) => a.sortingKey - b.sortingKey)
);
export const getSelectedLevel1CategoryGroupedInColumnsSelectorFactory = (sortAlphabetically) => createSelector(
  [state => state.categories.showCategory, state => state.categories.data],
  (selectedCategory, categories) => {
    // const start = new Date();
    if (selectedCategory) {
      // Make category1 with all children resolved
      const cat = {
        ...selectedCategory,
        children: selectedCategory.children
          .map(id => {
            let category2 = categories.find(c => c.id === id);
            if (category2) {
              return {
                ...category2,
                children: category2.children.map(id => categories.find(c => c.id === id)).filter(c => c),
              };
            } else {
              return null;
            }
          })
          .filter(c => c),
      };
      // Make array of columns with category2's
      if (sortAlphabetically) {
        return groupAlphabeticallyInColumns(cat);
      } else {
        return groupInColumns(cat);
      }
    } else {
      return []; // Nothing selected, no columns returned
    }
  },
);
export const getCategoriesLoading = createSelector(
  [state => state.categories && state.categories.loading],
  loading => loading,
);
export const getShowCategory = createSelector(
  [state => state.categories, state => state.categories.showCategory],
  (categoryData, cat) => cat,
);

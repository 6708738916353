import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import Icon from 'ui/icon';
import { favOpenModal } from '../containers/add-to-favorites-actions';
import { favModal } from '../containers/add-to-favorites-selector';
import { getFavoritesList } from '../../../favorites/containers/favorites-selector';
import { createFavoriteList } from '../../../favorites/containers/favorites-actions';
import { setDisableUI } from '../../../../app/containers/app-actions';

const AddToFavorites = ({ product }) => {
  const { isFavorite } = product;
  const inFavorites = isFavorite === '1';

  const favLists = useSelector(getFavoritesList);
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const openPopup = (e) => {
    dispatch(favOpenModal(product));
    dispatch(setDisableUI(true));

    if (favLists.length) return;
    dispatch(createFavoriteList());
  };

  // trigger force update when favorites list is updated
  const favModalStatus = useSelector(favModal);
  useEffect(() => {}, [favModalStatus]);

  // classname used for testing
  const className = inFavorites ? 'fav-added' : 'fav-not-added';
  const isFilled = inFavorites ? 'filled' : 'outlined';
  const style = { color: theme.button.primary.background };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Icon type="heart" onClick={openPopup} className={`fav-icon ${className}`} theme={isFilled} style={style} />
    </div>
  );
};

export default AddToFavorites;

import { message as antdMessage } from 'antd';

export default function callApi({ endpoint, options, groupCall = false, key }) {
  const { tokenNeeded = true } = options;
  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  };

  const getUrl = opts => {
    const finalUrl = opts.root || API_ENDPOINT;
    if (endpoint.charAt(0) === '/') {
      return encodeURI(`${finalUrl}${endpoint}`);
    }
    return encodeURI(`${finalUrl}/${endpoint}`);
  };

  const getErrorObject = (error, errorMessage, errors, status, severityLevel) => {
    const exception = error;
    exception.properties = {
      status,
      errors: errors || [],
      url: getUrl(),
      severityLevel,
    };
    return { error: exception };
  };

  function checkStatus(response) {
    const contentType = response.headers.get('content-type');

    if (response.status === 401 || (contentType && contentType?.indexOf('text/html') !== -1)) {
      return Promise.reject({ message: '401 Not Authorized', status: 'USE_PUBLIC' });
    }

    if (response.status >= 200 && response.status < 300) {
      return response.text();
    }

    if (response.status === 500) {
      antdMessage.error('Something went wrong, contact us when this problem persists.');
      return Promise.reject({ message: 'internal server error', status: response.status });
    }

    return response.json().then(result => {
      if (result) {
        // error response already configured on MW
        return Promise.reject({ error: { ...result } });
      }
      const error = new Error(options.errorMessage);
      return Promise.reject(getErrorObject(error, null, null, response.status));
    });
  }

  const mergedOptions = { ...defaultOptions, ...options };

  function handleAuthentication() {
    function addCustomHeaders(token) {
      const customHeaders = {
        Authorization: `Bearer ${token}`,
      };

      mergedOptions.headers = {
        ...mergedOptions.headers,
        ...customHeaders,
      };

      return mergedOptions;
    }

    return new Promise(resolve => resolve(mergedOptions));
  }

  return (
    handleAuthentication()
      .then(opts => fetch(getUrl(opts), opts))
      .then(checkStatus)
      .then(response => {
        // remove trailing commma in json
        // return JSON.parse(response.replace(/(.*?),\s*(\}|])/g, '$1$2'));
        return response && JSON.parse(response) || {};
      })
      // when it is a groupcall, add the key to the response.
      .then(response => (groupCall ? { ...response, key } : response))
      .catch(err => {
        if (err.error) {
          return Promise.reject(err.error);
        }
        return Promise.reject(err);
      })
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { getDateFormat } from '../user/containers/user-selector';

const twoDigit = nr => {
  const prefixxed = '0' + nr;
  return prefixxed.substr(prefixxed.length - 2);
};
const DateFormat = ({ date: dateObject, ...props }) => {

  if (!dateObject) {
    return '';
  }
  const date = dateObject.getDate();
  const month = dateObject.getMonth()  + 1;
  const year = dateObject.getFullYear();

  const dateFormat = useSelector(getDateFormat);

  let delimiter = '-';
  switch (dateFormat) {
    case 'dd/mm/yyyy':
    case 'mm/dd/yyyy':
    case 'yyyy/mm/dd':
      delimiter = '/';
      break;
  }

  let dateString;
  switch (dateFormat) {
    case 'dd/mm/yyyy':
    case 'dd-mm-yyyy':
      dateString = `${date}${delimiter}${twoDigit(month)}${delimiter}${year}`;
      break;
    case 'mm/dd/yyyy':
    case 'mm-dd-yyyy':
      dateString = `${month}${delimiter}${twoDigit(date)}${delimiter}${year}`;
      break;
    case 'yyyy/mm/dd':
    case 'yyyy-mm-dd':
      dateString = `${year}${delimiter}${twoDigit(month)}${delimiter}${twoDigit(date)}`;
      break;
  }

  return <span {...props}>{dateString}</span>;
};

export default DateFormat;

import React, { useContext } from 'react';
import { Dropdown, Menu } from 'antd';
import Div from 'ui/div';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSettings } from '../../../modules/user/containers/user-selector';
import { getPublicLanguage } from '../../containers/app-selectors';
import { setPublicLanguage } from '../../containers/app-actions';
import { getSupportedLanguageCodes } from '../../../modules/home/containers/home-selector';
import { ThemeContext } from 'styled-components';

const LanguageOptions = () => {
  const theme = useContext(ThemeContext);
  const language = useSelector(getPublicLanguage);
  const userSettings = useSelector(getUserSettings);
  const allLanguages = useSelector(getSupportedLanguageCodes);

  const dispatch = useDispatch();
  const onClickLanguage = ({ key }) => {
    dispatch(setPublicLanguage(key));
  }

  return (
    <Dropdown placement="bottomLeft" trigger={['hover']} overlay={
      <Menu onClick={onClickLanguage}>
        {allLanguages.map(lang => <Menu.Item key={lang}>{lang.toUpperCase()}</Menu.Item>)}
      </Menu>
    } overlayStyle={{zIndex: 1111}}>
      <Div fdr aic padding="0 14px" cursor="pointer" color={theme.header.top.color} fontSize="0.7rem" fontWeight="500">
        {language || userSettings.language || 'en'}
      </Div>
    </Dropdown>
  )
}

export default LanguageOptions;

import { useEffect, useState } from 'react';
import { buildPrismicParams } from '../utils/prismic-utils';
import usePrismicClient from './usePrismicClient';
import {useSelector} from 'react-redux';
import {getPrismicLanguage} from '../modules/user/containers/user-selector';


export default function usePrismicSinglePage(pagename, query) {

  const client = usePrismicClient();
  const lang = useSelector(getPrismicLanguage);

  const [isLoad, setIsLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  let fetchData = () => {
    setIsLoad(true);
  };

  useEffect(() => {
    if (isLoad && !isLoading && !isLoaded && client) {
      const params = buildPrismicParams(query, lang);
      setIsLoading(true);
      client.getSingle(pagename, params).then(
        function(document) {
          // document contains the document content
          setIsLoaded(true);
          setIsLoading(false);
          return setData(document);
        },
        function(error) {
          console.warn(`Page document '${pagename}' not found. Make sure it exists in your Prismic repository`);
          setIsLoading(false);
          setError(error);
        });
    }
  }, [client, isLoad, lang]);

  return {isLoading, isLoaded, error, data, fetchData};
}

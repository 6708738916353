import { handleActions } from 'redux-actions';
import * as actionTypes from './constants';
import { flattenJson } from '../../utils';
import defaultTranslations from '../../../assets/translations/default.json';
import hash from 'object-hash';

// Helper for merging translations (messages)
const generateMessages = (translations) => {
  const messages = {
    ...flattenJson(defaultTranslations),
    ...flattenJson(translations || {}),
  };
  const messageHash = hash(messages);
  return { data: messages, hash: messageHash };
};

let INITIAL_STATE = {
  loading: false,
  loaded: false,
  failed: false,
  ...generateMessages(),
  key: 'default'
};
export const reducer = handleActions(
  {
    [actionTypes.GET_TRANSLATIONS_REQUEST]: state => ({
      ...state,
      loading: true,
    }),
    [actionTypes.GET_TRANSLATIONS_CACHE]: (state, { payload }) => {
      let result = generateMessages(payload);
      if (result.hash === state.hash) {
        return state;
      }
      return {
        ...state,
        ...result,
        key: 'cached'
      };
    },
    [actionTypes.GET_TRANSLATIONS_SUCCESS]: (state, { payload }) => {
      let result = generateMessages(payload);
      if (result.hash === state.hash) {
        return {
          ...state,
          loaded: true,
          loading: false,
        };
      }
      return {
        ...state,
        ...result,
        key: payload.locale,
        loading: false,
        loaded: true,
      };
    },
    [actionTypes.GET_TRANSLATIONS_FAIL]: (state, { payload }) => ({
      ...state,
      loading: false,
      loaded: false,
      failed: true,
      errorMessage: payload,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
